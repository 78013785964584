import { Box, Grid, Typography } from "@mui/material";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import {
  PageContainer,
  MaxWidthContainer,
  BorderCard,
  FlexColumn,
  HomeCard,
  FlexRow,
} from "../../components/shared/container/Index";
import {
  AboutUsHero,
  AboutUsInfo,
  Alvaro,
  Catalina,
  CristianL,
  CristianS,
  David,
  Gary,
  LogoCompleto,
  Mateo,
  Melissa,
  Nicolas,
  Paola,
  QuantilBlack,
} from "../../images/Index";
import StyledImage from "../../components/common/image/StyledImage";
import StyledButton from "../../components/common/button/StyledButton";
import StyledTextDottedDivider from "../../components/common/divider/StyledTextDottedDivider";
import LogosBanner from "../../components/shared/section/LogosBanner";
import FormSection from "../../components/shared/section/FormSection";
import FullWidthBanner from "../../components/shared/section/FullWidthBanner";
import VideoBanner from "../../components/shared/section/VideoBanner";
import IconIsologo from "../../components/icons/IconIsologo";

const AboutUs = () => {
  return (
    <PageContainer>
      <Box
        sx={{
          backgroundImage: `url(${AboutUsHero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          paddingTop: "166px",
          marginBottom: "140px",
        }}
      >
        <MaxWidthContainer>
          <FlexColumn sx={{ maxWidth: "666px", margin: "0 auto" }}>
            <StyledImage src={LogoCompleto} alt="Logo completo" />
          </FlexColumn>
        </MaxWidthContainer>
        <MaxWidthContainer
          sx={{
            position: "relative",
            bottom: "-72px",
          }}
        >
          <BorderCard
            shadow
            sx={{
              background: "#FFFFFF",
              opacity: "90%",
            }}
          >
            <FlexColumn gap="28px" padding="40px 60px" justifyContent="center">
              <Typography variant="h5" textAlign="center">
                Creemos en el poder que tiene la inteligencia artificial para
                transformar el mundo. De igual manera sabemos que esta
                transformación debe ser guiada por principios que partan de la
                ética y la responsabilidad
              </Typography>
            </FlexColumn>
          </BorderCard>
        </MaxWidthContainer>
      </Box>

      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "80px", marginBottom: "112px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} sm={7} xl={7}>
            <FlexColumn
              gap="28px"
              alignItems="flex-start"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h2">SOMOS EL ALIADO</Typography>
              <Typography
                variant="h1"
                sx={{ fontSize: "52px", lineHeight: "58px" }}
              >
                Para implementar la inteligencia artificial de manera
                responsable, ética y segura.
              </Typography>
              <Typography variant="body1">
                Todas nuestras acciones están guiadas por la necesidad de
                contribuir a un futuro esperanzador para todos, por eso nuestro
                propósito es promover la justicia algorítmica, identificando y
                mitigando los sesgos en los modelos predictivos, y acompañando a
                las partes involucradas a construir un mejor FUTURO, desde
                ahora.
              </Typography>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} sm={5} xl={5}>
            <Box sx={{ height: "100%", borderRadius: "20px" }}>
              <StyledImage src={AboutUsInfo} alt="About us" />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "80px", marginBottom: "112px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} sm={5} xl={5}>
            <Box sx={{ height: "100%", borderRadius: "20px", padding: "40px" }}>
              <StyledImage src={QuantilBlack} alt="About us" />
            </Box>
          </Grid>
          <Grid item xs={4} sm={7} xl={7}>
            <FlexColumn
              gap="28px"
              alignItems="flex-start"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h2">CONOCE NUESTRA HISTORIA</Typography>
              <Typography
                variant="h1"
                sx={{ fontSize: "52px", lineHeight: "58px" }}
              >
                Criteria nació como un sueño de Quantil
              </Typography>
              <Typography variant="body1">
                Liderado por Catalina Bernal y Melissa Robles, y respaldado por
                el equipo de Quantil, BID Lab y fAIr LaC. Nuestra misión es
                enfrentar uno de los mayores retos en la inteligencia artificial
                de nuestra época: los sesgos en los modelos de IA.
              </Typography>
              <Typography variant="body1">
                Desde nuestros inicios en 2008, hemos trabajado más de 350
                proyectos de IA y analítica. En conjunto con líderes de sectores
                como la salud, finanzas y gobierno, hemos desarrollado
                soluciones que aseguren que la IA sea utilizada de manera justa
                y responsable. A través de nuestra tecnología avanzada, hemos
                ayudado a empresas a identificar y mitigar sesgos ocultos,
                mejorando la equidad y la precisión en sus procesos de toma de
                decisiones.
              </Typography>
              <Typography variant="body1">
                Hoy, continuamos innovando para fomentar la IA responsable en
                todo el mundo y promover una sociedad más equitativa en la
                región.
              </Typography>
            </FlexColumn>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <MaxWidthContainer>
        <FlexRow
          gap="48px"
          justifyContent="center"
          alignItems="center"
          padding="72px 0px"
        >
          <FlexColumn gap="24px">
            <Typography variant="body1">
              Estamos aquí porque creemos que la innovación no debe ser sinónimo
              de incertidumbre, y que la inteligencia artificial es una gran
              herramientas para crear oportunidades en lugar de restringirlas.
            </Typography>
            <Typography variant="body1">
              Todas nuestras acciones están guiadas por la necesidad de
              contribuir a un futuro esperanzador para todos, por eso nuestro
              propósito es promover la justicia algorítmica, identificando y
              mitigando los sesgos en los modelos predictivos, y acompañando a
              las partes involucradas a{" "}
              <strong>construir un mejor futuro, desde ahora.</strong>
            </Typography>
          </FlexColumn>

          <IconIsologo sx={{ width: "200px", height: "200px" }} />
        </FlexRow>
      </MaxWidthContainer>
      <FullWidthBanner />
      <StyledTextDottedDivider>
        ¿Cómo podemos ayudarte ?
      </StyledTextDottedDivider>
      <VideoBanner />
      <MaxWidthContainer>
        <Grid
          container
          spacing={8}
          columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
        >
          <Grid item xs={4} sm={12} xl={12}>
            <HomeCard secondary="true">
              <FlexColumn
                padding="28px"
                gap="28px"
                alignItems="flex-start"
                sx={{ paddingRight: "112px" }}
              >
                <Typography variant="h5">
                  Acompañamientos personalizados
                </Typography>
                <Typography variant="body1">
                  “Si las funcionalidades de nuestro producto no cubren tus
                  necesidades actuales, nuestro equipo de expertos y consultores
                  en inteligencia artificial responsable pueden implementar
                  proyectos ad-hoc en este ámbito”
                </Typography>
                <Typography variant="body1">
                  Como equipo consultor y expertos en inteligencia artificial
                  responsable podemos acompañarte en distintos escenarios
                  <ul style={{ color: "#4D033F", margin: "0px" }}>
                    <li>Auditoría de Algoritmos</li>
                    <li>Servicios de Calidad de Datos</li>
                    <li>Estrategias de IAR</li>
                    <li>Asesoría Legal para Compliance</li>
                  </ul>
                </Typography>

                <StyledButton variant="outlined" secondary="true">
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "600",
                    }}
                  >
                    Contáctanos
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </HomeCard>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <StyledTextDottedDivider>Conoce nuestro equipo</StyledTextDottedDivider>
      <MaxWidthContainer>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "80px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Alvaro})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Álvaro Riascos
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Director
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Matemático con doctorado en Matemáticas Aplicadas (IMPA),
                    profesor e investigador en la Universidad de los Andes.
                    Experto en teoría del equilibrio, teoría de juegos y
                    matemáticas aplicadas, fundador de Quantil.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Mateo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Mateo Dulce
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Investigador Adjunto
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Ph.D. en Estadística y Políticas Públicas de Carnegie Mellon
                    University e investigador adjunto en Quantil y CAPP. Su
                    investigación desarrolla métodos y sistemas estadísticos
                    robustos para mejorar la toma de decisiones sociales basadas
                    en datos.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Catalina})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Catalina María Bernal Murcia
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Investigadora Senior
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Economista y física, con énfasis en biología de sistemas.
                    Especializada en IA ética y en el desarrollo de proyectos de
                    analítica enfocados en problemas sociales y ambientales.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Melissa})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Melissa Robles
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Investigadora Senior
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Matemática con estudios complementarios en matemáticas
                    aplicadas y computacionales. Su investigación se centra en
                    la IAR, modelos de traducción de lenguas indígenas y
                    análisis de datos genómicos.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Gary})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Gary Martin N.
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Director Comercial
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Responsable de la estrategia de marketing y ventas de
                    Criteria. Con más de 10 años de experiencia en marketing,
                    ventas y analítica, ha trabajado con empresas líderes para
                    promover soluciones innovadoras en IA responsable y
                    tecnología.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Paola})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Paola Luna
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Coordinadora de Proyectos
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Con enfoque en facilitar el trabajo en equipo y gestionar la
                    comunicación con aliados estratégicos, asegurando la
                    integración de la estrategia con el desarrollo tecnológico.
                    Fomenta alianzas clave para garantizar la implementación
                    efectiva de soluciones.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${CristianS})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Cristian Sánchez
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Director Área TI
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Ingeniero de Sistemas con Maestría en Informática y
                    Telecomunicaciones, conocimientos en sistemas de
                    recomendación. Amplia experiencia en .NET, Python y Java,
                    certificado Google Associate Cloud Engineer, líder de TI en
                    Quantil.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${David})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    David Lectamo
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Desarrollador
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Ingeniero de Sistemas con experiencia en desarrollo de
                    software, enfocado en el desarrollo back-end, manejo de base
                    de datos y configuración de API REST. Con experiencia en
                    liderazgo de proyectos y asignación de tareas.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${CristianL})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Cristian Lasso
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Desarrollador
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Ingeniero de Sistemas con experiencia en desarrollo de
                    software, enfocado en el desarrollo de aplicaciones web,
                    móviles y liderando proyectos CMS. Con experiencia en UX/UI.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} xl={4} sx={{ marginBottom: "32px" }}>
            <FlexColumn
              height="100%"
              padding="32px 0px 0px 0px"
              sx={{ position: "relative" }}
            >
              <BorderCard>
                <FlexColumn padding="216px 28px 28px 28px" gap="20px">
                  <Box
                    sx={{
                      background: `url(${Nicolas})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      top: "0px",
                      left: "50%",
                      transform: "translate(-50%)",
                      height: "228px",
                      width: "228px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" textAlign="center">
                    Nicolás Orozco
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Diseñador UX
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Diseñador de Medios Interactivos con enfoque UI/UX
                    especializado en la creación de interfaces intuitivas y
                    visualmente atractivas. Con enfoque de experiencia de
                    usuario que satisfagan las necesidades y los objetivos de
                    negocio.
                  </Typography>
                </FlexColumn>
              </BorderCard>
            </FlexColumn>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <StyledTextDottedDivider>Con el respaldo de</StyledTextDottedDivider>
      <LogosBanner />
      <FormSection />
      <StyledFooter />
    </PageContainer>
  );
};

export default AboutUs;
