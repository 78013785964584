import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import LogoCompleto from "../../../images/LogoCompleto.svg";
import IconFooterList from "../../icons/IconFooterList";
import { MaxWidthContainer, FlexColumn } from "../../shared/container/Index";

const CustomBox = styled(Box)(({ theme, ...props }) => ({
  background: "linear-gradient(90deg, #4D033F, #02403C)",
  marginTop: "80px",
  padding: "48px 0",
  width: "100%",
  "& .MuiTypography-root": {
    color: theme.palette.primary[50],
  },
}));

const CustomNavLink = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const CustomListItem = styled(ListItem)(({ theme, ...props }) => ({
  padding: "0px",
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme, ...props }) => ({
  minWidth: "unset",
  marginRight: "8px",
}));

const footerLinks = {
  contacto: [
    { text: "+34 682 92 82 53", href: "https://wa.me/34682928253" },
    {
      text: "gary.martin@quantil.com.co",
      href: "mailto:gary.martin@quantil.com.co",
    },
    { text: "Colombia - Panamá", href: "#" },
  ],
  mapaDelSitio: [
    { text: "Inicio", href: "/" },
    { text: "IA Responsable", href: "/about-us" },
    { text: "SaaS", href: "/solution-saas" },
    { text: "Recursos", href: "/resources" },
    { text: "Política de privacidad", href: "/privacy-policy" },
    { text: "Términos y condiciones", href: "/terms-and-conditions" },
  ],
  software: [
    { text: "Login / Signin", href: "/login" },
    { text: "Visualización de datos", href: "/login" },
    { text: "Rendimiento técnico", href: "/login" },
    { text: "Detección de Sesgos", href: "/login" },
    { text: "Mitigación de Sesgos", href: "/login" },
    { text: "Predicciones", href: "/login" },
    { text: "Glosario", href: "/login" },
  ],
};

const renderFooterLinks = (links) => (
  <List>
    {links.map((link, index) => (
      <CustomListItem
        key={index}
        component={Link}
        href={link.href}
        underline="none"
      >
        <CustomListItemIcon>
          <IconFooterList />
        </CustomListItemIcon>
        <ListItemText
          primary={<CustomNavLink variant="body1">{link.text}</CustomNavLink>}
        />
      </CustomListItem>
    ))}
  </List>
);

const StyledFooter = ({ props, src, alt }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <CustomBox {...props}>
      <MaxWidthContainer id="Footer">
        <Grid
          container
          spacing={8}
          columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
        >
          <Grid item xs={4} xl={4}>
            <FlexColumn alignItems="flex-start" gap="32px">
              <Box sx={{ cursor: "pointer" }}>
                <img
                  src={LogoCompleto}
                  alt={"logo footer"}
                  onClick={() => handleNavigation("/")}
                />
              </Box>

              <Typography variant="body1">
                Analizamos, auditamos y mejoramos modelos de inteligencia
                artificial de una forma accesible y transparente para que la
                falta de conocimiento no sea una barrera a la hora de mitigar
                sesgos.
              </Typography>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} sm={8} xl={8}>
            <Grid
              container
              spacing={8}
              columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
            >
              <Grid item xs={4} xl={4}>
                <FlexColumn alignItems="flex-start" gap="8px">
                  <Typography variant="h6">Contacto</Typography>
                  {renderFooterLinks(footerLinks.contacto)}
                </FlexColumn>
              </Grid>
              <Grid item xs={4} xl={4}>
                <FlexColumn alignItems="flex-start" gap="8px">
                  <Typography variant="h6">Mapa del sitio</Typography>
                  {renderFooterLinks(footerLinks.mapaDelSitio)}
                </FlexColumn>
              </Grid>
              <Grid item xs={4} xl={4}>
                <FlexColumn alignItems="flex-start" gap="8px">
                  <Typography variant="h6">Software</Typography>
                  {renderFooterLinks(footerLinks.software)}
                </FlexColumn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </CustomBox>
  );
};

export default StyledFooter;
