import PageContainer from "../../components/shared/container/PageContainer";
import MaxWidthContainer from "../../components/shared/container/MaxWidthContainer";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import FlexColumn from "../../components/shared/container/FlexColumn";
import FlexRow from "../../components/shared/container/FlexRow";
import { Box, Typography } from "@mui/material";
import StyledButton from "../../components/common/button/StyledButton";
import { Hero } from "../../images/Index";

const PrivacyPolicy = () => {
  return (
    <PageContainer>
      <Box
        sx={{
          backgroundImage: `url(${Hero})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "360px !important",
          marginBottom: "80px",
        }}
      />
      <MaxWidthContainer>
        <FlexColumn width="100%">
          <Typography
            variant="h1"
            sx={{
              fontSize: "52px",
              lineHeight: "58px",
              marginBottom: "16px",
            }}
          >
            Política de privacidad de datos personales
          </Typography>
          <FlexRow
            gap="10px"
            alignItems="center"
            sx={{
              marginBottom: "40px",
            }}
          >
            <StyledButton variant="outlined" secondary="true">
              <Typography
                variant="body1"
                sx={{
                  textWrap: "nowrap",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Criteria
              </Typography>
            </StyledButton>
            <Typography variant="h6">
              Auditoría Algorítmica, Noviembre 2024
            </Typography>
          </FlexRow>

          <Typography
            variant="body1"
            sx={{
              marginBottom: "16px",
              borderLeft: "5px solid #02403C",
              paddingLeft: "16px",
            }}
          >
            <Typography variant="h6">Objetivo</Typography>
            Presentar los lineamientos para el aseguramiento de la protección de
            los datos personales dentro de la organización, dando cumplimiento a
            la Ley 1581 de 2012, Decreto 1377 de 2013 y normas que modifiquen o
            adicionen el régimen de protección de datos personales.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Responsable
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            <Typography variant="body1">Razón Social: Quantil S.A.S</Typography>
            <Typography variant="body1">
              Domicilio Social: Bogotá D.C., Colombia
            </Typography>
            <Typography variant="body1">
              Dirección: Carrera 45 # 108-27 , Edificio Paralelo 108, Torre 2,
              Oficina 608
            </Typography>
            <Typography variant="body1">
              Teléfono: (+57) 318 783 41 96
            </Typography>
            <Typography variant="body1">email: datos@quantil.com.co</Typography>
            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
              www.quantil.co
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Esta política aplica para los datos alojados en la infraestructura
            física y virtual de Quantil S.A.S respetando los criterios para la
            obtención, recolección, uso, tratamiento, procesamiento,
            intercambio, transferencia y transmisión de datos personales, y
            fijar las responsabilidades de Quantil S.A.S. y de sus empleados en
            el manejo y tratamiento de los datos personales que reposen en sus
            bases de datos y archivos.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Consideraciones generales
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Quantil S.A.S. es una empresa que tiene plena conciencia de la
            privacidad emitida sobre los Datos Personales (DP) de cada persona
            natural, jurídica o empresa y se encuentra comprometida a
            salvaguardar todo sobre dicho derecho. Protegerá la información de
            carácter privado y confidencial que es obtenida dentro del giro
            ordinario de sus negocios en desarrollo de su objeto social. La
            presente política tiene como fin la protección de la información que
            le ha sido confiada, desde la intención de entrega, el medio por el
            cual es enviada y el almacenamiento de la misma, precisando siempre
            que toda esta información siempre entregada voluntariamente por el
            cliente y que este tiene todo el poder y propiedad sobre la misma.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Descripción de la política
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            <ul style={{ margin: "0px" }}>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  De acuerdo con su objeto social (prestación de servicios
                  relacionados a la aplicación de analítica de datos, desarrollo
                  de software, soporte y consultoría de los servicios de la
                  organización), Quantil maneja información que los clientes
                  suministran para la prestación de los servicios contratados,
                  por lo cual considera que el tratamiento de dicha información,
                  tiene tanta importancia como el desarrollo de sus actividades
                  y servicios. Por lo tanto, no ahorrará esfuerzos en el
                  cumplimiento de las normas para la protección de dicha
                  información.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Quantil S.A.S. en su condición de responsable de manejo de
                  información personal, es el encargado de ejecutar el
                  tratamiento de la misma en estricto cumplimiento de la
                  normatividad aplicable, garantizando los derechos que a los
                  titulares de la información les asiste.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Quantil S.A.S, en su actividad comercial maneja información
                  personal de sus clientes, colaboradores y proveedores en
                  general. Quantil se encarga del tratamiento de información
                  personal sobre la información que los clientes, que en virtud
                  de un contrato de servicios, entregan para la transformación
                  de dicha información que permita la aplicación de estrategias
                  analíticas para la toma de decisiones.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  La información mencionada se mantiene en repositorios y bases
                  de datos destinadas para los fines de los servicios
                  contratados.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  En desarrollo de las actividades, la recolección de datos
                  personales por parte de Quantil S.AS., se limitará a aquellos
                  datos personales que son pertinentes y adecuados para la
                  finalidad para la cual son recolectados o requeridos conforme
                  a la normatividad vigente y conforme a su objeto social. Salvo
                  en los casos expresamente previstos en la Ley, no se podrán
                  recolectar datos personales sin autorización del titular.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Los repositorios y las bases de datos cuentan con las medidas
                  de seguridad necesarias para la conservación adecuada de los
                  datos personales y actualmente se mantienen en el software de
                  la compañía y los servidores propios.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Quantil S.A.S., tiene páginas web y redes sociales donde las
                  personas con intereses en los servicios de la empresa, tienen
                  opción de suscribirse para recibir información de interés,
                  propuestas de servicios, entre otros. Los datos de los
                  titulares de la Información, serán compilados, almacenados,
                  consultados, usados, compartidos, intercambiados,
                  transmitidos, transferidos y objeto de tratamiento para las
                  siguientes finalidades:
                </Typography>

                <ul style={{ margin: "0px" }}>
                  <li>
                    <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                      Mantener una eficiente comunicación de la información que
                      sea de utilidad para los suscriptores.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                      Tratar a los suscriptores como potenciales clientes, y
                      enviarles información de interés.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Quantil S.A.S, presta servicios relacionados con la aplicación
                  de matemáticas, analítica de datos y desarrollo de software
                  para mejorar la toma de decisiones, dentro de los cuales
                  realiza la consolidación de la información de sus clientes, y
                  en ella, se incorpora información personal de los proveedores
                  y clientes de quienes tienen contrato con Quantil, en general
                  son registros con información pertinente y necesaria para el
                  debido análisis.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Dicha información se mantiene en los repositorios y las bases
                  de datos destinadas para tal fin y es usada por el cliente en
                  el software implementado.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Esta información pertenece exclusivamente a nuestros clientes,
                  siendo ellos los responsables del tratamiento, y será
                  almacenada únicamente para los siguientes fines: En virtud de
                  un contrato de prestación de servicios con nuestros clientes,
                  en el cual se haya ofrecido la carga de información, el
                  procesamiento de la información y la divulgación para los
                  fines de inteligencia de negocios.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Aunque dicha información no ha sido recolectada por Quantil,
                  ha sido encargado en virtud de un contrato para su
                  procesamiento en materia de análisis de datos y transformación
                  a indicadores, y se mantendrá únicamente para esos fines,
                  asegurando la privacidad sobre dicha información propia del
                  cliente.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  En los vínculos contractuales que desarrolle Quantil, podrá
                  encargar el tratamiento de su información personal a distintas
                  personas naturales o jurídicas para los fines misionales de la
                  empresa y prestación de servicios. Quienes se encargan de la
                  información deben tener políticas claras y acoger nuestra
                  política. Serán obligaciones del encargado del tratamiento las
                  siguientes:
                  <ul style={{ margin: "0px" }}>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Tomar las medidas necesarias para garantizar la
                        confidencialidad de la información.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Utilizar la información suministrada por el responsable
                        del tratamiento que revele la información únicamente de
                        la manera y para los fines establecidos en este
                        contrato.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Abstenerse realizar para sí mismo o para terceros,
                        copias, arreglos, reproducciones, adaptaciones o
                        cualquier otra clase de mutilación, deformación o
                        modificación de la información confidencial.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        No divulgar a terceros o a ninguna otra persona o
                        entidad la información confidencial sin el
                        consentimiento previo, expreso y por escrito del
                        responsable del tratamiento.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Garantizar que los sistemas de comunicación y la
                        infraestructura tecnológica en la cual se almacenan los
                        datos recibidos del responsable del tratamiento, poseen
                        los elementos de seguridad necesarios para salvaguardar
                        la información. Al utilizar medios de intercambio de
                        información tales como el correo electrónico e Internet,
                        aplicarán las mismas medidas para asegurar que la
                        información no sea vista ni modificada por personas
                        ajenas al proceso.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Respetar en todo momento las políticas de seguridad de
                        la información establecidas por el responsable del
                        tratamiento.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        No reproducir la información confidencial entregada,
                        salvo si ello resulta necesario para cumplir la
                        finalidad para la cual ha sido suministrada y sólo se
                        dará a conocer a aquellos empleados, trabajadores o
                        asesores, que tengan necesidad de tal conocimiento, de
                        acuerdo a lo indicado por el supervisor del contrato.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        No ceder en ningún caso a terceras personas los datos de
                        carácter personal a los que tengan acceso, ni para
                        efectos de su conservación.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Al finalizar los vínculos comerciales por cualquier
                        causa, el encargado del tratamiento, deberá destruir
                        toda la documentación o información que contenga los
                        datos y que llegare a tener en su poder como
                        consecuencia de cualquier relación contractual de las
                        partes, utilizando métodos de destrucción adecuados, los
                        cuales en todo caso deberán ser aprobados por el
                        responsable del tratamiento de acuerdo con el nivel de
                        sensibilidad y el medio en el cual se encuentre
                        almacenada.
                      </Typography>
                    </li>
                  </ul>
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Finalidad del tratamiento de los datos personales
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Los datos personales proporcionados a Quantil serán objeto de
            tratamiento (recolección, almacenamiento, uso, circulación o
            supresión) para la finalidad específica para la que fueron
            suministrados y para dar cumplimiento a sus demás funciones
            constitucionales y legales de la entidad.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Ejercicio de los derechos de los titulares de los datos personales
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Los titulares de los datos personales, podrán acceder, conocer,
            actualizar y rectificar dichos datos; ser informados sobre el uso
            dado a los mismos; presentar consultas y reclamos sobre el manejo de
            dichos datos; revocar la autorización o solicitar la supresión de
            sus datos, en los casos en que sea procedente, y los demás derechos
            que le confiere la Ley.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Para ejercer su derecho fundamental de habeas data podrá emplear los
            mecanismos de contacto informados por Quantil.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Los procedimientos y términos para la atención de consultas,
            reclamos y demás peticiones referidas al ejercicio del derecho de
            hábeas data seguirán lo dispuesto en la Ley 1266 de 2008 y los
            principios sobre protección de datos contemplados en la Ley 1581 de
            2012.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Consultas, acceso y suministro de información
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            El Titular de los datos podrá realizar su consulta a QUANTIL, por
            los siguientes canales: correo electrónico (
            <a href="mailto:info@quantil.com.co" className="link">
              info@quantil.com.co{" "}
            </a>
            ), indicando en el asunto la solicitud; en la sección de Contáctenos
            de nuestra página web (
            <a href="https://quantil.co/" className="link">
              https://quantil.co/{" "}
            </a>
            ), La solicitud deberá tener los siguientes datos:
            <ul style={{ margin: "0px" }}>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Nombre y apellidos del Titular.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Petición en que se concreta la solicitud de acceso o consulta.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Dirección para notificaciones, fecha y firma del solicitante.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Documentos acreditativos de la petición formulada, cuando
                  corresponda.
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            De acuerdo con lo establecido en la Ley, el término de la Compañía
            para resolver estas consultas es de diez (10) días hábiles contados
            a partir de la fecha de recibo de la misma, y dará respuesta a
            través de correo ordinario o electrónico. Cuando no es posible
            atender la consulta dentro de dicho término, se informará al
            interesado, expresando los motivos de la demora y señalando la fecha
            en que se atenderá su consulta, la cual en ningún caso puede superar
            los cinco (5) días hábiles siguientes al vencimiento del primer
            término.
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Reclamaciones
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            En el tratamiento de los datos se distinguen en principio cuatro
            tipos de reclamos: reclamo de corrección, reclamo de supresión,
            reclamo de revocación y reclamo de infracción.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            El Titular de los datos podrá realizar su reclamo a QUANTIL, por los
            siguientes canales: correo electrónico (info@quantil.com.co)
            indicando en el asunto la solicitud; en la sección de Contáctenos de
            nuestra página web (quantil.com.co), La solicitud deberá tener los
            siguientes datos:
            <ul style={{ margin: "0px" }}>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Nombre y apellidos del Titular.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Descripción de los hechos y petición en que se concreta la
                  solicitud de corrección, supresión, revocación o infracción.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Dirección para notificaciones, fecha y firma del solicitante.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  Documentos acreditativos de la petición formulada que se
                  quiera hacer valer, cuando corresponda. Si la reclamación
                  resulta incompleta, se requerirá al interesado dentro de los
                  cinco (5) días siguientes a la recepción de la reclamación
                  para que subsane las fallas. Transcurridos dos (2) meses desde
                  la fecha del requerimiento sin que el solicitante presente la
                  información requerida, se entenderá que ha desistido del
                  reclamo.
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Una vez recibida la reclamación completa, se incluirá en la base de
            datos una leyenda que diga “Reclamo en trámite” y el motivo del
            mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda
            deberá mantenerse hasta que el reclamo sea decidido.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            De acuerdo con lo establecido en la ley, el término de la Compañía
            para resolver estos reclamos es de quince (15) días hábiles contados
            a partir de la fecha de recibo de esta, y dará respuesta a través de
            correo ordinario o electrónico. Cuando no es posible atender la
            consulta dentro de dicho término, se informará al interesado,
            expresando los motivos de la demora y señalando la fecha en que se
            atenderá su consulta, la cual en ningún caso puede superar los ocho
            (8) días hábiles siguientes al vencimiento del primer término.
          </Typography>
        </FlexColumn>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default PrivacyPolicy;
