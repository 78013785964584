import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconFooterList = (props) => {
  const { green, purple } = props;
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M10.1279 0.854736C10.1946 0.854736 10.2487 0.908623 10.2487 0.975096C10.2487 3.92874 10.9424 6.20408 12.2993 7.80518C13.1949 7.40781 14.0912 6.7637 14.9853 5.86959C15.0185 5.83641 15.0723 5.83641 15.1054 5.86959C15.1386 5.90277 15.1386 5.95656 15.1054 5.98974C14.2137 6.8815 13.5704 7.7753 13.1726 8.66837C14.7817 10.0006 17.0594 10.6804 20.0072 10.6804C20.0739 10.6804 20.1279 10.7343 20.1279 10.8007C20.1279 10.8672 20.0739 10.9211 20.0072 10.9211C17.0409 10.9211 14.7529 11.6085 13.142 12.9569C13.5353 13.8678 14.1846 14.7797 15.0944 15.6894C15.1275 15.7226 15.1275 15.7764 15.0944 15.8096C15.0612 15.8427 15.0074 15.8427 14.9742 15.8096C14.0657 14.9011 13.1551 14.2523 12.2452 13.8589C10.9241 15.4559 10.2487 17.7099 10.2487 20.6264C10.2487 20.6928 10.1946 20.7467 10.1279 20.7467C10.0612 20.7467 10.0072 20.6928 10.0072 20.6264C10.0072 17.7118 9.33246 15.4587 8.01266 13.8617C7.09977 14.2572 6.18607 14.9091 5.27455 15.8206C5.24137 15.8538 5.18757 15.8538 5.15439 15.8206C5.12121 15.7875 5.12121 15.7337 5.15439 15.7005C6.06725 14.7876 6.71948 13.8724 7.11456 12.9578C5.50365 11.6089 3.21543 10.9211 0.248673 10.9211C0.181988 10.9211 0.12793 10.8672 0.12793 10.8007C0.12793 10.7343 0.181988 10.6804 0.248673 10.6804C3.19838 10.6804 5.47744 9.99926 7.0869 8.66476C6.69116 7.77624 6.05194 6.88729 5.16547 6.00082C5.13229 5.96764 5.13229 5.91384 5.16547 5.88067C5.19865 5.84749 5.25245 5.84749 5.28562 5.88067C6.1743 6.76934 7.06538 7.40958 7.95596 7.80516C9.31312 6.20397 10.0072 3.92857 10.0072 0.975096C10.0072 0.908623 10.0612 0.854736 10.1279 0.854736Z"
          fill={green ? "#02403C" : purple ? "#4D033F" : "#EBFDEA"}
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconFooterList;
