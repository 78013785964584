import React from "react";
import { Box, Skeleton, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

const CustomSkeleton = styled(Skeleton)(({ theme, ...props }) => ({
  borderRadius: "8px",
  height: "200px",
  width: "100%",
  minWidth: "100%",
}));

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme, ...props }) => ({
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
  })
);

const StyledSkeletonRectangular = (props) => {
  return (
    <Box sx={{ position: "relative", width: "100%", height: "200px" }}>
      <CustomSkeleton
        variant="rectangular"
        sx={{ bgcolor: "grey.300" }}
        {...props}
      />
      <CustomCircularProgress />
    </Box>
  );
};

export default StyledSkeletonRectangular;
