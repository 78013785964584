import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import IconSliderArrow from "../../icons/IconSliderArrow";

const CustomButton = styled(IconButton)(({ theme, prev, ...props }) => ({
  transform: prev
    ? "translate(0%, -50%) rotate(180deg)"
    : "translate(0%, -50%) rotate(0deg)",
  position: "absolute",
  display: "block",
  zIndex: "5",
  left: prev ? "-20px" : "",
  right: prev ? "" : "-20px",
  width: "40px",
  height: "40px",
}));

const StyledArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <CustomButton
      className={className}
      style={{ ...style }}
      onClick={onClick}
      {...props}
    >
      <IconSliderArrow
        sx={{
          position: "absolute",
          width: "40px",
          height: "40px",
          top: "0",
          left: "0",
        }}
      />
    </CustomButton>
  );
};

export default StyledArrow;
