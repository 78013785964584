import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import {
  PageContainerDashboard,
  FlexColumn,
  FlexRow,
  MaxWidthContainer,
  BorderCard,
} from "../../components/shared/container/Index";
import { Grid, Typography } from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import StyledButton from "../../components/common/button/StyledButton";

const Payment = () => {
  const navigate = useNavigate();
  const { actualData } = useContext(DataContext);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <PageContainerDashboard>
      <Grid
        container
        spacing={2}
        sx={{ p: "20px", alignContent: "flex-start" }}
      >
        <Grid item xs={12}>
          <FlexRow justifycontent="space-between" alignItems="center">
            <Typography variant="h5">Compra de plan</Typography>
            <FlexColumn alignItems="center" gap="8px">
              <StyledButton
                sx={{ width: "max-content", minWidth: "100%" }}
                secondary="true"
              >
                <Typography
                  variant="body2"
                  onClick={() => handleNavigation(`/dashboard`)}
                >
                  ← Cambiar de plan
                </Typography>
              </StyledButton>
            </FlexColumn>
          </FlexRow>

          <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
        </Grid>
        <MaxWidthContainer>
          <Grid
            container
            spacing={0}
            columns={{
              xs: 4,
              sm: 4,
              md: 12,
              xl: 12,
              alignContent: "flex-start",
            }}
          >
            <Grid item xs={4} sm={4} md={6} xl={6}>
              <FlexColumn
                padding="24px"
                sx={{ padding: "48px 104px 48px 80px" }}
              >
                <Typography variant="h6">Metodo de pago</Typography>
                <FlexColumn></FlexColumn>
                <FlexColumn></FlexColumn>
                <FlexColumn></FlexColumn>
              </FlexColumn>
            </Grid>
            <Grid item xs={4} sm={4} md={6} xl={6}>
              <FlexColumn
                sx={{
                  padding: "48px 56px",
                  background: "#DADFDF",
                  borderRadius: "12px",
                }}
              >
                <BorderCard padding="16px" sx={{ background: "transparent" }}>
                  <FlexColumn gap="16px">
                    <Typography variant="h6">Detalles del plan</Typography>
                  </FlexColumn>
                </BorderCard>
              </FlexColumn>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </Grid>
    </PageContainerDashboard>
  );
};

export default Payment;
