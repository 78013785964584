// api/apiService.js
import { useAxios } from "../context/AxiosProvider";

export const useApiClient = () => {
  const api = useAxios();

  return {
    get: async (url, config = {}) => {
      try {
        const response = await api.get(url, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    post: async (url, data, config = {}) => {
      try {
        const response = await api.post(url, data, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    put: async (url, data, config = {}) => {
      try {
        const response = await api.put(url, data, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    patch: async (url, data, config = {}) => {
      try {
        const response = await api.patch(url, data, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    del: async (url, config = {}) => {
      try {
        const response = await api.delete(url, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};
