import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography } from "@mui/material";
import {
  FlexColumn,
  PageContainer,
  MaxWidthContainer,
  BorderCard,
  FlexRow,
  HomeCard,
} from "../../components/shared/container/Index";
import "react-toastify/dist/ReactToastify.css";
import StyledButton from "../../components/common/button/StyledButton";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import StyledTextDottedDivider from "../../components/common/divider/StyledTextDottedDivider";
import {
  Hero,
  SliderImage,
  SliderImage2,
  SliderImage3,
  SolutionVideo,
} from "../../images/Index";
import StyledImage from "../../components/common/image/StyledImage";
import StyledSlider from "../../components/common/slider/StyledSlider";
import LogosBanner from "../../components/shared/section/LogosBanner";
import FormSection from "../../components/shared/section/FormSection";
import {
  IconFinancial,
  IconGovernment,
  IconHealth,
} from "../../components/icons/Index";
import FullWidthBanner from "../../components/shared/section/FullWidthBanner";
import VideoBanner from "../../components/shared/section/VideoBanner";
import { useNavigate } from "react-router-dom";
import BlogSlider from "../../components/shared/section/BlogSlider";

const Homepage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <PageContainer>
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "72px", marginBottom: "112px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} sm={7} xl={7}>
            <FlexColumn
              gap="28px"
              alignItems="flex-start"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h2">IA RESPONSABLE</Typography>
              <Typography variant="h1">
                Naveguemos el mundo de la inteligencia artificial responsable
              </Typography>
              <Typography variant="body1">
                Cuando pensamos en el futuro, podemos imaginarnos uno incierto y
                estéril en el que perdemos protagonismo, o bien, uno en el que
                la innovación y la tecnología per se son la solución a nuestros
                problemas. Por esta razón, lo que nosotros proponemos es ese
                balance sano y necesario, donde somos capaces de evaluar
                nuestras posibilidades y tomar decisiones informadas, sin
                olvidarnos de que la equidad debe existir para que los avances
                puedan ser considerados realmente como un avance.
              </Typography>
              <Typography variant="body2">
                Somos el aliado en momentos decisivos, porque el futuro se ve
                más optimista si pensamos en todos.
              </Typography>
              <StyledButton
                variant="outlined"
                secondary="true"
                onClick={() => handleNavigation("/about-us")}
              >
                <Typography
                  variant="h5"
                  sx={{
                    lineHeight: "100%",
                    marginBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Conócenos
                </Typography>
              </StyledButton>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} sm={5} xl={5}>
            <StyledImage src={Hero} alt="hero" />
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <FullWidthBanner />
      <StyledTextDottedDivider>
        Conoce nuestra plataforma
      </StyledTextDottedDivider>
      <VideoBanner />
      <MaxWidthContainer sx={{ marginTop: "72px", marginBottom: "80px" }}>
        <Grid container spacing={1} columns={{ xs: 4, sm: 12, xl: 12 }}>
          <Grid item xs={4} sm={4} xl={4} sx={{ alignContent: "center" }}>
            <BorderCard
              shadow="true"
              sx={{ margin: "24px", alignContent: "flex-start" }}
            >
              <FlexColumn
                padding="24px 24px"
                gap="20px"
                alignItems="center"
                justifyContent="flex-start"
                height="100%"
              >
                <StyledImage
                  src={SliderImage}
                  alt="SliderImage"
                  width="222px"
                  height="200px"
                />
                <Typography variant="h5" textAlign="center">
                  Rendimiento técnico
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Evalúa tus algoritmos según métricas apropiadas a tu caso de
                  uso. Analiza qué tanto capturan tus predicciones los datos
                  observados.
                </Typography>
                <StyledButton
                  variant="outlined"
                  secondary="true"
                  sx={{ marginTop: "auto" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "600",
                    }}
                  >
                    Ver más
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </BorderCard>
          </Grid>
          <Grid item xs={4} sm={4} xl={4} sx={{ alignContent: "center" }}>
            <BorderCard
              shadow="true"
              sx={{ margin: "24px", alignContent: "flex-start" }}
            >
              <FlexColumn
                padding="24px 24px"
                gap="20px"
                alignItems="center"
                justifyContent="flex-start"
                height="100%"
              >
                <StyledImage
                  src={SliderImage2}
                  alt="SliderImage2"
                  width="180px"
                  height="200px"
                />
                <Typography variant="h5" textAlign="center">
                  Detección de sesgos
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Cuantifica los niveles de equidad de tus algoritmos con
                  métricas apropiadas a tu caso de uso. Analiza cómo tus modelos
                  impactan diferencialmente a grupos poblacionales.
                </Typography>
                <StyledButton
                  variant="outlined"
                  secondary="true"
                  sx={{ marginTop: "auto" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "600",
                    }}
                  >
                    Ver más
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </BorderCard>
          </Grid>
          <Grid item xs={4} sm={4} xl={4} sx={{ alignContent: "center" }}>
            <BorderCard
              shadow="true"
              sx={{ margin: "24px", alignContent: "flex-start" }}
            >
              <FlexColumn
                padding="24px 24px"
                gap="20px"
                alignItems="center"
                justifyContent="flex-start"
                height="100%"
              >
                <StyledImage
                  src={SliderImage3}
                  alt="SliderImage3"
                  width="200px"
                  height="200px"
                />
                <Typography variant="h5" textAlign="center">
                  Mitigación de sesgos
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Reduce los sesgos presentes en tus modelos y potencia su
                  impacto positivo en tus usuarios.
                </Typography>
                <StyledButton
                  variant="outlined"
                  secondary="true"
                  sx={{ marginTop: "auto" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: "100%",
                      marginBottom: "4px",
                      fontWeight: "600",
                    }}
                  >
                    Ver más
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </BorderCard>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "72px", marginBottom: "112px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} sm={7} xl={7}>
            <FlexColumn
              gap="28px"
              alignItems="flex-start"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h2">BENEFICIOS</Typography>
              <Typography variant="h1">¿Por qué usar Criteria?</Typography>
              <Typography variant="body1">
                <ul style={{ margin: "0px" }}>
                  <li>Detección automática de sesgos.</li>
                  <li>Cumplimiento con estándares de IA responsable.</li>
                  <li>Optimización de decisiones basadas en datos justos.</li>
                  <li>Escalabilidad sin complicaciones en la nube.</li>
                  <li>Integración rápida con tus sistemas actuales.</li>
                  <li>Aumenta la confianza de tus clientes y usuarios.</li>
                  <li>Mejora la equidad en procesos de recursos humanos.</li>
                  <li>Análisis detallado y reportes de rendimiento.</li>
                </ul>
              </Typography>
              <StyledButton
                variant="outlined"
                secondary="true"
                onClick={() => handleNavigation("/register")}
              >
                <Typography
                  variant="h5"
                  sx={{
                    lineHeight: "100%",
                    marginBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Registrarme
                </Typography>
              </StyledButton>
            </FlexColumn>
          </Grid>
          <Grid item xs={4} sm={5} xl={5} sx={{ alignContent: "center" }}>
            <StyledImage src={SolutionVideo} alt="hero" />
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <MaxWidthContainer sx={{ marginTop: "72px", marginBottom: "80px" }}>
        <Grid container spacing={3} columns={{ xs: 4, sm: 12, xl: 12 }}>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancial sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Industria financiera
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Aprobación de Préstamos y Créditos
                </Typography>
                <StyledButton variant="contained">Ver caso de uso</StyledButton>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconGovernment sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Gobierno
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Asignación de Recursos y Servicios Públicos
                </Typography>
                <StyledButton variant="contained">Ver caso de uso</StyledButton>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconHealth sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Hospital
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Diagnóstico y Tratamiento Personalizado
                </Typography>
                <StyledButton variant="contained">Ver caso de uso</StyledButton>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconHealth sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Aseguradora
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Diagnóstico y Tratamiento Personalizado
                </Typography>
                <StyledButton variant="contained">Ver caso de uso</StyledButton>
              </FlexColumn>
            </HomeCard>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <StyledTextDottedDivider>
        Conoce nuestros recursos
      </StyledTextDottedDivider>
      <BlogSlider />
      <StyledTextDottedDivider>Con el respaldo de</StyledTextDottedDivider>
      <LogosBanner />
      <FormSection />
      <StyledFooter />
    </PageContainer>
  );
};

export default Homepage;
