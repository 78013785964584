import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme, secondary, vertical, ...props }) => ({
  width: vertical ? "2px" : "100%",
  height: vertical ? "100%" : "2px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "48px",
  marginBottom: "80px",
}));

const CustomDivider = styled(Box)(
  ({ theme, secondary, purple, vertical, ...props }) => ({
    borderTop: vertical
      ? "none"
      : secondary
      ? "2px dashed " + theme.palette.primary[50]
      : purple
      ? "2px dashed #4D033F"
      : "2px dashed " + theme.palette.primary.main,
    borderLeft: vertical
      ? secondary
        ? "2px dashed " + theme.palette.primary[50]
        : purple
        ? "2px dashed #4D033F"
        : "2px dashed " + theme.palette.primary.main
      : "none",
    height: vertical ? "100%" : "2px",
    width: vertical ? "2px" : "100%",
  })
);

const StyledDottedDivider = (props) => {
  const { secondary, purple, vertical } = props;

  return (
    <CustomBox {...props}>
      <CustomDivider
        secondary={secondary}
        purple={purple}
        vertical={vertical}
      />
    </CustomBox>
  );
};

export default StyledDottedDivider;
