import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconSliderArrow = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
      >
        <circle cx="32.4919" cy="32.4362" r="31.8787" fill="#02403C" />
        <path
          d="M35.827 29.6428L29.6561 23.4719L33.6171 19.4692L46.5843 32.4363L33.6171 45.4035L29.6561 41.4425L35.7853 35.2716H18.3984V29.6428H35.827Z"
          fill="#EBFDEA"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconSliderArrow;
