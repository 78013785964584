import React from "react";
import { Grid, Typography } from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import {
  PageContainerDashboard,
  FlexColumn,
} from "../../components/shared/container/Index";
import "react-toastify/dist/ReactToastify.css";
import StyledAccordion from "../../components/common/accordion/StyledAccordion";
import {
  Calibracion,
  CurvaROC,
  CurvaROC2,
  EqualOpportunities,
  EqualOpportunities2,
  JusticiaPorCalibracion,
  JusticiaPorCalibracion2,
} from "../../images/Index";

const Glossary = () => {
  return (
    <PageContainerDashboard>
      <Grid
        container
        spacing={3}
        sx={{ p: "20px", alignContent: "flex-start" }}
      >
        <Grid item xs={12}>
          <FlexColumn gap="8px">
            <Typography variant="h5">Glosario</Typography>
            <Typography variant="body1">
              En esta sección encontrarás el glosario.
            </Typography>
            <StyledDivider />
          </FlexColumn>
        </Grid>
        <Grid item xs={12}>
          <FlexColumn gap="20px">
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Terminologías generales
            </Typography>
            <StyledAccordion
              title="Aprendizaje supervisado"
              details="El aprendizaje supervisado es un enfoque en el campo de la inteligencia artificial en el cual un modelo se entrena para efectuar predicciones o tomar decisiones fundamentadas en ejemplos previamente etiquetados. En este tipo de aprendizaje, el algoritmo recibe un conjunto de datos de entrenamiento que contiene entradas y sus respectivas salidas deseadas (etiquetas) asociadas. El objetivo del algoritmo es aprender una función que logre mapear las entradas a las salidas de manera precisa. Un ejemplo ilustrativo de esto se encuentra en los modelos de calificación crediticia, los cuales se fundamentan en información histórica de préstamos para predecir la probabilidad de que una nueva persona o entidad cumpla con un préstamo futuro."
            />
            <StyledAccordion
              title="Variable protegida"
              details="Una variable protegida es una característica o atributo asociado a una catacterística poblacional que se considera sensible o que podría dar lugar a discriminación, sesgos u otros efectos adversos al emplear un modelo de aprendizaje. Ejemplos de variables protegidas incluyen género, sexo, raza, edad, nivel socioeconómico, nacionalidad, orientación sexual, ubicación de residencia, entre otros."
            />
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Métricas según tipo de modelo
            </Typography>
            <StyledAccordion
              title="Calibración"
              details="La calibración de un modelo de scoring evalúa cuán bien el modelo refleja los eventos en el mundo real. Esta medida es relevante para modelos que asignan valores de probabilidad a pertenecer a una categoría positiva (como la probabilidad de que alguien repague un préstamo). En este sentido, si 100 personas reciben una probabilidad del 20%, se espera que alrededor de 20 de ellas realmente repaguen el préstamo. Por consiguiente, para cada valor de probabilidad  asignado, se anticipa que la proporción real de personas en la categoría positiva sea cercana a  entre aquellas a las que se les asignó ese puntaje. Como resultado, se crea un gráfico que contrasta la probabilidad asignada con la proporción efectiva en la realidad. A modo de ejemplo, se considera la curva de calibración evidenciada en la Figura 2. En esta, se evidencia que entre todos los datos marcados con una probabilidad de 0.5, más del 60% fueron realmente positivos. La diagonal indica un modelo perfectamente calibrado, en el que todas las probabilidades asignadas por el modelo se reflejan en la vida real."
              image={Calibracion}
            />
            <StyledAccordion
              title="Curva ROC"
              details="La curva ROC ilustra los niveles de sensibilidad (recall) y especificidad (consulta las definiciones de las métricas binarias) de los modelos binarios generados a partir del modelo de scoring. Estos modelos binarios se generan mediante la aplicación de un umbral al modelo en análisis. Como ejemplo, si tomamos un umbral de 0.5 para un modelo , creamos un nuevo clasificador definido de la siguiente forma:"
              image={CurvaROC}
              details2="Al variar el umbral, se calculan los valores de sensibilidad y especificidad correspondientes al modelo binario generado, y se representan gráficamente los valores de sensibilidad frente a 1-especificidad.

En la Figura 3, se presenta un ejemplo de la curva ROC de un clasificador perfecto. En algún umbral, el modelo binario resultante alcanza valores máximos de sensibilidad y especificidad, representados como el punto (0,1) en la esquina superior izquierda."
              image2={CurvaROC2}
            />
            <StyledAccordion
              title="AUC"
              details="La métrica AUC mide el área bajo la curva ROC del modelo. Esta toma valores entre 0 y 1, en donde 1 representa un modelo perfecto, mientras que valores cercanos a 0 indican modelos con altos errores de predicción. Un valor de 0.5 indica que el modelo tiene una curva ROC cercana a la diagonal."
            />
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Métricas binarias
            </Typography>
            <StyledAccordion
              title="Accuracy (Exactitud)"
              details="La “exactitud” representa el número de aciertos en comparación con todas las veces que hiciste una predicción. Por ejemplo, si adivinaste correctamente 8 de 10 veces, significa que tu exactitud es del 80%. En otras palabras, acertaste el 80% de las veces."
            />
            <StyledAccordion
              title="Recall (Sensibilidad)"
              details="El “recall” en un modelo binario indica cuántos elementos positivos logra detectar correctamente en comparación con todos los elementos positivos que realmente existen. Un “recall” alto significa que el modelo es bueno para encontrar esos elementos positivos, mientras que un “recall” bajo significa que podría estar ignorando algunos de ellos."
            />
            <StyledAccordion
              title="Especificidad"
              details="La especificidad mide la capacidad del modelo para evitar clasificar erróneamente casos que no pertenecen a esa clase en particular como si lo hicieran. Esto es particularmente útil cuando hay un desequilibrio en la cantidad de casos entre diferentes clases, ya que la especificidad proporciona una idea más precisa de cómo el modelo está manejando las instancias de la clase de interés."
            />
            <StyledAccordion
              title="Precision (Precisión)"
              details="La “precisión” en un modelo binario indica cuántos elementos identificados como positivos por el modelo son realmente positivos en comparación con todos los elementos que el modelo clasificó como positivos. Una “precisión” alta significa que el modelo es preciso en identificar esos elementos importantes, mientras que una “precisión” baja podría significar que está incluyendo algunos elementos incorrectos."
            />
            <StyledAccordion
              title="F1-Score"
              details="El valor de F1 representa una ponderación entre las métricas de Precisión y Recall. Optimizar esta métrica representa la optimización de ambas métricas consideradas."
            />
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Definiciones de justicia - Scoring
            </Typography>
            <StyledAccordion
              title="Demographic parity"
              details="El concepto de paridad estadística se refiere a una propiedad de un modelo que mantiene, en términos porcentuales, la misma proporción de predicciones positivas en cada categoría de una variable protegida."
            />
            <StyledAccordion
              title="Equal Opportunities"
              details="El concepto de Equal Opportunities (EO) se basa en la idea de que un modelo justo debe tener el mismo rendimiento en cada categoría de una variable protegida. En otras palabras, si tenemos una variable protegida VP con dos categorías A y B, el modelo debe predecir igual de bien a los individuos en la categoría A que a aquellos en la categoría B. Para satisfacer EO, un modelo debe satisfacer que las curvas ROC correspondientes a cada una de las categorías deben ser iguales. Esto significa que, para cada posible umbral τ, el modelo binario obtenido tiene que satisfacer"
              image={EqualOpportunities}
              details2="Un ejemplo de modelo que no cumple la condición de Equal Opportunities se evidencia en la"
              image2={EqualOpportunities2}
            />
            <StyledAccordion
              title="Equalized Odds"
              details="Dado que Equal Opportunities define una característica difícil de obtener en un modelo dado que impone restricciones fuertes sobre las métricas resultantes, se define Equalized Odds, que busca relajar esas condiciones. En particular, un modelo satisface Equalized Odds si satisface solo una de las dos condiciones (1) y (2) de Equal Opportunities."
            />
            <StyledAccordion
              title="Justicia por calibración"
              details="Al igual que Equal Opportunities, la justicia por calibración busca que el poder predictivo de un modelo se mantenga a lo largo de las categorías de una variable protegida. Un modelo satisface Justicia por Calibración si las curvas de calibración son iguales para cada una de las categorías existentes. En otras palabras, para todo p [0, 1] ,"
              image={JusticiaPorCalibracion}
              details2="En la Figura 6 se evidencia un modelo que no satisface justicia por calibración:"
              image2={JusticiaPorCalibracion2}
            />
            <Typography variant="h5" sx={{ margin: "10px 0px" }}>
              Métricas de justicia
            </Typography>
            <StyledAccordion
              title="Proportional parity"
              details="Esta métrica es similar a “Demographic Parity”. Se logra cuando la proporción de resultados positivos o favorables en los diferentes grupos es similar. Por ejemplo, en una revisión automática de currículums, tanto candidatos femeninos como masculinos deberían ser aceptados en proporciones cercanas, como el 25% cada uno."
            />
            <StyledAccordion
              title="False discovery rate parity"
              details="Con esta métrica se busca que la fracción de falsos positivos dentro de todos los positivos predichos sea similar entre cada grupo demográfico o categoría sensible."
            />
            <StyledAccordion
              title="False positive rate parity"
              details="Se relaciona con evaluar si la tasa de falsos positivos es similar o igual para diferentes grupos. Se calcula comparando la tasa de falsos positivos entre diferentes grupos demográficos o categorías sensibles y determinando si hay un sesgo o disparidad significativa en las tasas."
            />
            <StyledAccordion
              title="True negative rate parity"
              details="Esta métrica busca que la tasa de verdaderos negativos sea similar o igual para diferentes grupos. Se calcula comparando la tasa de verdaderos negativos entre diferentes grupos demográficos o categorías sensibles y determinando si hay un sesgo o disparidad significativa en las tasas."
            />
            <StyledAccordion
              title="False omission rate parity"
              details="Con esta métrica se busca que la fracción de falsos negativos dentro de los negativos predichos sea similar entre cada grupo demográfico o categoría sensible."
            />
            <StyledAccordion
              title="False negative rate parity"
              details="Esta métrica busca que la tasa de falsos negativos sea similar o igual para los grupos demográficos o sensibles. Se calcula comparando la tasa de falsos negativos entre cada grupo y determinando si hay un sesgo o disparidad significativa en las tasas."
            />
          </FlexColumn>
        </Grid>
      </Grid>
    </PageContainerDashboard>
  );
};

export default Glossary;
