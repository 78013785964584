import React, { useContext, useState, useEffect } from "react";
import { useApiClient } from "../../../api/apiService";
import { toast } from "react-toastify";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Typography, styled } from "@mui/material";
import StyledButton from "../../common/button/StyledButton";
import { EchartsContext } from "../../../context/EchartsContext";
import { DataContext } from "../../../context/DataContext";
import { LogoPDF } from "../../../images/Index";
import styles from "./PdfStyles";
import { useAuth } from "../../../context/AuthProvider";

const CustomPDFDownloadLink = styled(PDFDownloadLink)(
  ({ theme, ...props }) => ({
    color: theme.palette.primary[50],
    textDecoration: "none",
    fontWeight: "500",
  })
);

const MyDocument = ({ chartImages, texts, distributionTexts, userName }) => {
  const formatBulletPoint = (text) => {
    return <Text style={styles.bulletText}>• {text}</Text>;
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const methodologicalDetails = distributionTexts.filter(
    (text) => text.ver_mas
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.boxRow} wrap={false}>
          <View style={styles.boxColumn}>
            <Text style={styles.title}>Reporte de Mitigación de Sesgos</Text>
            <View style={styles.boxRow} wrap={false}>
              <Text style={styles.subtitle}>Fecha:</Text>
              <Text style={styles.greentext}>
                {new Date().toISOString().split("T")[0]}
              </Text>
              <Text style={styles.greentext}>-</Text>
              <Text style={styles.subtitle}>Usuario:</Text>
              <Text style={styles.greentext}>{userName}</Text>
            </View>
          </View>
          <Image style={styles.image} src={LogoPDF} />
        </View>
        <Text style={{ ...styles.text }}>{texts && texts.introduccion}</Text>

        {chartImages["antesMitigationCharts"]?.length > 0 && (
          <View style={styles.boxRowWrap} wrap={false}>
            {chartImages["antesMitigationCharts"].map((imgData, index) =>
              index === 0 ? (
                <View style={styles.graphBox} key={index} wrap={true}>
                  <Text style={styles.greentext}>Antes</Text>
                  {imgData && (
                    <Image
                      style={{ ...styles.graph, height: "200px" }}
                      src={imgData}
                    />
                  )}
                </View>
              ) : (
                <View
                  style={{ ...styles.graphBox, width: "33%" }}
                  key={index}
                  wrap={true}
                >
                  {imgData && (
                    <Image
                      style={{ ...styles.graph, height: "200px" }}
                      src={imgData}
                    />
                  )}
                </View>
              )
            )}
          </View>
        )}

        {chartImages["ahoraMitigationCharts"]?.length > 0 && (
          <View style={styles.boxRowWrap} wrap={false}>
            {chartImages["ahoraMitigationCharts"].map((imgData, index) =>
              index === 0 ? (
                <View style={styles.graphBox} key={index} wrap={false}>
                  <Text style={styles.greentext}>Ahora</Text>
                  {imgData && (
                    <Image
                      style={{ ...styles.graph, height: "200px" }}
                      src={imgData}
                    />
                  )}
                </View>
              ) : (
                <View
                  style={{ ...styles.graphBox, width: "33%" }}
                  key={index}
                  wrap={false}
                >
                  {imgData && (
                    <Image
                      style={{ ...styles.graph, height: "200px" }}
                      src={imgData}
                    />
                  )}
                </View>
              )
            )}
          </View>
        )}

        {chartImages["fprMitigationCharts"]?.length > 0 &&
          chartImages["fprMitigationCharts"].map((imgData, index) => (
            <View style={styles.graphBox} key={index} wrap={false}>
              <Text style={styles.greentext}></Text>
              {imgData && <Image style={styles.graph} src={imgData} />}
            </View>
          ))}

        {chartImages["tprMitigationCharts"]?.length > 0 &&
          chartImages["tprMitigationCharts"].map((imgData, index) => (
            <View style={styles.graphBox} key={index} wrap={false}>
              <Text style={styles.greentext}></Text>
              {imgData && <Image style={styles.graph} src={imgData} />}
            </View>
          ))}

        {chartImages["eoMitigationCharts"]?.length > 0 && (
          <View style={{ ...styles.boxRow }} wrap={false}>
            {chartImages["eoMitigationCharts"].map((imgData, index) => (
              <View style={styles.graphBox} key={index}>
                <Text style={styles.greentext}></Text>
                {imgData && <Image style={styles.graph} src={imgData} />}
              </View>
            ))}
          </View>
        )}

        {chartImages["generalMetricsMitigationCharts"]?.length > 0 && (
          <View style={styles.graphBox}>
            <Text style={styles.greentext}>Métricas generales</Text>
            {chunkArray(chartImages["generalMetricsMitigationCharts"], 3).map(
              (chunk, chunkIndex) => (
                <View
                  style={{ ...styles.boxRow, height: "260px" }}
                  wrap={false}
                  key={chunkIndex}
                >
                  {chunk.map((imgData, index) => (
                    <View style={styles.graphBoxHalfWidth} key={index}>
                      {imgData && <Image style={styles.graph} src={imgData} />}
                    </View>
                  ))}
                </View>
              )
            )}
          </View>
        )}

        {chartImages["distributionMitigationCharts"]?.length > 0 &&
          chartImages["distributionMitigationCharts"].map((imgData, index) => (
            <View style={{ ...styles.graphBox, height: "auto" }} wrap={false}>
              {index === 0 && (
                <Text style={styles.greentext}>
                  Análisis por Grupo Poblacional
                </Text>
              )}
              <View
                style={{
                  ...styles.graphBox,
                  height: "auto",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
                wrap={false}
                key={index}
              >
                <View style={{ width: "45%", marginRight: "10px" }}>
                  {imgData && <Image style={styles.graph} src={imgData} />}
                </View>
                <View
                  style={{
                    width: "50%",
                    padding: "10px",
                    textAlign: "justify",
                    whiteSpace: "normal",
                  }}
                >
                  {distributionTexts[index] && (
                    <Text style={styles.greentext}>
                      {distributionTexts[index]?.general}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          ))}

        {chartImages["poblationMitigationCharts"]?.length > 0 &&
          (chartImages["poblationMitigationCharts"].length ===
          distributionTexts.length * 2
            ? distributionTexts.map((text, index) => (
                <View
                  style={{ ...styles.graphBox, height: "auto" }}
                  key={`group-${index}`}
                  wrap={false}
                >
                  {index === 0 && (
                    <Text style={styles.greentext}>
                      Análisis por Grupo Poblacional
                    </Text>
                  )}
                  <View
                    style={{
                      ...styles.graphBox,
                      height: "auto",
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: "60%",
                        marginRight: "10px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Image
                        style={styles.graph}
                        src={
                          chartImages["poblationMitigationCharts"][index * 2]
                        }
                      />
                      <Image
                        style={styles.graph}
                        src={
                          chartImages["poblationMitigationCharts"][
                            index * 2 + 1
                          ]
                        }
                      />
                    </View>
                    <View
                      style={{
                        width: "40%",
                        padding: "10px",
                        textAlign: "justify",
                        whiteSpace: "normal",
                      }}
                    >
                      <Text style={styles.greentext}>{text}</Text>
                    </View>
                  </View>
                </View>
              ))
            : chartImages["poblationMitigationCharts"].map((imgData, index) => (
                <View
                  style={{ ...styles.graphBox, height: "auto" }}
                  key={`item-${index}`}
                  wrap={false}
                >
                  {index === 0 && (
                    <Text style={styles.greentext}>
                      Análisis por Grupo Poblacional
                    </Text>
                  )}
                  <View
                    style={{
                      ...styles.graphBox,
                      height: "auto",
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ width: "45%", marginRight: "10px" }}>
                      <Image style={styles.graph} src={imgData} />
                    </View>
                    <View
                      style={{
                        width: "50%",
                        padding: "10px",
                        textAlign: "justify",
                        whiteSpace: "normal",
                      }}
                    >
                      {distributionTexts[index] && (
                        <Text style={styles.greentext}>
                          {distributionTexts[index]}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
              )))}

        <View wrap={false}>
          <Text style={styles.title}>Recomendaciones y conclusiones</Text>
          <Text style={styles.text}>{texts && texts.conclusion}</Text>
        </View>

        {methodologicalDetails.length > 0 && (
          <View>
            {methodologicalDetails.map((detail, index) => (
              <View
                key={index}
                style={styles.methodologicalDetails}
                wrap={false}
              >
                {index === 0 && (
                  <Text style={styles.title}>Detalles metodológicos</Text>
                )}
                <Text style={styles.subtitle}>{detail.key}</Text>
                <Text style={styles.text}>{detail.ver_mas}</Text>
              </View>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );
};

const MitigationReport = ({
  loading,
  distributionVariables,
  poblationVariable,
}) => {
  const { post } = useApiClient();
  const { eCharts } = useContext(EchartsContext);
  const { actualData } = useContext(DataContext);
  const { userName } = useAuth();
  const [texts, setTexts] = useState(null);
  const [distributionTexts, setDistributionTexts] = useState([]);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  const getTexts = async (actualData) => {
    if (
      !loading &&
      actualData.fairness_definition &&
      actualData.metrics_dataframe &&
      actualData.mitigated_metrics_dataframe &&
      actualData.mitigate_variable
    ) {
      const body = {
        protected_variable: actualData.mitigate_variable,
        df_metrics_before: actualData.metrics_dataframe,
        df_metrics_after: actualData.mitigated_metrics_dataframe,
        fairness_definition: actualData.fairness_definition,
        id_model: actualData.id_model,
      };
      try {
        const response = await post("generate_mitigation_report/", body);
        setTexts(response.data);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(`Error: ${error.response.data.msg}`);
        } else {
          toast.error(
            "Error: Ocurrió un error al cargar la información de las graficas."
          );
        }
      }
    }
  };

  useEffect(() => {
    if (
      actualData &&
      actualData.fairness_definition &&
      actualData.metrics_dataframe &&
      actualData.mitigated_metrics_dataframe &&
      actualData.mitigate_variable
    ) {
      getTexts(actualData);
    }
  }, [actualData]);

  useEffect(() => {
    if (distributionVariables) {
      const textsArray = Object.entries(distributionVariables).map(
        ([key, item]) => ({
          key,
          general: item.text.general,
          ver_mas: item.text.ver_mas,
        })
      );
      setDistributionTexts(textsArray);
    }
    if (poblationVariable) {
      if (poblationVariable.Lineas) {
        const textsArray = Object.values(poblationVariable.Lineas).map(
          (item) => item.texto
        );
        setDistributionTexts(textsArray);
      } else {
        const textsArray = Object.values(poblationVariable).map(
          (item) => item.texto
        );
        setDistributionTexts(textsArray);
      }
    }
  }, [distributionVariables, poblationVariable]);

  const handlePdfStatus = (loading) => {
    setPdfGenerating(loading);
  };

  return (
    <StyledButton variant="contained" disabled={loading || pdfGenerating}>
      <Typography variant="body1">
        <CustomPDFDownloadLink
          document={
            <MyDocument
              chartImages={eCharts}
              texts={texts}
              distributionTexts={distributionTexts}
              userName={userName}
            />
          }
          fileName={"Mitigacion_de_sesgos_" + actualData.name + ".pdf"}
        >
          {({ blob, url, loading: pdfLoading, error }) => {
            handlePdfStatus(pdfLoading);
            return pdfLoading || loading
              ? "Generando documento..."
              : "Descargar reporte de mitigación de sesgos";
          }}
        </CustomPDFDownloadLink>
      </Typography>
    </StyledButton>
  );
};

export default MitigationReport;
