import React, { useContext, useEffect, useState } from "react";
import { DialogTitle, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import StyledModal from "../../common/modal/StyledModal";
import { toast } from "react-toastify";
import FlexRow from "../container/FlexRow";
import StyledButton from "../../common/button/StyledButton";
import FlexColumn from "../container/FlexColumn";
import { DataContext } from "../../../context/DataContext";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  background: "#FFFFFF",
  color: purple ? "#4D033F" : "#02403C",
  "& .MuiOutlinedInput-root": {
    color: purple ? "#4D033F" : "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: purple ? "#4D033F" : "#02403C", // Aquí se especifica el color del label
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: purple ? "#4D033F" : "#02403C", // Aquí se especifica el color del label cuando está enfocado
  },
}));

const EditNameModal = ({
  open,
  onClose,
  name,
  namesList,
  setName,
  handleSaveName,
}) => {
  const handleSave = () => {
    if (name === "") {
      toast.warning("Por favor ingresa un nombre válido para el modelo.");
      return;
    }

    const nameExists = namesList.some((item) => item.name === name);

    if (nameExists) {
      toast.warning(
        "El nombre del modelo ya existe. Por favor, elige otro nombre."
      );
    } else {
      onClose();
      handleSaveName();
    }
  };

  return (
    <StyledModal open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography variant="h6">Editar Modelo</Typography>
      </DialogTitle>
      <FlexColumn gap="8px">
        <Typography variant="h4">
          Escriba el nuevo nombre del modelo.
        </Typography>
        <CustomTextField
          autoFocus
          margin="dense"
          label="Nombre del modelo"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          inputProps={{ maxLength: 50 }}
        />
        <Typography variant="body1">Máximo 50 caracteres.</Typography>
      </FlexColumn>
      <FlexRow gap="28px" sx={{ marginTop: "20px" }}>
        <StyledButton
          variant="outlined"
          component="label"
          onClick={() => onClose()}
          secondary="true"
        >
          Cancelar
        </StyledButton>
        <StyledButton
          variant="contained"
          component="label"
          onClick={handleSave}
        >
          Guardar
        </StyledButton>
      </FlexRow>
    </StyledModal>
  );
};

export default EditNameModal;
