import React from "react";
import { DialogTitle, Typography } from "@mui/material";
import StyledModal from "../../common/modal/StyledModal";
import FlexRow from "../container/FlexRow";
import StyledButton from "../../common/button/StyledButton";
import FlexColumn from "../container/FlexColumn";

const DeleteModelModal = ({ open, onClose, handleSaveDelete }) => {
  const handleSave = () => {
    onClose();
    handleSaveDelete();
  };

  return (
    <StyledModal open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography variant="h6">Eliminar Modelo</Typography>
      </DialogTitle>
      <FlexColumn gap="8px">
        <Typography variant="h4">
          ¿Estás segur@ de que quieres eliminar este modelo?
        </Typography>
      </FlexColumn>
      <FlexRow gap="28px" sx={{ marginTop: "20px" }}>
        <StyledButton
          variant="outlined"
          component="label"
          onClick={() => onClose()}
          secondary="true"
        >
          Cancelar
        </StyledButton>
        <StyledButton
          variant="contained"
          component="label"
          onClick={handleSave}
        >
          Eliminar
        </StyledButton>
      </FlexRow>
    </StyledModal>
  );
};

export default DeleteModelModal;
