import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import {
  PageContainerDashboard,
  FlexColumn,
  BorderCard,
  FlexRow,
} from "../../components/shared/container/Index";
import "react-toastify/dist/ReactToastify.css";
import { useApiClient } from "../../api/apiService";
import { toast } from "react-toastify";
import StyledInputBoxPrediction from "../../components/common/input/StyledInputBoxPrediction";
import StyledButton from "../../components/common/button/StyledButton";
import StyledTable from "../../components/common/table/StyledTable";
import IconInfo from "../../components/icons/IconInfo";
import { DataContext } from "../../context/DataContext";

const Prediction = () => {
  const { actualData, addPredictionData } = useContext(DataContext);
  const { post } = useApiClient();
  const [uploadedData, setUploadedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [excelFile, setExcelFile] = useState(null);

  useEffect(() => {
    if (uploadedData) {
      generateExcel();
      addPredictionData();
    }
  }, [uploadedData]);

  const handleUploadComplete = (response) => {
    setUploadedData(response.dataframe);
  };

  const generateExcel = async () => {
    setLoading(true);
    const body = {
      dataframe: uploadedData,
    };
    try {
      const response = await post("dataframe_to_excel/", body);
      const { file, filename } = response;
      const blob = b64toBlob(
        file,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      const blobUrl = URL.createObjectURL(blob);
      setExcelFile({ url: blobUrl, filename });
      toast.success(response.msg);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al generar el archivo.");
      }
    } finally {
      setLoading(false);
    }
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return (
    <PageContainerDashboard>
      {actualData ? (
        <Grid
          container
          spacing={3}
          sx={{ p: "20px", alignContent: "flex-start" }}
        >
          <Grid item xs={12}>
            <FlexColumn gap="8px">
              <Typography variant="h5">Predicciones</Typography>
              <Typography variant="body1">
                Aquí podrás ejecutar el modelo mitigado sobre datos nuevos. Para
                ello, tus datos deben incluir las mismas columnas
                correspondientes a las variables protegidas, además de una
                columna llamada "prediccion", que representa la salida de tu
                modelo original. El sistema generará una nueva columna
                denominada "y_final_fair", que contendrá la predicción ajustada
                del modelo mitigado.
              </Typography>
              <StyledDivider />
              <FlexRow
                justifycontent="space-between"
                alignItems="center"
                sx={{ margin: "40px 0px" }}
              >
                <Typography variant="h6">{actualData.name}</Typography>
                <StyledButton
                  variant="contained"
                  disabled={!excelFile || loading}
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = excelFile.url;
                    link.setAttribute("download", excelFile.filename); // Archivo descargado con su nombre
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  }}
                >
                  <Typography sx={{ fontWeight: "500" }}>
                    Descargar predicciones
                  </Typography>
                </StyledButton>
              </FlexRow>
            </FlexColumn>
          </Grid>
          <Grid item xs={12}>
            <BorderCard padding="16px" sx={{ overflowX: "auto" }}>
              {!uploadedData ? (
                <FlexColumn gap="8px">
                  <Typography variant="h4">
                    Carga los nuevos datos con los que quieres ejecutar tu
                    modelo mitigado
                  </Typography>
                  <StyledInputBoxPrediction
                    onUploadComplete={handleUploadComplete}
                  />
                </FlexColumn>
              ) : (
                <FlexColumn gap="8px">
                  <Typography variant="h4">
                    Datos cargados con predicción
                  </Typography>
                  <StyledTable borderaxis="both">
                    <thead>
                      <tr>
                        {Object.keys(uploadedData).map((key, index) => (
                          <th
                            key={key}
                            style={{
                              backgroundColor:
                                index === Object.keys(uploadedData).length - 1
                                  ? "#EBFDEA"
                                  : "transparent",
                            }}
                          >
                            <FlexRow
                              gap="8px"
                              justifycontent="center"
                              alignItems="center"
                            >
                              {key} <IconInfo />
                            </FlexRow>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedData[Object.keys(uploadedData)[0]]
                        .slice(0, 20)
                        .map((_, index) => (
                          <tr key={index}>
                            {Object.keys(uploadedData).map((key, colIndex) => (
                              <td
                                key={`${key}-${index}`}
                                style={{
                                  backgroundColor:
                                    colIndex ===
                                    Object.keys(uploadedData).length - 1
                                      ? "#EBFDEA"
                                      : "transparent",
                                }}
                              >
                                {typeof uploadedData[key][index] === "number"
                                  ? Number.isInteger(uploadedData[key][index])
                                    ? uploadedData[key][index]
                                    : uploadedData[key][index].toFixed(2)
                                  : uploadedData[key][index]}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </StyledTable>
                </FlexColumn>
              )}
            </BorderCard>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          sx={{ p: "20px", alignContent: "flex-start" }}
        />
      )}
    </PageContainerDashboard>
  );
};

export default Prediction;
