import React from "react";
import { Box } from "@mui/material";
import StyledAppBarDashboard from "./StyledAppBarDashboard";
import StyledDrawer from "./StyledDrawer";

const StyledNavbar = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <StyledAppBarDashboard />
      <StyledDrawer variant="permanent" />
    </Box>
  );
};

export default StyledNavbar;
