import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import PageContainer from "../../components/shared/container/PageContainer";
import BorderCard from "../../components/shared/container/BorderCard";
import FlexColumn from "../../components/shared/container/FlexColumn";
import StyledButton from "../../components/common/button/StyledButton";
import MaxWidthContainer from "../../components/shared/container/MaxWidthContainer";
import { useNavigate } from "react-router-dom";
import StyledStepper from "../../components/common/stepper/StyledStepper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApiClient } from "../../api/apiService";
import { useAuth } from "../../context/AuthProvider";
import FlexRow from "../../components/shared/container/FlexRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  color: "#02403C",
  "& .MuiOutlinedInput-root": {
    color: "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#02403C",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#02403C",
  },
}));

const CustomSelect = styled(Select)(({ purple, ...props }) => ({
  color: "#02403C",
  borderRadius: "10px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
  "&:hover .MuiOutlinedInput-root": {
    borderRadius: "10px",
    borderColor: "#02403C !important",
    borderWidth: "1px !important",
  },
  "&.Mui-focused .MuiOutlinedInput-root": {
    borderRadius: "10px",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
  "& fieldset": {
    color: "#02403C",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
  "&:hover fieldset": {
    color: "#02403C",
    borderColor: "#02403C !important",
    borderWidth: "1px !important",
  },
  "&.Mui-focused fieldset": {
    color: "#02403C",
    borderColor: "#02403C",
    borderWidth: "1px",
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(
  ({ purple, ...props }) => ({
    color: "#02403C",
    "& .MuiFormControlLabel-label": {
      color: "#02403C",
    },
  })
);

const CustomCheckbox = styled(Checkbox)(({ purple, ...props }) => ({
  color: "#02403C",
  "&.Mui-checked": {
    color: "#02403C",
  },
}));

const Register = () => {
  const { post } = useApiClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [interests, setInterests] = useState([]);
  const { setUserId, setUserName } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [sector, setSector] = useState("");
  const [charge, setCharge] = useState("");
  const [company, setCompany] = useState("");
  const [selectedInterests, setSelectedInterests] = useState([]);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedInterests(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    loadInterests();
  }, []);

  const loadInterests = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "get_interests/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setInterests(data.data);
    } catch (error) {
      toast.error("Error: Ocurrió un error al cargar los intereses.");
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleNameChange = (event) => setName(event.target.value);
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handlePasswordConfirmChange = (event) => {
    const value = event.target.value;
    setPasswordConfirm(value);
    setPasswordsMatch(value === password);
  };
  const handleSectorChange = (event) => setSector(event.target.value);
  const handleChargeChange = (event) => setCharge(event.target.value);
  const handleCompanyChange = (event) => setCompany(event.target.value);
  const handleCheckboxChange = (event) =>
    setTermsAccepted(event.target.checked);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLogin();
  };

  const handleLogin = async () => {
    setLoading(true);
    const body = {
      data: {
        name: name,
        email: email,
        user_role: "user",
        user_sector: sector,
        user_position: charge,
        organization: company,
        interests: selectedInterests,
        creation_date: new Date().toISOString().split("T")[0],
        account_status: "Activo",
        password: password,
      },
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "register_user/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "token/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
          }
        );
        const data = await response.json();
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        getUserId();
        toast.success(`Tu usuario ha sido creado con éxito.`);
      } else {
        const data = await response.json();
        toast.error(data.msg);
      }
    } catch (error) {
      if (error.response && error.response.msg) {
        toast.error(`Error: ${error.response.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al iniciar sesión.");
      }
    }
    setLoading(false);
  };

  const getUserId = async () => {
    const body = {
      email: email,
    };
    try {
      const response = await post("get_user_by_email/", body);
      setUserId(response.id_user);
      setUserName(response.name);
      localStorage.setItem("user_id", response.id_user);
      localStorage.setItem("username", response.name);
      handleNavigation("/dashboard");
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al obtener el id del usuario.");
      }
    }
  };

  const canProceedToNextStep =
    name &&
    email &&
    password &&
    passwordConfirm &&
    termsAccepted &&
    passwordsMatch &&
    isEmailValid;

  return (
    <PageContainer>
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "72px", marginBottom: "72px" }}
          columns={{ xs: 4, sm: 12, xl: 12 }}
        >
          <Grid item xs={4} sm={6} xl={6}>
            <BorderCard shadow>
              <FlexColumn
                padding="28px"
                component="form"
                gap="28px"
                alignItems="flex-start"
                onSubmit={handleSubmit}
              >
                <FlexRow gap="8px">
                  <Button
                    sx={{
                      display: activeStep === 1 ? "block" : "none",
                      width: "24px",
                      height: "24px",
                      padding: "0px",
                    }}
                    onClick={() => setActiveStep(0)}
                  >
                    <ArrowBackIcon />
                  </Button>
                  <StyledStepper
                    variant="dots"
                    steps={2}
                    activeStep={activeStep}
                    position="static"
                    justifycontent="flex-start"
                  />
                </FlexRow>

                {activeStep === 0 && (
                  <FlexColumn gap="28px" alignItems="flex-start" width="100%">
                    {" "}
                    <Typography variant="h5" color={"#02403C"}>
                      Registrate e inicia tu prueba gratis
                    </Typography>
                    <CustomTextField
                      label="Nombre completo"
                      variant="outlined"
                      fullWidth
                      onChange={handleNameChange}
                      value={name}
                    />
                    <CustomTextField
                      label="Correo"
                      variant="outlined"
                      fullWidth
                      type="email"
                      onChange={handleEmailChange}
                      value={email}
                      error={!isEmailValid}
                      helperText={
                        !isEmailValid && "Correo electrónico inválido"
                      }
                    />
                    <CustomTextField
                      label="Contraseña"
                      variant="outlined"
                      fullWidth
                      type="password"
                      onChange={handlePasswordChange}
                      value={password}
                    />
                    <CustomTextField
                      label="Confirmar contraseña"
                      variant="outlined"
                      fullWidth
                      type="password"
                      error={!passwordsMatch}
                      helperText={
                        !passwordsMatch && "Las contraseñas no coinciden"
                      }
                      onChange={handlePasswordConfirmChange}
                      value={passwordConfirm}
                    />
                    <CustomFormControlLabel
                      control={
                        <CustomCheckbox
                          checked={termsAccepted}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Aceptar términos y condiciones"
                    />
                    <StyledButton
                      variant="contained"
                      onClick={() => canProceedToNextStep && setActiveStep(1)}
                      disabled={!canProceedToNextStep}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: "100%",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#EBFDEA",
                        }}
                        color={"#02403C"}
                      >
                        Continuar
                      </Typography>
                    </StyledButton>
                  </FlexColumn>
                )}
                {activeStep === 1 && (
                  <FlexColumn gap="28px" alignItems="flex-start" width="100%">
                    {" "}
                    <Typography variant="h5" color={"#02403C"}>
                      Queremos conocerte un poco más
                    </Typography>
                    <CustomTextField
                      label="Sector"
                      variant="outlined"
                      fullWidth
                      onChange={handleSectorChange}
                      value={sector}
                    />
                    <CustomTextField
                      label="Cargo"
                      variant="outlined"
                      fullWidth
                      onChange={handleChargeChange}
                      value={charge}
                    />
                    <CustomTextField
                      label="Organización"
                      variant="outlined"
                      fullWidth
                      onChange={handleCompanyChange}
                      value={company}
                    />
                    <CustomSelect
                      variant="outlined"
                      displayEmpty
                      multiple
                      fullWidth
                      value={selectedInterests}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Typography variant="body1">Intereses</Typography>
                          );
                        }
                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>
                          <Typography variant="body1">Intereses</Typography>
                        </em>
                      </MenuItem>
                      {interests.map((interest) => (
                        <MenuItem key={interest} value={interest}>
                          <Checkbox
                            checked={selectedInterests.includes(interest)}
                            style={{ marginRight: 8 }}
                          />
                          <Typography variant="body1">{interest}</Typography>
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <StyledButton
                      variant="contained"
                      type="submit"
                      disabled={
                        sector === "" ||
                        charge === "" ||
                        company === "" ||
                        selectedInterests.length === 0 ||
                        loading
                      }
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          lineHeight: "100%",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#EBFDEA",
                        }}
                        color={"#02403C"}
                      >
                        Regístrate
                      </Typography>
                    </StyledButton>
                  </FlexColumn>
                )}
                <Typography variant="body1">
                  Ya tienes cuenta,{" "}
                  <b
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/login")}
                  >
                    {" "}
                    ingresa aquí
                  </b>
                </Typography>
              </FlexColumn>
            </BorderCard>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default Register;
