import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconBalance = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="84"
        height="84"
        viewBox="0 0 84 84"
        fill="none"
      >
        <circle cx="42.2501" cy="42.2638" r="41.3356" fill="#02403C" />
        <path
          d="M36.3641 49.6214H36.3623C36.3623 48.1333 36.4855 48.8185 28.5402 32.9279C26.917 29.6823 22.2688 29.6759 20.6428 32.9279C12.6304 48.9546 12.8217 48.1802 12.8217 49.6214H12.8198C12.8198 53.6846 18.0906 56.979 24.592 56.979C31.0933 56.979 36.3641 53.6846 36.3641 49.6214ZM24.592 34.9062L31.2138 48.1499H17.9701L24.592 34.9062ZM71.6787 49.6214C71.6787 48.1333 71.8019 48.8185 63.8566 32.9279C62.2334 29.6823 57.5852 29.6759 55.9592 32.9279C47.9468 48.9546 48.1381 48.1802 48.1381 49.6214H48.1362C48.1362 53.6846 53.407 56.979 59.9084 56.979C66.4097 56.979 71.6805 53.6846 71.6805 49.6214H71.6787ZM53.2866 48.1499L59.9084 34.9062L66.5302 48.1499H53.2866ZM61.3799 59.922H45.1932V32.8139C47.3554 31.8675 48.9787 29.9187 49.4597 27.5486H61.3799C62.1929 27.5486 62.8514 26.8901 62.8514 26.0771V23.1341C62.8514 22.3211 62.1929 21.6625 61.3799 21.6625H48.1031C46.7604 19.8857 44.6497 18.7195 42.2502 18.7195C39.8507 18.7195 37.74 19.8857 36.3972 21.6625H23.1204C22.3074 21.6625 21.6489 22.3211 21.6489 23.1341V26.0771C21.6489 26.8901 22.3074 27.5486 23.1204 27.5486H35.0407C35.5217 29.9178 37.144 31.8675 39.3071 32.8139V59.922H23.1204C22.3074 59.922 21.6489 60.5805 21.6489 61.3935V64.3366C21.6489 65.1496 22.3074 65.8081 23.1204 65.8081H61.3799C62.1929 65.8081 62.8514 65.1496 62.8514 64.3366V61.3935C62.8514 60.5805 62.1929 59.922 61.3799 59.922Z"
          fill="#EBFDEA"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconBalance;
