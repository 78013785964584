import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { EchartsContext } from "../../context/EchartsContext";
import { useApiClient } from "../../api/apiService";
import { toast } from "react-toastify";
import ReactECharts from "echarts-for-react";
import {
  PageContainerDashboard,
  BorderCard,
  FlexColumn,
  FlexRow,
} from "../../components/shared/container/Index";
import { Box, Grid, Typography, Slider } from "@mui/material";
import StyledDivider from "../../components/common/divider/StyledDivider";
import StyledButton from "../../components/common/button/StyledButton";
import StyledSkeletonRectangular from "../../components/common/skeleton/StyledSkeletonRectangular";
import PerformanceReport from "../../components/shared/reports/PerformancePDFReport";
import StyledTooltip from "../../components/common/tooltip/StyledTooltip";

const Performance = () => {
  const { post } = useApiClient();
  const originalConsoleWarn = console.warn;
  console.warn = function (message) {
    if (!message.includes("Can't get DOM width or height")) {
      originalConsoleWarn.apply(console, arguments);
    }
  };

  const navigate = useNavigate();
  const { actualData, addPerformanceData } = useContext(DataContext);
  const { addChart } = useContext(EchartsContext);
  const [loading, setLoading] = useState(true);
  const [completeValues, setCompleteValues] = useState(null);
  const [variables, setVariables] = useState([]);
  const [initialMetrics, setInitialMetrics] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [opUmbral, setOpUmbral] = useState(0);
  const [umbral, setUmbral] = useState(0);
  const [textUmbral, setTextUmbral] = useState(0);

  const chartRef = useRef(null);
  const variableChartRefs = useRef([]);
  const hasExportedChart = useRef(false);
  const hasExportedVariableCharts = useRef(false);

  const [pie, setPie] = useState(null);
  const [bar, setBar] = useState(null);
  const [barVariables, setBarVariables] = useState([]);
  const chartRefPie = useRef(null);
  const chartRefBar = useRef(null);
  const barVariableChartRefs = useRef([]);
  const hasExportedChartPie = useRef(false);
  const hasExportedChartBar = useRef(false);
  const hasExportedBarvariableCharts = useRef(false);

  useEffect(() => {
    if (actualData && actualData.data_view) {
      getGraphics(actualData);
      setPie(actualData.data_view.etiqueta_real);
      setBar(actualData.data_view.prediccion);
      setBarVariables(
        Object.keys(actualData.data_view)
          .filter((key) => key !== "prediccion" && key !== "etiqueta_real")
          .map((key) => ({ [key]: actualData.data_view[key] }))
      );
    }
    hasExportedChart.current = false;
    hasExportedVariableCharts.current = false;
    hasExportedChartPie.current = false;
    hasExportedChartBar.current = false;
    hasExportedBarvariableCharts.current = false;
  }, [actualData]);

  const pieOption = pie
    ? {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "right",
        },
        series: [
          {
            name: "Etiqueta real",
            type: pie.tipo,
            radius: "80%",
            data: pie.data.map((item, index) => ({
              ...item,
              itemStyle: {
                color: ["#9FC7D0", "#D9E9EC"][index % 2],
              },
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      }
    : {};

  const barOption = bar
    ? {
        tooltip: {},
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Predicción",
            type: "bar",
            barWidth: "100%",
            data: bar.data,
            itemStyle: {
              color: "#A6819F",
            },
          },
          {
            name: "Línea suavizada",
            type: "line",
            smooth: true,
            data: bar.data,
            itemStyle: {
              color: "#9FC7D0",
            },
          },
        ],
      }
    : {};

  const generateBarOption = (variableName, variableData) => ({
    title: {
      text: `${variableName}`,
      left: "center",
    },
    tooltip: {},
    xAxis: [
      {
        type: "category",
        data:
          variableData.data.x_axis_data ||
          variableData.data.map((item) => item[0]),
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: variableData.tipo === "histograma" ? "Histograma" : "Barras",
        type: "bar",
        barWidth: "95%",
        data:
          variableData.data.series_data ||
          variableData.data.map((item) => item[1]),
        itemStyle: {
          color: "#A6819F",
        },
      },
    ],
  });

  const exportPieChartAsImage = useCallback(() => {
    if (!hasExportedChartPie.current) {
      const echartInstance = chartRefPie.current.getEchartsInstance();
      const originalOptions = echartInstance.getOption();

      const optionsWithoutAnimation = {
        ...originalOptions,
        series: originalOptions.series.map((series) => ({
          ...series,
          animation: false,
        })),
      };
      echartInstance.setOption(optionsWithoutAnimation);

      const imgData = echartInstance.getDataURL({
        type: "png",
        pixelRatio: 4,
        backgroundColor: "#FFF",
      });

      echartInstance.setOption(originalOptions);

      addChart("pieChart", imgData);
      hasExportedChartPie.current = true;
    }
  }, [addChart]);

  const exportBarChartAsImage = useCallback(() => {
    if (!hasExportedChartBar.current) {
      const echartInstance = chartRefBar.current.getEchartsInstance();
      const originalOptions = echartInstance.getOption();

      const optionsWithoutAnimation = {
        ...originalOptions,
        series: originalOptions.series.map((series) => ({
          ...series,
          animation: false,
        })),
      };
      echartInstance.setOption(optionsWithoutAnimation);

      const imgData = echartInstance.getDataURL({
        type: "png",
        pixelRatio: 4,
        backgroundColor: "#FFF",
      });

      echartInstance.setOption(originalOptions);

      addChart("barChart", imgData);
      hasExportedChartBar.current = true;
    }
  }, [addChart]);

  const exportBarVariableChartsAsImages = useCallback(() => {
    if (!hasExportedBarvariableCharts.current) {
      const images = barVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("barVariableCharts", images);
      hasExportedBarvariableCharts.current = true;
    }
  }, [addChart]);

  const exportChartAsImage = useCallback(() => {
    if (!hasExportedChart.current) {
      const echartInstance = chartRef.current.getEchartsInstance();
      const originalOptions = echartInstance.getOption();

      const optionsWithoutAnimation = {
        ...originalOptions,
        series: originalOptions.series.map((series) => ({
          ...series,
          animation: false,
        })),
      };
      echartInstance.setOption(optionsWithoutAnimation);

      const imgData = echartInstance.getDataURL({
        type: "png",
        pixelRatio: 4,
        backgroundColor: "#FFF",
      });

      echartInstance.setOption(originalOptions);

      addChart("completeDataChart", imgData);
      hasExportedChart.current = true;
    }
  }, [addChart]);

  const exportVariableChartsAsImages = useCallback(() => {
    if (!hasExportedVariableCharts.current) {
      const images = variableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("variableCharts", images);
      hasExportedVariableCharts.current = true;
    }
  }, [addChart]);

  useEffect(() => {
    if (!loading && completeValues) {
      const updatedMetrics = completeValues[umbral.toFixed(2)];
      if (updatedMetrics) {
        setMetrics(updatedMetrics);
      }
      exportChartAsImage();
      exportVariableChartsAsImages();

      exportPieChartAsImage();
      exportBarChartAsImage();
      exportBarVariableChartsAsImages();
    }
  }, [
    loading,
    completeValues,
    umbral,
    exportChartAsImage,
    exportVariableChartsAsImages,
    exportPieChartAsImage,
    exportBarChartAsImage,
    exportBarVariableChartsAsImages,
  ]);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const getGraphics = async (actualData) => {
    setLoading(true);
    const body = { dataframe: actualData.original_dataframe };
    try {
      const response = await post(
        "generate_technical_performance_charts/",
        body
      );
      setTextUmbral(response.optimal_threshold);
      if (!actualData.optimal_threshold) {
        setUmbral(response.optimal_threshold);
        setOpUmbral(response.optimal_threshold);
        addPerformanceData(response.optimal_threshold);
      } else {
        setUmbral(actualData.optimal_threshold);
        setOpUmbral(actualData.optimal_threshold);
      }

      setCompleteValues(response.data.valores_completos);
      setVariables(
        Object.keys(response.data)
          .filter(
            (key) =>
              key !== "valores_completos" &&
              key !== "lineas_metricas" &&
              key !== "cajitas_metricas"
          )
          .map((key) => response.data[key])
      );
      setInitialMetrics(response.data.cajitas_metricas);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error(
          "Error: Ocurrió un error al cargar la información de las graficas."
        );
      }
    }
  };

  const getCompleteDataChartOptions = (completeValues) => {
    const categories = Object.keys(completeValues);
    const seriesNames = Object.keys(completeValues[categories[0]]);

    const seriesData = seriesNames.map((key, index) => {
      const colors = ["#FCC465", "#A6819F", "#809F9E", "#9FC7D0"];
      return {
        name: key,
        type: "line",
        showSymbol: false,
        data: categories.map((category) => [
          category,
          completeValues[category][key],
        ]),
        itemStyle: {
          color: colors[index % colors.length],
        },
      };
    });

    return {
      title: {
        text: "Métricas binarias por Umbral",
        left: "center",
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          const umbral = params[0].axisValue;
          let tooltipText = `Umbral: ${umbral}<br/>`;
          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${param.value[1]}<br/>`;
          });
          return tooltipText;
        },
      },
      legend: {
        data: seriesNames,
        top: "bottom",
      },
      grid: {
        right: 80,
      },
      xAxis: {
        name: "Umbral",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
      },
      yAxis: {
        name: "Métrica",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0], // Ajusta el padding si es necesario
        },
        type: "value",
      },
      series: seriesData,
    };
  };

  const getVariableChartOptions = (variable, index) => {
    const seriesData = variable.data.y_s.map((series, i) => {
      const lineStyle = i === 0 ? { type: "dotted", color: "black" } : {};
      const areaStyle = index !== 0 && i !== 0 ? { areaStyle: {} } : {};
      const tooltip = i === 0 ? { show: false } : {};
      const color = "#9FC7D0";

      const dataPairs = variable.data.xAxis[i].data.map((xValue, j) => [
        xValue,
        series.data[j],
      ]);

      return {
        name: series.name,
        type: "line",
        showSymbol: false,
        lineStyle,
        tooltip,
        ...areaStyle,
        itemStyle: {
          color: color,
        },
        data: dataPairs,
      };
    });

    return {
      title: {
        text: variable.data.title,
        left: "center",
      },
      grid: {
        left: 50,
      },
      xAxis: {
        name:
          variable.data.title.split("\n")[0] === "Calibración"
            ? "Predicción"
            : variable.data.title.split("\n")[0] === "ROC"
            ? "FPR"
            : "Recall",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        name:
          variable.data.title.split("\n")[0] === "Calibración"
            ? "Proporción de positivos"
            : variable.data.title.split("\n")[0] === "ROC"
            ? "TPR"
            : "Precisión",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0], // Ajusta el padding si es necesario
        },
        type: "value",
      },
      series: seriesData,
    };
  };

  return (
    <PageContainerDashboard>
      {actualData ? (
        <Grid
          container
          spacing={2}
          sx={{ p: "20px", alignContent: "flex-start" }}
        >
          <Grid item xs={12}>
            <FlexRow
              justifycontent="space-between"
              alignItems="center"
              gap="48px"
            >
              <FlexColumn>
                <Typography variant="h5">
                  Evaluación del rendimiento técnico
                </Typography>
                <Typography variant="body1">
                  Aquí encuentras un análisis de las métricas más importantes
                  sobre el desempeño de tu modelo.
                </Typography>
              </FlexColumn>
              <FlexColumn alignItems="center" gap="8px">
                <StyledButton
                  sx={{ width: "max-content", minWidth: "100%" }}
                  secondary="true"
                >
                  <Typography
                    variant="body2"
                    onClick={() =>
                      handleNavigation(
                        `/dashboard/${actualData.id_model}/data-view`
                      )
                    }
                  >
                    ← Volver a Visualización
                  </Typography>
                </StyledButton>
                <StyledButton
                  sx={{ width: "max-content", minWidth: "100%" }}
                  secondary="true"
                >
                  <Typography
                    variant="body2"
                    onClick={() =>
                      handleNavigation(
                        `/dashboard/${actualData.id_model}/detection`
                      )
                    }
                  >
                    Ver detección de sesgos →
                  </Typography>
                </StyledButton>
              </FlexColumn>
            </FlexRow>
            <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
            <FlexRow justifycontent="space-between" alignItems="center">
              <Typography variant="h6">{actualData.name}</Typography>
              <PerformanceReport
                metrics={initialMetrics.data}
                loading={loading}
              />
            </FlexRow>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              columns={{
                xs: 4,
                sm: 4,
                md: 12,
                xl: 12,
                alignContent: "flex-start",
              }}
            >
              <Grid item xs={4} sm={4} md={6} xl={6}>
                <BorderCard padding="16px">
                  <FlexColumn gap="8px" alignItems="center">
                    {loading ? (
                      <StyledSkeletonRectangular />
                    ) : (
                      <ReactECharts
                        ref={chartRef}
                        option={getCompleteDataChartOptions(completeValues)}
                        style={{
                          height: "400px",
                          width: "460px",
                          maxWidth: "440px",
                        }}
                      />
                    )}
                  </FlexColumn>
                </BorderCard>
              </Grid>
              <Grid item xs={4} sm={4} md={6} xl={6}>
                <BorderCard padding="16px">
                  <FlexColumn gap="24px" height="100%">
                    <Typography align="center" variant="body2">
                      Con base en las métricas, el mejor umbral sugerido para el
                      modelo es {textUmbral}.
                      {
                        <StyledTooltip
                          tooltipText={
                            "Este umbral corresponde al punto de la curva ROC que está más cerca de la esquina superior izquierda del gráfico. Esta ubicación representa el balance óptimo entre TPR y FPR, ya que minimiza tanto los falsos positivos como los falsos negativos."
                          }
                          onClick={() => navigate("/dashboard/glossary")}
                        />
                      }
                      {textUmbral === opUmbral
                        ? "Si prefieres, puedes ajustar el umbral a continuación."
                        : "El umbral que tienes seleccionado acutalmente es:"}
                    </Typography>
                    {loading ? (
                      <StyledSkeletonRectangular />
                    ) : (
                      <FlexColumn gap="72px" height="100%" alignItems="center">
                        <FlexRow gap="24px" height="40px" alignItems="center">
                          <Typography align="center" variant="h6" width="140px">
                            Umbral = {umbral}
                          </Typography>
                          <StyledButton
                            variant="contained"
                            sx={{
                              display: umbral === opUmbral ? "none" : "block",
                            }}
                            onClick={() => {
                              setOpUmbral(umbral);
                              addPerformanceData(umbral);
                            }}
                          >
                            Guardar nuevo umbral
                          </StyledButton>
                        </FlexRow>

                        <Box
                          sx={{
                            position: "relative",
                            width: "80%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            sx={{
                              position: "absolute",
                              left: "0px",
                              top: "20px",
                            }}
                          >
                            0
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            sx={{
                              position: "absolute",
                              right: "0px",
                              top: "20px",
                            }}
                          >
                            1
                          </Typography>
                          <Slider
                            value={umbral}
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={(e, newValue) => setUmbral(newValue)}
                            valueLabelDisplay="auto"
                            marks={[]}
                            sx={{
                              "& .MuiSlider-rail": {
                                color: "#02403C",
                                height: "1px",
                              },
                              "& .MuiSlider-track": {
                                color: "#02403C",
                                height: "1px",
                              },
                              "& .MuiSlider-thumb": {
                                backgroundColor: "#02403C",
                              },
                            }}
                          />
                        </Box>
                        <Grid
                          container
                          spacing={1}
                          columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                        >
                          <Grid item xs={2} sm={2} md={3} xl={3}>
                            <FlexColumn
                              alignItems="center"
                              justifycontent="center"
                              padding="8px"
                              sx={{
                                background: "#FFFFFF",
                                border: "3px solid #809F9E",
                                borderRadius: "5px",
                              }}
                            >
                              <Typography align="center" variant="body1">
                                Accuracy <br />{" "}
                                {metrics
                                  ? metrics.Accuracy
                                  : initialMetrics.data.Accuracy}
                              </Typography>
                            </FlexColumn>
                          </Grid>
                          <Grid item xs={2} sm={2} md={3} xl={3}>
                            <FlexColumn
                              alignItems="center"
                              justifycontent="center"
                              padding="8px"
                              sx={{
                                background: "#FFFFFF",
                                border: "3px solid #A6819F",
                                borderRadius: "5px",
                              }}
                            >
                              <Typography align="center" variant="body1">
                                Recall <br />{" "}
                                {metrics
                                  ? metrics.Recall
                                  : initialMetrics.data.Recall}
                              </Typography>
                            </FlexColumn>
                          </Grid>
                          <Grid item xs={2} sm={2} md={3} xl={3}>
                            <FlexColumn
                              alignItems="center"
                              justifycontent="center"
                              padding="8px"
                              sx={{
                                background: "#FFFFFF",
                                border: "3px solid #FFCDA1",
                                borderRadius: "5px",
                              }}
                            >
                              <Typography align="center" variant="body1">
                                Precisión <br />{" "}
                                {metrics
                                  ? metrics.Precision
                                  : initialMetrics.data.Precision}
                              </Typography>
                            </FlexColumn>
                          </Grid>
                          <Grid item xs={2} sm={2} md={3} xl={3}>
                            <FlexColumn
                              alignItems="center"
                              justifycontent="center"
                              padding="8px"
                              sx={{
                                background: "#FFFFFF",
                                border: "3px solid #9FC7D0",
                                borderRadius: "5px",
                              }}
                            >
                              <Typography align="center" variant="body1">
                                F1 <br />{" "}
                                {metrics
                                  ? metrics.F1Score
                                  : initialMetrics.data.F1Score}
                              </Typography>
                            </FlexColumn>
                          </Grid>
                        </Grid>
                      </FlexColumn>
                    )}
                  </FlexColumn>
                </BorderCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BorderCard padding="16px">
              <FlexColumn gap="16px">
                <Typography variant="h4">
                  Curvas de rendimiento técnico
                </Typography>

                {loading ? (
                  <StyledSkeletonRectangular />
                ) : (
                  <Grid
                    container
                    spacing={2}
                    columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                  >
                    {variables.map((variable, index) => (
                      <Grid item xs={4} sm={4} md={6} xl={4} key={index}>
                        <FlexColumn alignItems="center">
                          <ReactECharts
                            ref={(el) =>
                              (variableChartRefs.current[index] = el)
                            }
                            option={getVariableChartOptions(variable, index)}
                            style={{
                              height: "400px",
                              width: "80%",
                              maxWidth: "440px",
                            }}
                            key={index}
                          />
                        </FlexColumn>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </FlexColumn>
            </BorderCard>
          </Grid>
          <FlexColumn sx={{ display: "none" }}>
            <FlexColumn alignItems="center">
              <ReactECharts
                ref={chartRefPie}
                option={pieOption}
                style={{ height: "200px", width: "400px", maxWidth: "440px" }}
              />
            </FlexColumn>
            <FlexColumn alignItems="center">
              <ReactECharts
                ref={chartRefBar}
                option={barOption}
                style={{ height: "400px", width: "400px", maxWidth: "440px" }}
              />
            </FlexColumn>
            {barVariables.map((variable, index) => {
              const variableName = Object.keys(variable)[0];
              const variableData = Object.values(variable)[0];
              return (
                <FlexRow key={index}>
                  <ReactECharts
                    ref={(el) => (barVariableChartRefs.current[index] = el)}
                    option={generateBarOption(variableName, variableData)}
                    style={{
                      height: "360px",
                      width: "400px",
                      maxWidth: "440px",
                    }}
                    key={index}
                  />
                </FlexRow>
              );
            })}
          </FlexColumn>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          sx={{ p: "20px", alignContent: "flex-start" }}
        />
      )}
    </PageContainerDashboard>
  );
};

export default Performance;
