import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import { DataContext } from "../../context/DataContext";
import {
  PageContainerDashboard,
  BorderCard,
  FlexColumn,
  FlexRow,
} from "../../components/shared/container/Index";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Modal,
  Box,
  IconButton,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StyledDivider from "../../components/common/divider/StyledDivider";
import StyledButton from "../../components/common/button/StyledButton";
import StyledStepper from "../../components/common/stepper/StyledStepper";
import DetectionReport from "../../components/shared/reports/DetectionPDFReport";
import StyledSkeletonRectangular from "../../components/common/skeleton/StyledSkeletonRectangular";
import TuplesSelector from "../../components/shared/tuples/TuplesSelector";
import { toast } from "react-toastify";
import { useApiClient } from "../../api/apiService";
import { EchartsContext } from "../../context/EchartsContext";
import DetectionColorBanner from "../../components/shared/section/DetectionColorBanner";
import StyledImage from "../../components/common/image/StyledImage";
import { GraphError } from "../../images/Index";

const metrics = [
  {
    title: "Demographic Parity",
    shortDescription:
      "La distribución de las predicciones asignadas por el modelo es igual para todos los grupos.",
    description:
      "La distribución de las predicciones asignadas por el modelo es igual para todos los grupos.",
    value: "dp",
  },
  {
    title: "Recall - True Positive Rate Parity",
    shortDescription:
      "La tasa de verdaderos positivos es igual entre todos los grupos de tus variables protegidas.",
    description:
      "La tasa de verdaderos positivos es igual entre todos los grupos de tus variables protegidas.",
    value: "tpr",
  },
  {
    title: "False Positive Rate Parity",
    shortDescription:
      "La tasa de falsos positivos es igual entre todos los grupos de tus variables protegidas.",
    description:
      "La tasa de falsos positivos es igual entre todos los grupos de tus variables protegidas.",
    value: "fpr",
  },
  {
    title: "Equalized Odds",
    shortDescription:
      "La probabilidad de hacer una predicción correcta (tanto verdaderos positivos como verdaderos negativos) es igual entre todos los grupos de tus variables protegidas.",
    description:
      "La probabilidad de hacer una predicción correcta (tanto verdaderos positivos como verdaderos negativos) es igual entre todos los grupos de tus variables protegidas.",
    value: "eo",
  },
  {
    title: "Calibration",
    shortDescription:
      "Las predicciones coinciden con la frecuencia real de resultados positivos dentro de cada grupo.",
    description:
      "Las predicciones coinciden con la frecuencia real de resultados positivos dentro de cada grupo.",
    value: "ca",
  },
];

const Detection = () => {
  const { post } = useApiClient();
  const navigate = useNavigate();
  const { actualData, addDetectionData, mitigationVars, setMitigationVars } =
    useContext(DataContext);
  const { addChart, deleteChart } = useContext(EchartsContext);

  const [open, setOpen] = useState(false);
  const [openMitigateModal, setOpenMitigateModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [prevStep, setPrevStep] = useState(0);
  const [detectionStep, setDetectionStep] = useState(0);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [actualMetric, setActualMetric] = useState("");

  const [showGraphics, setShowGraphics] = useState(false);
  const [loading, setLoading] = useState(true);

  const [softLineVariables, setSoftLineVariables] = useState([]);
  const [barVariables, setBarVariables] = useState([]);
  const [rocVariables, setRocVariables] = useState([]);
  const [tprBarVariables, setTprBarVariables] = useState([]);
  const [fprBarVariables, setFprBarVariables] = useState([]);
  const [texts, setTexts] = useState(null);
  const [calibrationLineVariables, setCalibrationLineVariables] = useState([]);
  const softLineVariableChartRefs = useRef([]);
  const hasExportedSoftLinevariableCharts = useRef(false);
  const barVariableChartRefs = useRef([]);
  const hasExportedBarvariableCharts = useRef(false);
  const tprBarVariableChartRefs = useRef([]);
  const hasExportedTprBarvariableCharts = useRef(false);
  const fprBarVariableChartRefs = useRef([]);
  const hasExportedFprBarvariableCharts = useRef(false);
  const rocVariableChartRefs = useRef([]);
  const hasExportedRocvariableCharts = useRef(false);
  const calibrationLineVariableChartRefs = useRef([]);
  const hasExportedCalibrationLinevariableCharts = useRef(false);

  const [tuples, setTuples] = useState([]);

  const addTuple = (newTuple) => {
    setTuples((prevTuples) => {
      const tupleExists = prevTuples.some(
        (tuple) =>
          tuple.length === newTuple.length &&
          tuple.every((val, index) => val === newTuple[index])
      );

      if (!tupleExists) {
        return [...prevTuples, newTuple];
      } else {
        toast.warning("Esta tupla ya ha sido agregada.");
        return prevTuples;
      }
    });
  };

  const removeTuple = (tupleToRemove) => {
    setTuples((prevTuples) =>
      prevTuples.filter(
        (tuple) =>
          !(
            tuple.length === tupleToRemove.length &&
            tuple.every((val, index) => val === tupleToRemove[index])
          )
      )
    );
  };

  const [tuplesSelectors, setTuplesSelectors] = useState([
    <TuplesSelector
      tuples={tuples}
      addTuple={addTuple}
      removeTuple={removeTuple}
      combination={1}
      key={0}
    />,
  ]);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setActiveStep(0);
    setSelectedMetric(null);
    setActualMetric("");
    setOpen(false);
  };
  const handlePrevStep = () => {
    if (prevStep >= 0) {
      setActiveStep(activeStep - prevStep);
    }
    setSelectedMetric(null);
    setActualMetric("");
  };

  const handleNext = (step, selected) => {
    if (step === 2) {
      setPrevStep(2);
    }
    if (step === 1) {
      setPrevStep(1);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + step);
    if (selected) {
      setSelectedMetric(selected);
      setActualMetric(selected.value);
    }
  };

  const handleNextDetection = (step) => {
    if (actualMetric !== "") {
      setDetectionStep((prevDetectionStep) => prevDetectionStep + step);
    }
  };

  const handlePrevDetection = (step) => {
    if (actualMetric !== "") {
      setDetectionStep((prevDetectionStep) => prevDetectionStep - step);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setSelectedMetric(null);
    setActualMetric("");
  };

  const handleAccept = () => {
    setActiveStep(0);
    setOpen(false);
  };

  const handleAddTuplesSelector = () => {
    setTuplesSelectors((prevSelectors) => [
      ...prevSelectors,
      <TuplesSelector
        tuples={tuples}
        addTuple={addTuple}
        removeTuple={removeTuple}
        key={prevSelectors.length}
        combination={prevSelectors.length + 1}
      />,
    ]);
  };

  const handleDetect = async () => {
    deleteChart("softLineDetectionCharts");
    deleteChart("calibrationLineDetectionCharts");
    deleteChart("barDetectionCharts");
    deleteChart("rocDetectionCharts");
    deleteChart("tprBarDetectionCharts");
    deleteChart("fprBarDetectionCharts");

    const body = {
      dataframe: actualData.original_dataframe,
      tuples: tuples,
      metric: actualMetric,
      id_model: actualData.id_model,
    };
    try {
      const response = await post("generate_bias_detection_charts/", body);
      const barrasMetricasKeys = new Set([
        ...mitigationVars,
        ...response.df_metrics.attribute_name,
      ]);
      setMitigationVars(Array.from(barrasMetricasKeys));

      setSoftLineVariables(response.data.densidad_metricas || []);
      setBarVariables(response.data.barras_metricas || []);
      setRocVariables(response.data.curvas_roc || []);
      setTprBarVariables(response.data.TPR || []);
      setFprBarVariables(response.data.FPR || []);
      setTexts(response.data.textos || null);
      setCalibrationLineVariables(response.data.curvas_calibracion || []);

      if (response.biased_variables.lista_vps.length === 0) {
        addDetectionData(
          response.dataframe,
          response.df_metrics,
          selectedMetric.value,
          tuples,
          { sugerida: "Sin-sesgos", lista_vps: ["Sin-sesgos"] }
        );
        setOpenMitigateModal(true);
      } else {
        addDetectionData(
          response.dataframe,
          response.df_metrics,
          selectedMetric.value,
          tuples,
          response.biased_variables
        );
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error(
          "Error: Ocurrió un error al cargar la información de las gráficas."
        );
      }
    }
  };

  const detect = () => {
    setShowGraphics(true);
    handleDetect();
  };

  const generateSoftLineOption = (variableData, key) => {
    const titleText = `${key}`;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          label: {
            backgroundColor: "#6a7985",
          },
        },
        formatter: function (params) {
          let tooltipText = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            tooltipText += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${item.color};"></span>`;
            tooltipText += `${item.seriesName}: ${item.data[1]}<br/>`;
          });
          return tooltipText;
        },
      },
      title: {
        text: titleText,
        left: "center",
      },
      legend: {
        type: "scroll",
        orient: "horizontal",
        left: "center",
        top: "bottom",
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 11,
        },
        data: Object.keys(variableData.data.data),
      },
      xAxis: {
        name: "Valor Predicho",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        type: "value",
      },
      series: Object.keys(variableData.data.data).map((childKey) => ({
        name: childKey,
        data: variableData.data.data[childKey].map((value, index) => [
          variableData.data.xAxis[childKey][index],
          value,
        ]),
        type: "line",
        smooth: true,
        symbol: "none",
      })),
    };
  };

  const generateBarOption = (variableData, key) => {
    const shouldRotate = variableData.data.data.length > 3;
    const hideLabels = variableData.data.data.length > 6; // Condición para ocultar etiquetas si hay más de 6 barras

    return {
      tooltip: {},
      title: {
        text: `${key}`,
        left: "center",
      },
      grid: {
        left: 45,
      },
      xAxis: {
        type: "category",
        data: variableData.data.xAxis,
        axisLabel: {
          show: !hideLabels, // Mostrar etiquetas solo si hay 6 o menos barras
          interval: 0,
          rotate: shouldRotate ? 30 : 0,
        },
      },
      yAxis: {
        name:
          actualData.fairness_definition === "fpr"
            ? "FPR"
            : actualData.fairness_definition === "tpr"
            ? "TPR"
            : "",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: [
        {
          data: variableData.data.data.map((value, index) => ({
            value,
            itemStyle: {
              color: variableData.data.color_data[index],
            },
          })),
          type: "bar",
        },
      ],
    };
  };

  const generateCalibrationLineOption = (variable, key) => {
    const seriesData = [];

    seriesData.push({
      name: "Diagonal",
      type: "line",
      showSymbol: false,
      lineStyle: {
        type: "dotted",
        color: "black",
      },
      itemStyle: {
        color: "black",
      },
      data: variable.diag,
      tooltip: {
        show: false,
      },
    });

    Object.keys(variable.label).forEach((lineKey) => {
      seriesData.push({
        name: `${lineKey} (${variable.label[lineKey]})`,
        type: "line",
        showSymbol: false,
        lineStyle: {},
        data: variable[lineKey],
      });
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      title: {
        text: `${key}`,
        left: "center",
      },
      legend: {
        type: "scroll",
        orient: "horizontal",
        left: "center",
        top: "bottom",
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 11,
        },
        data: seriesData
          .map((item) => item.name)
          .filter((name) => name !== "Diagonal"),
      },
      xAxis: {
        name: "Valor Predicho",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        name: "Proporción de positivos",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: seriesData,
    };
  };

  const generateRocOption = (variable, key) => {
    const series = Object.keys(variable).map((key) => {
      if (key === "diag") {
        return {
          name: key,
          type: "line",
          data: variable[key],
          showSymbol: false,
          lineStyle: {
            type: "dotted",
            color: "black",
          },
          itemStyle: {
            color: "black",
          },
        };
      } else {
        return {
          name: key,
          type: "line",
          data: variable[key],
          showSymbol: false,
          lineStyle: {
            type: "solid",
          },
        };
      }
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          label: {
            backgroundColor: "#6a7985",
          },
        },
        formatter: function (params) {
          let result = "";
          params.forEach((param) => {
            result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color};"></span>`;
            result += `${param.seriesName}: ${param.value[1]}<br/>`;
          });
          return result;
        },
      },
      title: {
        text: `Curva ROC \n ${key}`,
        left: "center",
      },
      legend: {
        type: "scroll",
        orient: "horizontal",
        left: "center",
        top: "bottom",
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 11,
        },
        data: Object.keys(variable).filter((name) => name !== "diag"),
      },
      grid: {
        top: 45,
        left: 50,
      },
      xAxis: {
        name: "FPR",
        nameLocation: "center",
        nameGap: 0,
        nameTextStyle: {
          align: "center",
          verticalAlign: "top",
          padding: [30, 0, 0, 0],
        },
        type: "value",
      },
      yAxis: {
        name: "TPR",
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          align: "center",
          padding: [0, 0, 0, 0],
        },
        type: "value",
      },
      series: series,
    };
  };

  const exportSoftLineVariableChartsAsImages = useCallback(() => {
    if (!hasExportedSoftLinevariableCharts.current) {
      const images = softLineVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("softLineDetectionCharts", images);
      hasExportedSoftLinevariableCharts.current = true;
    }
  }, [addChart]);

  const exportBarVariableChartsAsImages = useCallback(() => {
    if (!hasExportedBarvariableCharts.current) {
      const images = barVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("barDetectionCharts", images);
      hasExportedBarvariableCharts.current = true;
    }
  }, [addChart]);

  const exportTprBarVariableChartsAsImages = useCallback(() => {
    if (!hasExportedTprBarvariableCharts.current) {
      const images = tprBarVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("tprBarDetectionCharts", images);
      hasExportedTprBarvariableCharts.current = true;
    }
  }, [addChart]);

  const exportFprBarVariableChartsAsImages = useCallback(() => {
    if (!hasExportedFprBarvariableCharts.current) {
      const images = fprBarVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("fprBarDetectionCharts", images);
      hasExportedFprBarvariableCharts.current = true;
    }
  }, [addChart]);

  const exportRocVariableChartsAsImages = useCallback(() => {
    if (!hasExportedRocvariableCharts.current) {
      const images = rocVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("rocDetectionCharts", images);
      hasExportedRocvariableCharts.current = true;
    }
  }, [addChart]);

  const exportCalibrationLineVariableChartsAsImages = useCallback(() => {
    if (!hasExportedCalibrationLinevariableCharts.current) {
      const images = calibrationLineVariableChartRefs.current
        .map((chartRef, index) => {
          if (chartRef) {
            const echartInstance = chartRef.getEchartsInstance();
            const originalOptions = echartInstance.getOption();

            const optionsWithoutAnimation = {
              ...originalOptions,
              series: originalOptions.series.map((series) => ({
                ...series,
                animation: false,
              })),
            };
            echartInstance.setOption(optionsWithoutAnimation);

            const imgData = echartInstance.getDataURL({
              type: "png",
              pixelRatio: 4,
              backgroundColor: "#FFF",
            });

            echartInstance.setOption(originalOptions);

            return imgData;
          }
          return null;
        })
        .filter((imgData) => imgData !== null);

      addChart("calibrationLineDetectionCharts", images);
      hasExportedCalibrationLinevariableCharts.current = true;
    }
  }, [addChart]);

  useEffect(() => {
    if (
      !loading &&
      softLineVariables &&
      barVariables &&
      tprBarVariables &&
      fprBarVariables &&
      rocVariables &&
      calibrationLineVariables
    ) {
      exportSoftLineVariableChartsAsImages();
      exportBarVariableChartsAsImages();
      exportTprBarVariableChartsAsImages();
      exportFprBarVariableChartsAsImages();
      exportRocVariableChartsAsImages();
      exportCalibrationLineVariableChartsAsImages();
    }
  }, [
    loading,
    softLineVariables,
    barVariables,
    tprBarVariables,
    fprBarVariables,
    rocVariables,
    calibrationLineVariables,
    exportSoftLineVariableChartsAsImages,
    exportBarVariableChartsAsImages,
    exportTprBarVariableChartsAsImages,
    exportFprBarVariableChartsAsImages,
    exportRocVariableChartsAsImages,
    exportCalibrationLineVariableChartsAsImages,
  ]);

  return (
    <PageContainerDashboard>
      {actualData ? (
        !showGraphics ? (
          <Grid
            container
            spacing={2}
            sx={{ p: "20px", alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <FlexRow
                justifycontent="space-between"
                alignItems="center"
                gap="48px"
              >
                <FlexColumn>
                  <Typography variant="h5">Detección de sesgos</Typography>
                  <Typography variant="body1">
                    En esta sección se detectan los sesgos de tu modelo.
                  </Typography>
                </FlexColumn>
                <FlexColumn alignItems="center" gap="8px">
                  <StyledButton
                    sx={{ width: "max-content", minWidth: "100%" }}
                    secondary="true"
                  >
                    <Typography
                      variant="body2"
                      onClick={() =>
                        handleNavigation(
                          `/dashboard/${actualData.id_model}/performance`
                        )
                      }
                    >
                      ← Ver rendimiento técnico
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </FlexRow>
              <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
            </Grid>
            <Grid item xs={12}>
              <FlexRow justifycontent="space-between" alignItems="center">
                <Typography variant="h6">{actualData.name}</Typography>
              </FlexRow>
            </Grid>
            <Grid item spacing={2} xs={12}>
              <BorderCard padding="8px">
                <FlexColumn gap="16px" alignItems="flex-start">
                  {detectionStep === 0 && (
                    <FlexColumn gap="16px" alignItems="flex-start" width="100%">
                      <Typography variant="h4">
                        Seleccionar métrica justicia
                      </Typography>
                      <StyledStepper
                        variant="dots"
                        steps={2}
                        activeStep={detectionStep}
                        position="static"
                        justifycontent="center"
                      />
                      <FlexColumn>
                        <Typography variant="body1">
                          Selecciona la métrica que mejor representa tu
                          definición de justicia. Si no lo sabes,{" "}
                          <span
                            style={{ fontWeight: "600", cursor: "pointer" }}
                            onClick={handleOpen}
                          >
                            realiza este quiz.
                          </span>
                        </Typography>
                      </FlexColumn>
                      <StyledDivider />

                      <FlexColumn gap="16px" alignItems="flex-start">
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" column="true">
                            {metrics.map((metric, index) => (
                              <FormControlLabel
                                key={index}
                                value={metric.value}
                                control={
                                  <Radio
                                    checked={
                                      selectedMetric?.value === metric.value
                                    }
                                    onChange={() => {
                                      setSelectedMetric(metric);
                                      setActualMetric(metric.value);
                                    }}
                                  />
                                }
                                label={
                                  <FlexColumn>
                                    <Typography>{metric.title}</Typography>
                                    <Typography>
                                      {metric.shortDescription}
                                    </Typography>
                                  </FlexColumn>
                                }
                                labelPlacement="end"
                                sx={{ margin: "0px", marginBottom: "16px" }}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                        {Object.keys(actualData.data_view).length <= 3 ? (
                          <StyledButton
                            variant="contained"
                            disabled={actualMetric === ""}
                            onClick={() => detect()}
                          >
                            Detectar sesgos
                          </StyledButton>
                        ) : (
                          <StyledButton
                            variant="contained"
                            disabled={actualMetric === ""}
                            onClick={() => handleNextDetection(1)}
                          >
                            Seleccionar grupos demográficos
                          </StyledButton>
                        )}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {detectionStep === 1 && (
                    <FlexColumn gap="16px" alignItems="flex-start" width="100%">
                      <Typography variant="h4">
                        Análisis de interseccionalidad
                      </Typography>
                      <StyledStepper
                        variant="dots"
                        steps={2}
                        activeStep={detectionStep}
                        position="static"
                        justifycontent="center"
                      />
                      <Typography variant="body1">
                        Muchas veces los sesgos se encuentran implícitos en la
                        combinación de dos variables protegidas. Selecciona las
                        combinaciones que quieres explorar.
                      </Typography>
                      <StyledDivider />
                      {tuplesSelectors}
                      <StyledButton
                        variant="outlined"
                        secondary
                        onClick={handleAddTuplesSelector}
                        disabled={!(tuplesSelectors.length === tuples.length)}
                      >
                        Añadir otra combinación
                      </StyledButton>
                      <FlexRow gap="8px">
                        <StyledButton
                          variant="outlined"
                          secondary
                          onClick={() => handlePrevDetection(1)}
                        >
                          Regresar
                        </StyledButton>
                        <StyledButton
                          variant="contained"
                          onClick={() => detect()}
                        >
                          Detectar sesgos
                        </StyledButton>
                      </FlexRow>
                    </FlexColumn>
                  )}
                </FlexColumn>
              </BorderCard>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ p: "20px", alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <FlexRow
                justifycontent="space-between"
                alignItems="center"
                gap="48px"
              >
                <FlexColumn>
                  <Typography variant="h5">Detección de sesgos</Typography>
                  <Typography variant="body1">
                    En esta sección se detectan los segos de tu modelo.
                  </Typography>
                </FlexColumn>
                <FlexColumn alignItems="center" gap="8px">
                  <StyledButton
                    sx={{ width: "max-content", minWidth: "100%" }}
                    secondary="true"
                  >
                    <Typography
                      variant="body2"
                      onClick={() =>
                        handleNavigation(
                          `/dashboard/${actualData.id_model}/performance`
                        )
                      }
                    >
                      ← Ver rendimiento técnico
                    </Typography>
                  </StyledButton>
                  <StyledButton
                    sx={{ width: "max-content", minWidth: "100%" }}
                    secondary="true"
                  >
                    <Typography
                      variant="body2"
                      onClick={() =>
                        handleNavigation(
                          `/dashboard/${actualData.id_model}/mitigation`
                        )
                      }
                    >
                      Mitigar sesgos →
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </FlexRow>
              <StyledDivider sx={{ marginBottom: "28px", marginTop: "28px" }} />
              <FlexRow justifycontent="space-between" alignItems="center">
                <Typography variant="h6">{actualData.name}</Typography>
                {texts && (
                  <DetectionReport
                    textsByVariable={texts}
                    fairness={actualMetric}
                    variables={Object.keys(actualData.original_dataframe)
                      .filter(
                        (key) => key !== "prediccion" && key !== "etiqueta_real"
                      )
                      .map((key) => key)}
                    tuples={tuples}
                    loading={loading}
                  />
                )}
              </FlexRow>
            </Grid>
            {softLineVariables.length !== 0 && (
              <Grid item xs={12}>
                <BorderCard padding="16px">
                  <FlexColumn gap="16px">
                    <Typography variant="h4">
                      Distribución de las predicciones del modelo por variable
                      protegida.
                    </Typography>

                    <Typography variant="body1">
                      {texts.texto_principal}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: texts.texto_variables_protegidas,
                      }}
                    />
                    {loading ? (
                      <StyledSkeletonRectangular />
                    ) : (
                      <Box sx={{ padding: "16px" }}>
                        <Grid
                          container
                          spacing={2}
                          columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                        >
                          {Object.keys(softLineVariables).map((key, index) => (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={6}
                              xl={4}
                              key={`softline-${index}`}
                            >
                              <ReactECharts
                                ref={(el) =>
                                  (softLineVariableChartRefs.current[index] =
                                    el)
                                }
                                option={generateSoftLineOption(
                                  softLineVariables[key],
                                  key
                                )}
                                style={{
                                  height: "400px",
                                  width: "100%",
                                  maxWidth: "440px",
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </FlexColumn>
                </BorderCard>
              </Grid>
            )}
            {barVariables.length !== 0 && (
              <Grid item xs={12}>
                <BorderCard padding="16px">
                  <FlexColumn gap="16px">
                    <Typography variant="h4">
                      Distribución de{" "}
                      {actualData.fairness_definition === "fpr"
                        ? "False Positive Rate Parity"
                        : "Recall - True Positive Rate Parity"}{" "}
                      por variable protegida
                    </Typography>
                    <Typography variant="body1">
                      Los colores que observas en las gráficas muestran el nivel
                      de sesgos detectado con la definición de justicia
                      seleccionada. Cada color corresponde a un intervalo en la
                      disparidad de la métrica de cada categoría respecto a la
                      variable de referencia y se distribuyen así:
                    </Typography>
                    <DetectionColorBanner />
                    <Typography variant="body1">
                      {texts.texto_principal}
                    </Typography>
                    {loading ? (
                      <StyledSkeletonRectangular />
                    ) : (
                      <Box sx={{ padding: "16px" }}>
                        {Object.keys(barVariables).map((key, index) => (
                          <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                          >
                            <Grid item xs={4} sm={4} md={12} xl={12}>
                              <Typography variant="h5">{key}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={6}
                              xl={3}
                              key={`roc-${index}`}
                            >
                              {rocVariables[key].error === "error" ? (
                                <StyledImage
                                  padding="20px"
                                  src={GraphError}
                                  alt={"Error-" + key}
                                />
                              ) : (
                                <ReactECharts
                                  ref={(el) =>
                                    (rocVariableChartRefs.current[index] = el)
                                  }
                                  option={generateRocOption(
                                    rocVariables[key],
                                    key
                                  )}
                                  style={{
                                    height: "300px",
                                    width: "100%",
                                    maxWidth: "440px",
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={6}
                              xl={3}
                              key={`bar-${index}`}
                            >
                              {barVariables[key].tipo === "error" ? (
                                <StyledImage
                                  padding="20px"
                                  src={GraphError}
                                  alt={"Error-" + key}
                                />
                              ) : (
                                <ReactECharts
                                  ref={(el) =>
                                    (barVariableChartRefs.current[index] = el)
                                  }
                                  option={generateBarOption(
                                    barVariables[key],
                                    key
                                  )}
                                />
                              )}
                            </Grid>
                            <Grid item xs={4} sm={4} md={12} xl={6}>
                              <Typography variant="h6">
                                {texts.texto_variables_protegidas[key].titulo}
                              </Typography>
                              <Typography variant="body1">
                                {texts.texto_variables_protegidas[key].parrafo}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    )}
                  </FlexColumn>
                </BorderCard>
              </Grid>
            )}
            {tprBarVariables.length !== 0 && (
              <Grid item xs={12}>
                <BorderCard padding="16px">
                  <FlexColumn gap="16px">
                    <Typography variant="h4">
                      Distribución de Equalized Odds por variable protegida
                    </Typography>
                    <Typography variant="body1">
                      Los colores que observas en las gráficas muestran el nivel
                      de sesgos detectado con la definición de justicia
                      seleccionada. Cada color corresponde a un intervalo en la
                      disparidad de la métrica de cada categoría respecto a la
                      variable de referencia y se distribuyen así:
                    </Typography>
                    <DetectionColorBanner />
                    <Typography variant="body1">
                      {texts.texto_principal}
                    </Typography>
                    {loading ? (
                      <StyledSkeletonRectangular />
                    ) : (
                      <Box sx={{ padding: "16px" }}>
                        {Object.keys(tprBarVariables).map((key, index) => (
                          <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                          >
                            <Grid item xs={4} sm={4} md={12} xl={12}>
                              <Typography variant="h5">{key}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              xl={2}
                              key={`roc-${index}`}
                            >
                              {rocVariables[key].error === "error" ? (
                                <StyledImage
                                  padding="20px"
                                  src={GraphError}
                                  alt={"Error-" + key}
                                />
                              ) : (
                                <ReactECharts
                                  ref={(el) =>
                                    (rocVariableChartRefs.current[index] = el)
                                  }
                                  option={generateRocOption(
                                    rocVariables[key],
                                    key
                                  )}
                                  style={{
                                    height: "300px",
                                    width: "100%",
                                    maxWidth: "440px",
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              xl={2}
                              key={`bar-${index}`}
                            >
                              {tprBarVariables[key].tipo === "error" ? (
                                <StyledImage
                                  padding="20px"
                                  src={GraphError}
                                  alt={"Error-" + key}
                                />
                              ) : (
                                <ReactECharts
                                  ref={(el) =>
                                    (tprBarVariableChartRefs.current[index] =
                                      el)
                                  }
                                  option={generateBarOption(
                                    tprBarVariables[key],
                                    "TPR"
                                  )}
                                />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              xl={2}
                              key={`bar-${index}`}
                            >
                              {fprBarVariables[key].tipo === "error" ? (
                                <StyledImage
                                  padding="20px"
                                  src={GraphError}
                                  alt={"Error-" + key}
                                />
                              ) : (
                                <ReactECharts
                                  ref={(el) =>
                                    (fprBarVariableChartRefs.current[index] =
                                      el)
                                  }
                                  option={generateBarOption(
                                    fprBarVariables[key],
                                    "FPR"
                                  )}
                                />
                              )}
                            </Grid>
                            <Grid item xs={4} sm={4} md={12} xl={6}>
                              <Typography variant="h6">
                                {texts.texto_variables_protegidas[key].titulo}
                              </Typography>
                              <Typography variant="body1">
                                {texts.texto_variables_protegidas[key].parrafo}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    )}
                  </FlexColumn>
                </BorderCard>
              </Grid>
            )}
            {calibrationLineVariables.length !== 0 && (
              <Grid item xs={12}>
                <BorderCard padding="16px">
                  <FlexColumn gap="16px">
                    <Typography variant="h4">
                      Distribución de Calibration por variable protegida
                    </Typography>

                    <Typography variant="body1">
                      {texts.texto_principal}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: texts.texto_variables_protegidas,
                      }}
                    />
                    {loading ? (
                      <StyledSkeletonRectangular />
                    ) : (
                      <Box sx={{ padding: "16px" }}>
                        <Grid
                          container
                          spacing={2}
                          columns={{ xs: 4, sm: 4, md: 12, xl: 12 }}
                        >
                          {Object.keys(calibrationLineVariables).map(
                            (key, index) => (
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={6}
                                xl={4}
                                key={`calibrationline-${index}`}
                              >
                                <ReactECharts
                                  ref={(el) =>
                                    (calibrationLineVariableChartRefs.current[
                                      index
                                    ] = el)
                                  }
                                  option={generateCalibrationLineOption(
                                    calibrationLineVariables[key],
                                    key
                                  )}
                                  style={{
                                    height: "400px",
                                    width: "100%",
                                    maxWidth: "440px",
                                  }}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Box>
                    )}
                  </FlexColumn>
                </BorderCard>
              </Grid>
            )}
          </Grid>
        )
      ) : (
        <Grid
          container
          spacing={3}
          sx={{ p: "20px", alignContent: "flex-start" }}
        />
      )}

      <Modal
        open={openMitigateModal}
        onClose={() => setOpenMitigateModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <BorderCard padding="16px">
            <FlexColumn justifycontent="center">
              <FlexRow justifycontent="flex-end" width="100%">
                <IconButton onClick={() => setOpenMitigateModal(false)}>
                  <CloseIcon color="primary" />
                </IconButton>
              </FlexRow>
              <FlexColumn
                gap="28px"
                sx={{ padding: "0px 32px", marginTop: "28px" }}
              >
                <Typography align="center" variant="h4">
                  ¡Felicitaciones! No se detectaron sesgos en tu modelo
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  sx={{ fontSize: "20px" }}
                >
                  Tu modelo ha pasado la evaluación de sesgos sin encontrar
                  diferencias significativas: ninguna de las variables
                  protegidas presenta métricas con diferencias mayores a 0.1
                  entre grupos. Para mantener la equidad en el tiempo, te
                  recomendamos revisar periódicamente el desempeño de tu modelo
                  entre los distintos grupos poblacionales.
                </Typography>
                <FlexRow gap="20px" justifycontent="center">
                  <StyledButton
                    variant="contained"
                    onClick={() => setOpenMitigateModal(false)}
                  >
                    <Typography variant="body2">Continuar</Typography>
                  </StyledButton>
                </FlexRow>
              </FlexColumn>
            </FlexColumn>
          </BorderCard>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          overflow: "auto",
          display: "-webkit-box",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px",
        }}
      >
        <Box
          sx={{
            width: "70%",
            minWidth: "800px",
          }}
        >
          <BorderCard padding="16px">
            <FlexColumn justifycontent="center">
              <FlexRow justifycontent="space-between" width="100%">
                <IconButton
                  onClick={handlePrevStep}
                  disabled={activeStep === 0}
                  sx={{ opacity: activeStep === 0 ? "0%" : "100%" }}
                >
                  <ArrowBackIcon color="primary" />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <CloseIcon color="primary" />
                </IconButton>
              </FlexRow>

              {activeStep === 0 && (
                <FlexColumn
                  gap="28px"
                  sx={{ padding: "0px 32px", marginTop: "28px" }}
                >
                  <Typography align="center" variant="h4">
                    Determinemos la métrica óptima para evaluar tu modelo
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    sx={{ fontSize: "20px" }}
                  >
                    Cuando estás evaluando la equidad de tu modelo, ¿cuál de los
                    siguientes escenarios describe mejor tu objetivo principal?
                  </Typography>
                  <FlexRow gap="20px">
                    <StyledButton
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                      secondary="true"
                      onClick={() => handleNext(2, metrics[0])}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "100%",
                        }}
                      >
                        a. Quiero asegurarme de que los grupos o categorías de
                        las variables protegidas tengan la misma probabilidad de
                        recibir una predicción positiva, sin importar su
                        desempeño real en la predicción.
                      </Typography>
                    </StyledButton>

                    <StyledButton
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                      secondary="true"
                      onClick={() => handleNext(1)}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "100%",
                        }}
                      >
                        b. Quiero asegurarme de que el modelo sea igual de
                        preciso para los grupos o categorías de las variables
                        protegidas.
                      </Typography>
                    </StyledButton>

                    <StyledButton
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                      secondary="true"
                      onClick={() => handleNext(2, metrics[4])}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "100%",
                        }}
                      >
                        c. Quiero asegurarme de que las predicciones del modelo
                        reflejen con precisión la proporción observada de la
                        variable objetivo para los grupos o categorías de las
                        variables protegidas. Esto significa que requiero un
                        modelo calibrado, condicional a cada grupo.
                      </Typography>
                    </StyledButton>
                  </FlexRow>
                </FlexColumn>
              )}
              {activeStep === 1 && (
                <FlexColumn
                  gap="28px"
                  sx={{ padding: "0px 32px", marginTop: "28px" }}
                >
                  <Typography align="center" variant="h4">
                    Determinemos la métrica óptima para evaluar tu modelo
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    sx={{ fontSize: "20px" }}
                  >
                    La predicción positiva (predicción igual a 1) en tu modelo,
                    ¿puede perjudicar o ayudar a quien se le asigne?
                  </Typography>
                  <FlexRow gap="20px">
                    <StyledButton
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                      secondary="true"
                      onClick={() => handleNext(1, metrics[1])}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "100%",
                        }}
                      >
                        a. La predicción positiva puede beneficiar a quien la
                        recibe. (Por ejemplo, se trata de un modelo de
                        aprobación de crédito, donde una predicción positiva
                        significa que se le otorgará un préstamo, lo cual podría
                        ser benéfico para la persona).
                      </Typography>
                    </StyledButton>

                    <StyledButton
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                      secondary="true"
                      onClick={() => handleNext(1, metrics[2])}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "100%",
                        }}
                      >
                        b. La predicción positiva puede perjudicar a quien la
                        recibe. (Por ejemplo, se trata de un modelo que predice
                        reincidencia en crimen. Una predicción positiva implica
                        restringir la condición de libertad de la persona.)
                      </Typography>
                    </StyledButton>

                    <StyledButton
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                      secondary="true"
                      onClick={() => handleNext(1, metrics[3])}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "100%",
                        }}
                      >
                        c. Es ambiguo.
                      </Typography>
                    </StyledButton>
                  </FlexRow>
                </FlexColumn>
              )}
              {activeStep === 2 && (
                <FlexColumn
                  gap="28px"
                  sx={{ padding: "0px 32px", marginTop: "28px" }}
                >
                  <Typography align="center" variant="body1">
                    Según tus criterios la métrica de justicia óptima para tu
                    modelo es:
                  </Typography>
                  <Typography align="center" variant="h4">
                    {selectedMetric?.title}
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    sx={{ fontSize: "20px" }}
                  >
                    {selectedMetric?.description}
                  </Typography>
                  <FlexRow gap="20px" justifycontent="center">
                    <StyledButton
                      variant="outlined"
                      secondary
                      onClick={handleReset}
                    >
                      Reiniciar quiz
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      onClick={() => handleAccept()}
                    >
                      Aceptar
                    </StyledButton>
                  </FlexRow>
                </FlexColumn>
              )}
            </FlexColumn>
          </BorderCard>
        </Box>
      </Modal>
    </PageContainerDashboard>
  );
};

export default Detection;
