import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconGlossaryItem = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
      >
        <rect x="0.230469" width="40" height="40" rx="20" fill="#02403C" />
        <mask
          id="mask0_3758_2543"
          maskUnits="userSpaceOnUse"
          x="5"
          y="5"
          width="31"
          height="30"
        >
          <rect x="5.23047" y="5" width="30" height="30" fill="#F5FEF4" />
        </mask>
        <g mask="url(#mask0_3758_2543)">
          <path
            d="M14.6055 32.5C13.3763 32.5 12.3398 32.0781 11.4961 31.2344C10.6523 30.3906 10.2305 29.3542 10.2305 28.125V11.875C10.2305 10.6667 10.6523 9.63542 11.4961 8.78125C12.3398 7.92708 13.3763 7.5 14.6055 7.5H30.2305V26.25C29.6888 26.25 29.2409 26.4323 28.8867 26.7969C28.5326 27.1615 28.3555 27.6042 28.3555 28.125C28.3555 28.6667 28.5326 29.1146 28.8867 29.4688C29.2409 29.8229 29.6888 30 30.2305 30V32.5H14.6055ZM14.6055 30H26.2617C26.1367 29.7083 26.0378 29.4115 25.9648 29.1094C25.8919 28.8073 25.8555 28.4792 25.8555 28.125C25.8555 27.7917 25.8867 27.4688 25.9492 27.1562C26.0117 26.8438 26.1159 26.5417 26.2617 26.25H14.6055C14.0638 26.25 13.6159 26.4323 13.2617 26.7969C12.9076 27.1615 12.7305 27.6042 12.7305 28.125C12.7305 28.6667 12.9076 29.1146 13.2617 29.4688C13.6159 29.8229 14.0638 30 14.6055 30ZM12.7305 24.1562C13.0221 24.0104 13.319 23.9062 13.6211 23.8438C13.9232 23.7812 14.2513 23.75 14.6055 23.75H27.7305V10H14.6055C14.0638 10 13.6159 10.1823 13.2617 10.5469C12.9076 10.9115 12.7305 11.3542 12.7305 11.875V24.1562ZM16.1367 21.25H17.668L18.4492 19.0312H21.9805L22.7617 21.25H24.293L20.9805 12.5H19.418L16.1367 21.25ZM18.8867 17.75L20.168 14.125H20.2617L21.543 17.75H18.8867Z"
            fill="#F5FEF4"
          />
        </g>
      </svg>
    </CustomSvgIcon>
  );
};

export default IconGlossaryItem;
