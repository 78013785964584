import React from "react";
import { Divider } from "@mui/material";
import { styled } from "@mui/system";

const CustomDivider = styled(Divider)(({ theme, purple, ...props }) => ({
  width: "100%",
  backgroundColor: purple ? "#4D033F" : theme.palette.primary.main,
  marginTop: props.marginTop,
}));

const StyledDivider = (props) => {
  const { purple } = props;
  return (
    <CustomDivider {...props} purple={purple}>
      {props.children}
    </CustomDivider>
  );
};

export default StyledDivider;
