import { useParams } from "react-router-dom";
import { useBlogs } from "../../context/BlogContext";
import PageContainer from "../../components/shared/container/PageContainer";
import MaxWidthContainer from "../../components/shared/container/MaxWidthContainer";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import StyledTextDottedDivider from "../../components/common/divider/StyledTextDottedDivider";
import BlogSlider from "../../components/shared/section/BlogSlider";
import FlexColumn from "../../components/shared/container/FlexColumn";
import FlexRow from "../../components/shared/container/FlexRow";
import { Box, styled, TextField, Typography } from "@mui/material";
import StyledButton from "../../components/common/button/StyledButton";
import HomeCard from "../../components/shared/container/HomeCard";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  color: "#02403C",
  "& .MuiOutlinedInput-root": {
    color: "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: "#02403C",
      borderColor: "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#02403C",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#02403C",
  },
}));

const BlogEntry = () => {
  const { slug } = useParams();
  const { blogs } = useBlogs();

  const blog = blogs.find((blog) => blog.slug === slug);

  if (!blog) return <div>Blog no encontrado</div>;

  return (
    <PageContainer>
      <Box
        sx={{
          backgroundImage: `url(${blog.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "360px !important",
          marginBottom: "80px",
        }}
      />
      <MaxWidthContainer>
        <FlexRow gap="120px">
          <FlexColumn width="60%">
            <Typography
              variant="h1"
              sx={{
                fontSize: "52px",
                lineHeight: "58px",
                marginBottom: "16px",
              }}
            >
              {blog.title}
            </Typography>
            <FlexRow
              gap="10px"
              alignItems="center"
              sx={{
                marginBottom: "40px",
              }}
            >
              <StyledButton variant="outlined" secondary="true">
                <Typography
                  variant="body1"
                  sx={{
                    textWrap: "nowrap",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Blog
                </Typography>
              </StyledButton>
              <Typography variant="h6">
                {blog.author}, {blog.date}
              </Typography>
            </FlexRow>

            <Typography
              variant="h6"
              sx={{
                marginBottom: "40px",
                borderLeft: "5px solid #02403C",
                paddingLeft: "16px",
              }}
            >
              {blog.summary}
            </Typography>
            {blog.content.map((item, index) => {
              switch (item.type) {
                case "paragraph":
                  return (
                    <Typography
                      variant="body1"
                      key={index}
                      sx={{ marginBottom: "8px" }}
                    >
                      {item.text}
                    </Typography>
                  );
                case "heading":
                  return (
                    <Typography
                      variant="h5"
                      key={index}
                      sx={{ marginTop: "40px", marginBottom: "8px" }}
                    >
                      {item.text}
                    </Typography>
                  );
                case "image":
                  return <img key={index} src={item.src} alt={item.alt} />;
                default:
                  return null;
              }
            })}
          </FlexColumn>
          <FlexColumn gap="48px" width="40%">
            <FlexColumn gap="10px">
              <Typography variant="h6">Categorias</Typography>
              {blog.categories.map((category) => (
                <StyledButton
                  variant="outlined"
                  secondary="true"
                  sx={{ marginRight: "auto" }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      textWrap: "nowrap",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {category}
                  </Typography>
                </StyledButton>
              ))}
            </FlexColumn>
            <HomeCard padding="24px" sx={{ height: "auto" }}>
              <FlexColumn
                justifyContent="center"
                alignItems="flex-start"
                gap="20px"
              >
                <Typography variant="h5">
                  Suscríbete a nuestro Newsletter
                </Typography>
                <Typography variant="body1">
                  Mantente informado de los últimos recursos que compartimos
                  sobre Inteligencia Artificial Responsable
                </Typography>
                <CustomTextField
                  label="Correo"
                  variant="outlined"
                  fullWidth
                  type="email"
                />
                <StyledButton variant="contained">Suscribirme</StyledButton>
              </FlexColumn>
            </HomeCard>
          </FlexColumn>
        </FlexRow>
      </MaxWidthContainer>

      <StyledTextDottedDivider>Recursos relacionados</StyledTextDottedDivider>
      <BlogSlider />
      <StyledFooter />
    </PageContainer>
  );
};

export default BlogEntry;
