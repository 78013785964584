import React from "react";
import { Table } from "@mui/material";
import { styled } from "@mui/system";

const CustomTable = styled(Table)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: "0.5px solid " + theme.palette.primary.main,
  "& th": {
    textAlign: "center",
    border: "1px solid " + theme.palette.primary.main,
    padding: "6px 4px", // Puedes ajustar el color del hover si es necesario
  },
  "& td": {
    textAlign: "center",
    border: "1px solid " + theme.palette.primary.main,
    padding: "6px 4px", // Puedes ajustar el color del hover si es necesario
  },
}));

const StyledTable = (props) => {
  return <CustomTable {...props}>{props.children}</CustomTable>;
};

export default StyledTable;
