import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconTrending = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="22"
        viewBox="0 0 30 22"
        fill="none"
      >
        <path
          d="M28.5928 18.3326H3.9335V0.979713C3.9335 0.475111 3.5248 0.0664062 3.0202 0.0664062H1.19358C0.688978 0.0664062 0.280273 0.475111 0.280273 0.979713V20.1592C0.280273 21.1678 1.09825 21.9858 2.10689 21.9858H28.5928C29.0974 21.9858 29.5061 21.5771 29.5061 21.0725V19.2459C29.5061 18.7413 29.0974 18.3326 28.5928 18.3326ZM26.7662 1.89302H20.0271C18.8067 1.89302 18.1954 3.36858 19.0584 4.23166L20.9079 6.0811L16.7198 10.2698L12.5317 6.08168C11.8182 5.36816 10.6617 5.36816 9.94877 6.08168L6.02783 10.0026C5.67107 10.3594 5.67107 10.9376 6.02783 11.2944L7.31902 12.5856C7.67578 12.9423 8.25402 12.9423 8.61078 12.5856L11.24 9.95581L15.428 14.1439C16.1416 14.8574 17.298 14.8574 18.011 14.1439L23.4908 8.66405L25.3403 10.5135C26.2034 11.3766 27.6789 10.7652 27.6789 9.54482V2.80633C27.6795 2.30173 27.2708 1.89302 26.7662 1.89302Z"
          fill="currentColor"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconTrending;
