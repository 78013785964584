import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconBalance = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="84"
        height="84"
        viewBox="0 0 84 84"
        fill="none"
      >
        <circle cx="41.7501" cy="42.2638" r="41.3356" fill="#02403C" />
        <path
          d="M14.8091 58.5283C14.8091 60.0251 16.023 61.239 17.5198 61.239H66.3133C67.8102 61.239 69.0241 60.0251 69.0241 58.5283V31.4208H14.8091V58.5283ZM52.7596 40.9084C52.7596 40.1596 53.3661 39.553 54.115 39.553H62.2472C62.9961 39.553 63.6026 40.1596 63.6026 40.9084V43.6192C63.6026 44.368 62.9961 44.9745 62.2472 44.9745H54.115C53.3661 44.9745 52.7596 44.368 52.7596 43.6192V40.9084ZM52.7596 51.0737C52.7596 50.6993 53.0628 50.396 53.4373 50.396H62.9249C63.2993 50.396 63.6026 50.6993 63.6026 51.0737V52.4291C63.6026 52.8035 63.2993 53.1068 62.9249 53.1068H53.4373C53.0628 53.1068 52.7596 52.8035 52.7596 52.4291V51.0737ZM20.2306 42.9415C20.2306 42.5671 20.5338 42.2638 20.9083 42.2638H46.6604C47.0348 42.2638 47.3381 42.5671 47.3381 42.9415V44.2969C47.3381 44.6713 47.0348 44.9745 46.6604 44.9745H20.9083C20.5338 44.9745 20.2306 44.6713 20.2306 44.2969V42.9415ZM20.2306 51.0737C20.2306 50.6993 20.5338 50.396 20.9083 50.396H35.8174C36.1918 50.396 36.4951 50.6993 36.4951 51.0737V52.4291C36.4951 52.8035 36.1918 53.1068 35.8174 53.1068H20.9083C20.5338 53.1068 20.2306 52.8035 20.2306 52.4291V51.0737ZM67.6687 23.2885H16.1645C15.4156 23.2885 14.8091 23.8951 14.8091 24.6439V28.71H69.0241V24.6439C69.0241 23.8951 68.4176 23.2885 67.6687 23.2885Z"
          fill="#EBFDEA"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconBalance;
