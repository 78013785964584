import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../../context/DataContext";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import FlexColumn from "../container/FlexColumn";
import { toast } from "react-toastify";
import StyledDivider from "../../common/divider/StyledDivider";

const TuplesSelector = ({ addTuple, removeTuple, tuples, combination }) => {
  const { actualData } = useContext(DataContext);
  const [checkedMetrics, setCheckedMetrics] = useState([]);
  const [currentTuple, setCurrentTuple] = useState([]);

  useEffect(() => {
    if (checkedMetrics.length < 2) {
      removeTuple(currentTuple);
    }
    if (checkedMetrics.length === 2) {
      const newTuple = [...checkedMetrics].sort();

      if (currentTuple) {
        removeTuple(currentTuple);
      }

      addTuple(newTuple);
      setCurrentTuple(newTuple);
    }
  }, [checkedMetrics]);

  const handleCheck = (variable) => {
    const newCheckedMetrics = checkedMetrics.includes(variable)
      ? checkedMetrics.filter((item) => item !== variable)
      : [...checkedMetrics, variable];

    const sortedNewTuple = newCheckedMetrics.sort();
    const tupleExists = tuples.some(
      (tuple) =>
        tuple.length === sortedNewTuple.length &&
        tuple.every((val, index) => val === sortedNewTuple[index])
    );

    if (!tupleExists) {
      setCheckedMetrics(newCheckedMetrics);
    } else {
      // Mostrar advertencia o bloquear la selección
      toast.warning("Esta combinación ya existe.");
    }
  };

  return (
    <FlexColumn gap="16px" alignItems="flex-start" width="100%">
      <FormControl component="fieldset">
        <FormGroup aria-label="position" column="true">
          <Typography variant="h4">Combinación {combination}</Typography>
          {Object.keys(actualData.data_view)
            .filter((key) => key !== "etiqueta_real" && key !== "prediccion")
            .map((variableKey, index) => (
              <FormControlLabel
                key={index}
                value={variableKey}
                control={
                  <Checkbox
                    checked={checkedMetrics.includes(variableKey)}
                    onChange={() => handleCheck(variableKey)}
                    disabled={
                      !checkedMetrics.includes(variableKey) &&
                      checkedMetrics.length >= 2
                    }
                  />
                }
                label={
                  <FlexColumn>
                    <Typography>{variableKey}</Typography>
                  </FlexColumn>
                }
                labelPlacement="end"
                sx={{ margin: "0px", marginBottom: "16px" }}
              />
            ))}
        </FormGroup>
      </FormControl>
      <StyledDivider />
    </FlexColumn>
  );
};

export default TuplesSelector;
