import React from "react";
import { Paper } from "@mui/material";
import { styled } from "@mui/system";

const CustomPaper = styled(Paper)(({ theme, secondary, ...props }) => ({
  height: "100%",
  width: props.width,
  display: props.display,
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  borderRadius: "20px",
  padding: props.padding,
  background: secondary
    ? theme.palette.primary[100]
    : theme.palette.primary[50],
  "& .MuiTypography-root": {
    color: secondary
      ? `${theme.palette.primary.contrastText} !important`
      : theme.palette.primary.main,
  },
  "& .MuiButtonBase-root": {
    border: secondary
      ? `1px solid ${theme.palette.primary.contrastText} !important`
      : `1px solid ${theme.palette.primary.main}`,
  },
}));

const HomeCard = (props) => {
  return <CustomPaper {...props}>{props.children}</CustomPaper>;
};

export default HomeCard;
