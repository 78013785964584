import { Box, Typography } from "@mui/material";
import FlexColumn from "../container/FlexColumn";
import FlexRow from "../container/FlexRow";

const DetectionColorBanner = () => {
  return (
    <FlexColumn gap="16px" sx={{ marginBottom: "20px" }}>
      <Typography variant="body1">
        Diferencia con el grupo de referencia
      </Typography>

      <FlexRow gap="80px" sx={{ flexWrap: "wrap" }}>
        <FlexColumn>
          <FlexRow gap="16px" sx={{}}>
            <FlexColumn
              sx={{
                padding: "4px",
                borderLeft: "0.5px solid #02403C50",
              }}
            >
              <Box
                sx={{
                  height: "80px",
                  width: "80px",
                  background: "#193F3B",
                }}
              />
              <Typography variant="body1">0</Typography>
            </FlexColumn>
            <FlexColumn
              sx={{
                padding: "4px",
                borderLeft: "0.5px solid #02403C50",
              }}
            >
              <Box
                sx={{
                  height: "80px",
                  width: "80px",
                  background: "#79978D",
                }}
              />
              <Typography variant="body1">0.05</Typography>
            </FlexColumn>
            <FlexColumn
              sx={{
                padding: "4px",
                borderLeft: "0.5px solid #02403C50",
              }}
            >
              <Box
                sx={{
                  height: "80px",
                  width: "80px",
                  background: "#F19F57",
                }}
              />
              <Typography variant="body1">0.10</Typography>
            </FlexColumn>
            <FlexColumn
              sx={{
                padding: "4px",
                borderLeft: "0.5px solid #02403C50",
              }}
            >
              <Box
                sx={{
                  height: "80px",
                  width: "80px",
                  background: "#924553",
                }}
              />
              <Typography variant="body1">0.15</Typography>
            </FlexColumn>
            <FlexColumn
              sx={{
                padding: "4px",
                borderLeft: "0.5px solid #02403C50",
              }}
            >
              <Box
                sx={{
                  height: "80px",
                  width: "80px",
                  background: "#652832",
                }}
              />
              <Typography variant="body1">{">"}0.2</Typography>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <FlexRow
          gap="12px"
          sx={{
            padding: "4px",
            borderLeft: "0.5px solid #02403C50",
          }}
        >
          <Box
            sx={{
              height: "80px",
              width: "80px",
              background: "#6A6667",
            }}
          />
          <Typography variant="body1" sx={{ maxWidth: "440px" }}>
            Este color representa a la categoría de referencia, es decir,
            aquella que tiene el mínimo valor en la métrica de justicia
            seleccionada.
          </Typography>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
};

export default DetectionColorBanner;
