import React from "react";
import { Box, Toolbar } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const PageContainer = (props) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar sx={{ height: "92px" }} />
      <CustomBox {...props}>{props.children}</CustomBox>
    </Box>
  );
};

export default PageContainer;
