import React from "react";
import { MobileStepper } from "@mui/material";
import { styled } from "@mui/system";

const CustomStepper = styled(MobileStepper)(({ theme, ...props }) => ({
  display: "flex",
  justifyContent: props.justifycontent,
  gap: "8px",
  padding: "0px",
  "& .MuiMobileStepper-dots": {
    gap: "8px",
  },
  "& .MuiMobileStepper-dot": {
    borderRadius: "64px",
    width: "48px",
    height: "6px",
  },
}));

const StyledStepper = (props) => {
  return <CustomStepper {...props} />;
};

export default StyledStepper;
