import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconBalance = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="84"
        height="84"
        viewBox="0 0 84 84"
        fill="none"
      >
        <circle cx="41.7501" cy="42.2638" r="41.3356" fill="#02403C" />
        <path
          d="M47.1987 41.1908L42.814 49.96C42.2847 51.0275 40.7496 50.9922 40.2644 49.9071L35.2446 38.7647L32.598 45.0902H24.2963L40.3968 61.5436C41.0231 62.1876 42.0377 62.1876 42.6641 61.5436L58.7734 45.0902H49.1484L47.1987 41.1908ZM60.7407 26.2019L60.529 25.9814C55.9856 21.3409 48.5485 21.3409 43.9962 25.9814L41.5348 28.5045L39.0734 25.9902C34.53 21.3409 27.0841 21.3409 22.5407 25.9902L22.3289 26.2019C18.0325 30.5954 17.8473 37.5913 21.6849 42.2671H30.7188L33.886 34.6624C34.3624 33.5243 35.968 33.4979 36.4797 34.6271L41.6142 46.0342L45.9371 37.3973C46.4576 36.3562 47.9397 36.3562 48.4602 37.3973L50.8952 42.2671H61.3847C65.2224 37.5913 65.0371 30.5954 60.7407 26.2019Z"
          fill="#EBFDEA"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconBalance;
