import { Typography } from "@mui/material";
import StyledSlider from "../../common/slider/StyledSlider";
import BorderCard from "../container/BorderCard";
import FlexColumn from "../container/FlexColumn";
import MaxWidthContainer from "../container/MaxWidthContainer";
import FlexRow from "../container/FlexRow";
import StyledButton from "../../common/button/StyledButton";
import { useBlogs } from "../../../context/BlogContext";
import { useNavigate } from "react-router-dom";
import StyledSliderImage from "../../common/slider/StyledSliderImage";

const BlogSlider = () => {
  const { blogs } = useBlogs();
  const navigate = useNavigate();

  const handleCardClick = (slug) => {
    navigate(`/blogs/${slug}`);
    window.scrollTo(0, 0);
  };

  return (
    <MaxWidthContainer>
      <StyledSlider slidesToShow={3.3} showArrows={false}>
        {blogs.map((blog) => (
          <BorderCard sx={{ margin: "16px" }}>
            <FlexColumn
              gap="20px"
              alignItems="flex-start"
              justifyContent="center"
              padding="20px 28px"
            >
              <StyledSliderImage src={blog.image} alt={blog.slug} />
              <Typography
                variant="h5"
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 1,
                }}
              >
                {blog.title}
              </Typography>
              <FlexRow gap="8px" width="100%" justifyContent="stretch">
                {blog.categories.slice(0, 3).map((category) => (
                  <StyledButton
                    variant="outlined"
                    secondary="true"
                    sx={{ width: "33%" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: "nowrap",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {category}
                    </Typography>
                  </StyledButton>
                ))}
              </FlexRow>
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  minHeight: "54px",
                }}
              >
                {blog.summary}
              </Typography>
              <StyledButton
                variant="contained"
                onClick={() => handleCardClick(blog.slug)}
              >
                Ver más
              </StyledButton>
            </FlexColumn>
          </BorderCard>
        ))}
      </StyledSlider>
    </MaxWidthContainer>
  );
};

export default BlogSlider;
