import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme, ...props }) => ({
  width: props.width ? props.width : "100%",
  height: props.height + " !important",
}));

const StyledImage = (props) => {
  const { src, alt } = props;
  return (
    <CustomBox {...props}>
      <img src={src} alt={alt} width={"100%"} />
    </CustomBox>
  );
};

export default StyledImage;
