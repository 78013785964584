import React from "react";
import { Paper } from "@mui/material";
import { styled } from "@mui/system";

const CustomPaper = styled(Paper)(({ theme, shadow, purple, ...props }) => ({
  height: "100%",
  width: props.width,
  display: props.display,
  justifyContent: "center",
  alignItems: "center",
  boxShadow: shadow
    ? purple
      ? "0px 5px 10px #4D033F50"
      : "0px 5px 10px #02403C50"
    : "none",
  border: purple
    ? "1px solid #4D033F"
    : "1px solid " + theme.palette.primary.main,
  borderRadius: "20px",
  padding: props.padding,
}));

const BorderCard = (props) => {
  const { shadow, purple } = props;
  return (
    <CustomPaper shadow={shadow} purple={purple} {...props}>
      {props.children}
    </CustomPaper>
  );
};

export default BorderCard;
