import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconGlossary = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
      >
        <mask
          id="mask0_3758_2544"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="31"
          height="30"
        >
          <rect x="0.230469" width="30" height="30" fill="#F5FEF4" />
        </mask>
        <g mask="url(#mask0_3758_2544)">
          <path
            d="M9.60547 27.5C8.3763 27.5 7.33984 27.0781 6.49609 26.2344C5.65234 25.3906 5.23047 24.3542 5.23047 23.125V6.875C5.23047 5.66667 5.65234 4.63542 6.49609 3.78125C7.33984 2.92708 8.3763 2.5 9.60547 2.5H25.2305V21.25C24.6888 21.25 24.2409 21.4323 23.8867 21.7969C23.5326 22.1615 23.3555 22.6042 23.3555 23.125C23.3555 23.6667 23.5326 24.1146 23.8867 24.4688C24.2409 24.8229 24.6888 25 25.2305 25V27.5H9.60547ZM9.60547 25H21.2617C21.1367 24.7083 21.0378 24.4115 20.9648 24.1094C20.8919 23.8073 20.8555 23.4792 20.8555 23.125C20.8555 22.7917 20.8867 22.4688 20.9492 22.1562C21.0117 21.8438 21.1159 21.5417 21.2617 21.25H9.60547C9.0638 21.25 8.61589 21.4323 8.26172 21.7969C7.90755 22.1615 7.73047 22.6042 7.73047 23.125C7.73047 23.6667 7.90755 24.1146 8.26172 24.4688C8.61589 24.8229 9.0638 25 9.60547 25ZM7.73047 19.1562C8.02214 19.0104 8.31901 18.9062 8.62109 18.8438C8.92318 18.7812 9.2513 18.75 9.60547 18.75H22.7305V5H9.60547C9.0638 5 8.61589 5.18229 8.26172 5.54688C7.90755 5.91146 7.73047 6.35417 7.73047 6.875V19.1562ZM11.1367 16.25H12.668L13.4492 14.0312H16.9805L17.7617 16.25H19.293L15.9805 7.5H14.418L11.1367 16.25ZM13.8867 12.75L15.168 9.125H15.2617L16.543 12.75H13.8867Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </CustomSvgIcon>
  );
};

export default IconGlossary;
