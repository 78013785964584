import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const CustomButton = styled(Button)(
  ({ theme, secondary, purple, ...props }) => ({
    padding: "4px 20px",
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: secondary
      ? "transparent"
      : purple
      ? "#4D033F"
      : theme.palette.primary.main,
    borderRadius: "50px",
    color: secondary
      ? purple
        ? "#4D033F"
        : theme.palette.primary.main
      : purple
      ? "#FAEDF8"
      : theme.palette.primary[50],
    "& .MuiTypography-root": {
      color: secondary
        ? purple
          ? "#4D033F"
          : theme.palette.primary.main
        : purple
        ? "#FAEDF8"
        : theme.palette.primary[50],
    },
    border: secondary
      ? purple
        ? "1px solid #4D033F"
        : "1px solid " + theme.palette.primary.main
      : "0px",
    "&:hover": {
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  })
);

const StyledButton = (props) => {
  const { disabled = false, purple, secondary, ...otherProps } = props; // Desestructuramos las props y asignamos un valor predeterminado a disabled
  return (
    <CustomButton
      disabled={disabled}
      secondary={secondary}
      purple={purple}
      {...otherProps}
    >
      {props.children}
    </CustomButton>
  );
};

export default StyledButton;
