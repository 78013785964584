import React from "react";
import { useDropzone } from "react-dropzone";
import StyledButton from "../button/StyledButton";
import { useApiClient } from "../../../api/apiService";
import { toast } from "react-toastify";

const StyledInputButton = ({ onUploadComplete }) => {
  const { post } = useApiClient();
  const handlePost = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("excelFile", file);
    try {
      const response = await post("load_model/", formData);
      if (onUploadComplete) {
        onUploadComplete(response);
        if (response.data.deleted_values) {
          toast.warning(
            "Atención: Se eliminaron columnas o filas con valores vacíos."
          );
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error("Error: Ocurrió un error al cargar el archivo.");
      }
    }
  };

  const { getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        handlePost(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        const fileRejection = fileRejections[0];
        const error = fileRejection.errors.find(
          (e) => e.code === "file-invalid-type"
        );
        if (error) {
          toast.error(
            `Error: El archivo ${fileRejection.file.name} tiene un tipo no permitido.`
          );
        }
      }
    },
  });

  return (
    <>
      <StyledButton variant="contained" component="label">
        <input {...getInputProps()} />
        Cargar datos
      </StyledButton>
    </>
  );
};

export default StyledInputButton;
