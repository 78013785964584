import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme, ...props }) => ({
  display: "flex",
  gap: props.gap,
  justifyContent: props.justifycontent,
  alignItems: props.alignItems,
  padding: props.padding,
  height: props.height,
  width: props.width,
}));

const FlexRow = (props) => {
  return <CustomBox {...props}>{props.children}</CustomBox>;
};

export default FlexRow;
