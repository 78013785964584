import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconBalancePurple = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="61"
        viewBox="0 0 61 61"
        fill="none"
      >
        <circle cx="30.25" cy="30.0931" r="30" fill="#4D033F" />
        <path
          d="M10.697 41.8974C10.697 42.9837 11.578 43.8648 12.6644 43.8648H48.0771C49.1635 43.8648 50.0445 42.9837 50.0445 41.8974V22.2237H10.697V41.8974ZM38.2402 29.1095C38.2402 28.566 38.6804 28.1258 39.2239 28.1258H45.1261C45.6695 28.1258 46.1097 28.566 46.1097 29.1095V31.0768C46.1097 31.6203 45.6695 32.0605 45.1261 32.0605H39.2239C38.6804 32.0605 38.2402 31.6203 38.2402 31.0768V29.1095ZM38.2402 36.4871C38.2402 36.2154 38.4603 35.9953 38.7321 35.9953H45.6179C45.8896 35.9953 46.1097 36.2154 46.1097 36.4871V37.4708C46.1097 37.7425 45.8896 37.9626 45.6179 37.9626H38.7321C38.4603 37.9626 38.2402 37.7425 38.2402 37.4708V36.4871ZM14.6318 30.585C14.6318 30.3132 14.8519 30.0931 15.1236 30.0931H33.8137C34.0854 30.0931 34.3055 30.3132 34.3055 30.585V31.5687C34.3055 31.8404 34.0854 32.0605 33.8137 32.0605H15.1236C14.8519 32.0605 14.6318 31.8404 14.6318 31.5687V30.585ZM14.6318 36.4871C14.6318 36.2154 14.8519 35.9953 15.1236 35.9953H25.9442C26.2159 35.9953 26.436 36.2154 26.436 36.4871V37.4708C26.436 37.7425 26.2159 37.9626 25.9442 37.9626H15.1236C14.8519 37.9626 14.6318 37.7425 14.6318 37.4708V36.4871ZM49.0608 16.3215H11.6807C11.1372 16.3215 10.697 16.7617 10.697 17.3052V20.2563H50.0445V17.3052C50.0445 16.7617 49.6043 16.3215 49.0608 16.3215Z"
          fill="#FFF5FC"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconBalancePurple;
