import {
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import MaxWidthContainer from "../container/MaxWidthContainer";
import FlexColumn from "../container/FlexColumn";
import BorderCard from "../container/BorderCard";
import { styled } from "@mui/system";
import StyledButton from "../../common/button/StyledButton";

const CustomTextField = styled(TextField)(({ purple, ...props }) => ({
  color: purple ? "#4D033F" : "#02403C",
  "& .MuiOutlinedInput-root": {
    color: purple ? "#4D033F" : "#02403C",
    borderRadius: "10px",
    "& fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
    },
    "&.Mui-focused fieldset": {
      color: purple ? "#4D033F" : "#02403C",
      borderColor: purple ? "#4D033F" : "#02403C",
    },
  },
  "& .MuiInputLabel-root": {
    color: purple ? "#4D033F" : "#02403C", // Aquí se especifica el color del label
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: purple ? "#4D033F" : "#02403C", // Aquí se especifica el color del label cuando está enfocado
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(
  ({ purple, ...props }) => ({
    color: purple ? "#4D033F" : "#02403C",
    "& .MuiFormControlLabel-label": {
      color: purple ? "#4D033F" : "#02403C",
    },
  })
);

const CustomCheckbox = styled(Checkbox)(({ purple, ...props }) => ({
  color: purple ? "#4D033F" : "#02403C",
  "&.Mui-checked": {
    color: purple ? "#4D033F" : "#02403C",
  },
}));

const FormSection = (props) => {
  const { purple } = props;
  return (
    <MaxWidthContainer id="Contact">
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "72px", marginBottom: "112px" }}
        columns={{ xs: 4, sm: 12, xl: 12 }}
      >
        <Grid item xs={4} sm={6} xl={6}>
          <FlexColumn
            gap="28px"
            alignItems="flex-start"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h2" color={purple && "#4D033F"}>
              CONTÁCTANOS
            </Typography>
            <Typography variant="h3" color={purple && "#4D033F"}>
              Construyamos el futuro que queremos desde hoy
            </Typography>
            <Typography variant="body1" color={purple && "#4D033F"}>
              Nos emociona ser parte del cambio dentro de instituciones
              responsables y justas. Déjanos tus datos y nos comunicaremos.
              <br />
              <br /> O comunícate por alguno de los canales que tenemos
              disponibles.
              <ul
                style={{ color: purple ? "#4D033F" : "#02403C", margin: "0px" }}
              >
                <li>
                  <Typography
                    sx={{ textDecoration: "none" }}
                    component={Link}
                    href={"https://wa.me/34682928253"}
                  >
                    +34 682 92 82 53
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{ textDecoration: "none" }}
                    component={Link}
                    href={"mailto:gary.martin@quantil.com.co"}
                  >
                    gary.martin@quantil.com.co
                  </Typography>
                </li>
              </ul>
            </Typography>
          </FlexColumn>
        </Grid>
        <Grid item xs={4} sm={6} xl={6}>
          <BorderCard shadow purple={purple}>
            <FlexColumn
              padding="28px"
              component="form"
              gap="28px"
              alignItems="flex-start"
            >
              <Typography variant="h5" color={purple && "#4D033F"}>
                Completa el formulario para iniciar tu prueba gratis y/o conocer
                más
              </Typography>
              <CustomTextField
                purple={purple}
                label="Nombre"
                variant="outlined"
                fullWidth
              />
              <CustomTextField
                purple={purple}
                label="Organización"
                variant="outlined"
                fullWidth
              />
              <CustomTextField
                purple={purple}
                label="Correo"
                variant="outlined"
                fullWidth
              />
              <CustomTextField
                purple={purple}
                label="Asunto"
                variant="outlined"
                fullWidth
              />
              <CustomFormControlLabel
                purple={purple}
                control={<CustomCheckbox purple={purple} />}
                label="Aceptar términos y condiciones"
              />
              <StyledButton variant="contained" purple={purple}>
                <Typography
                  variant="h5"
                  sx={{
                    lineHeight: "100%",
                    marginBottom: "4px",
                    fontWeight: "500",
                    color: "#EBFDEA",
                  }}
                  color={purple && "#4D033F"}
                >
                  Enviar
                </Typography>
              </StyledButton>
            </FlexColumn>
          </BorderCard>
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default FormSection;
