import Blog1 from "./images/Blog1.jpg";

const blogsData = [
  {
    id: 1,
    title: "¿Quien Nada Debe, Nada Teme?",
    author: "Autor",
    date: "2024-12-01",
    categories: ["Blogs", "Casos de uso", "Webinars", "Guías"],
    image: Blog1,
    summary:
      "Resumen recurso ... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac nisi pellentesque, porta lacus vitae, rhoncus odio. Nulla mattis mi turpis, in dictum diam dignissim sed. Nulla placerat semper nibh vel porttitor. Cras euismod nibh nec sem laoreet, nec sagittis ante condimentum. Curabitur dui lectus, aliquet eu mollis non, cursus a arcu. Duis a nisi viverra, consectetur velit et, vestibulum nibh. Pellentesque scelerisque elit vel odio fringilla aliquam.",
    content: [
      {
        type: "paragraph",
        text: "Aún recordamos como en la película de 1987 el policía que protagonizaba Robocop tenía un visor que le permitía identificar por medio de las caras la identidad de las personas. Pues, poco más de 20 años y de forma mucho menos intimidante, varios países del mundo ya cuentan con sistemas integrados de cámaras con reconocimiento facial que apoyan a los diferentes departamentos policiales para la detección y lucha del crimen. Aunque, lo que apenas está evolucionando es hacerles frente a las implicaciones éticas referentes a la privacidad, el mal uso de las imágenes y los potenciales sesgos que pueden ser replicados por la IA.",
      },
      {
        type: "paragraph",
        text: "Son diversas las formas como el reconocimiento facial apoya actualmente a la policía en la lucha contra el crimen. En España se desarrolló un sistema titulado ABIS (sistema automático de identificación biométrica por sus siglas en inglés) que utiliza un algoritmo llamado “Cogent Face Recognition Platform (FRP)” desarrollado por la empresa francesa THALES que permite identificar a un individuo con base en cualquier tipo de imagen que presente su rostro (El País, 2022). Del mismo modo, un informe presentado por Amnistía Internacional del 2017 al 2021 el departamento de policía de Nueva York utilizó reconocimiento facial para rastrear sospechosos en 22.000 casos, utilizando un sistema integrado de más de 15 000 cámaras (2021). Incluso, el uso de esta tecnología se reporta en Colombia. En Cúcuta, en el 2022, se desarrolló un plan piloto de reconocimiento facial que cruzaba la información de las bases de datos de detenidos de la Policía Nacional (Semana, 2022). En 2017 Envigado invirtió más de 2700 millones de pesos en cámaras con un software de reconocimiento facial y desde las 2015 ciudades como Bogotá, Cali, Cartagena y Barranquilla también cuentan con esta tecnología.",
      },
      {
        type: "paragraph",
        text: "No obstante, estos desarrollos suscitan verdaderas preguntas sobre la privacidad, la ética algorítmica y los derechos de los ciudadanos. Probablemente, el esfuerzo más canónico corresponde a la iniciativa #BanTheScan elaborada por Amnistía Internacional; organización que “después de la explosión de ciertas actuaciones racistas” en donde se utilizó cámaras con reconocimiento facial para identificar y procesar individuos en calidad de protesta durante el movimiento Black Lives Matter, estableció una campaña con más de 5.000 voluntarios y 18 000 horas de trabajo para documentar la ubicación de las cámaras con IA en Nueva York y ofrecer/demandar transparencia en el uso de esta tecnología. Pero, es importante preguntarse: ¿Por qué es importante la transparencia en estos sistemas? ¿Qué problemas puede traer una tecnología que tiene intenciones de reconocer a delincuentes? ¿Quién nada debe, nada teme?",
      },
      {
        type: "heading",
        text: "Problemas de ética en crimen",
      },
      {
        type: "paragraph",
        text: "La ética algorítmica ha ido incrementando su importancia con el creciente uso de la inteligencia artificial para la toma de decisiones. Los modelos de aprendizaje que han mostrado un mejor rendimiento técnico en los últimos años son los conocidos como «caja negra», dentro de los cuales podemos encontrar las redes neuronales profundas y los bosques aleatorios. El nombre asignado a esta categoría de modelos no es coincidencial; estos parecen producir resultados impresionantes, pero pocas personas entienden cómo lo hacen. Explicar por qué una red neuronal predijo que una imagen correspondía a un perro y no a un gato es una tarea titánica, pero posiblemente esa sea una respuesta correcta. A la hora de clasificar imágenes, la interpretabilidad del modelo no parece ser un gran problema, pero cuando se utilizan modelos para determinar si otorgar o no un crédito, para predecir si un delincuente va a reincidir, o si una persona es apta o no para un trabajo, es crucial entender el “cómo” de esa toma de decisión.",
      },
      {
        type: "paragraph",
        text: "La falta de interpretabilidad puede ocultar problemas subyacentes en los modelos, como sesgos. Si al determinar si una persona es apta o no para una vacante laboral, se le otorga un puntaje bajo a una hoja de vida simplemente porque corresponde a una mujer, se está incurriendo en sesgos. Estos sesgos provienen de los datos y, por lo tanto, de prejuicios históricos que han caracterizado a la humanidad en el pasado. Si no deseamos seguir perpetuando estos mismos prejuicios, debemos evitar que los modelos que utilicemos los reproduzcan.",
      },
      {
        type: "paragraph",
        text: "Los problemas de sesgos en los modelos de crimen se han venido estudiando desde hace un tiempo. En particular, se han detectado múltiples sesgos en modelos de reincidencia. En 2019 se llevó a cabo un estudio comparativo de distintas formas de asignar valores de probabilidad de reincidencia de jóvenes criminales en Cataluña. Comparativamente, los modelos de aprendizaje entrenados muestran un rendimiento técnico superior a la metodología utilizada anteriormente, que consistía en un formulario que los jóvenes debían completar. Sin embargo, estos modelos aún presentan discriminación por género y origen étnico. El uso exclusivo de estos modelos basados en un mejor rendimiento técnico podría dar lugar a sesgos y perjudicar a ciertos grupos poblacionales debido a características que no están relacionadas con sus comportamientos criminales. Especialmente conocida, la herramienta COMPAS ha sido utilizada en todo Estados Unidos para ayudar a los jueces a tomar decisiones sobre la fianza al predecir el riesgo de reincidencia criminal de los acusados. Diversos estudios han demostrado que esta herramienta muestra sesgos y perjudica a ciertos grupos poblacionales, principalmente a personas menores de 25 años y afroamericanas.",
      },
      {
        type: "paragraph",
        text: "Por otro lado, se sabe que los algoritmos de reconocimiento facial han mostrado, en su mayoría, disparidades de rendimiento entre personas de diferentes géneros y razas. Desde 2018, se ha estado estudiando el impacto que esto puede tener. Los algoritmos de inteligencia artificial aprenden de los datos con los que se entrenan. Si el 80% de las imágenes de entrenamiento corresponden a personas blancas, es muy probable que el algoritmo tenga un mejor rendimiento en este grupo poblacional. Los problemas de reconocimiento facial van más allá de la barrera tecnológica que podría proporcionar, por ejemplo, el reconocimiento facial en un dispositivo. Cuando estos algoritmos se combinan con políticas de seguridad, el daño puede ser enorme. En 2020, la Universidad de Harvard analizó el posible impacto en la sociedad al tener diferencias de rendimiento en los algoritmos para ciertos grupos poblacionales (Figura 1).",
      },
      {
        type: "paragraph",
        text: "“La vigilancia continua induce miedo y daño psicológico, dejando a los sujetos vulnerables a abusos dirigidos, así como daño físico, al expandir sistemas de supervisión gubernamental utilizados para negar el acceso a la atención médica y el bienestar social. En un entorno de justicia penal, las tecnologías de reconocimiento facial que son inherentemente sesgadas en su precisión pueden identificar erróneamente a sospechosos, encarcelando a afroamericanos inocentes.” (Harvard University, 2020).",
      },
      {
        type: "image",
        src: "url_de_la_imagen",
        alt: "Gráfica de precisión de tecnologías de reconocimiento facial",
      },
    ],
    slug: "Quien-Nada-Debe-Nada-Teme",
  },
  {
    id: 2,
    title: "Título del Blog 2",
    author: "Autor 2",
    date: "2024-12-02",
    categories: ["Categoría3"],
    image: Blog1,
    summary: "Breve resumen del blog.",
    content: [
      {
        type: "paragraph",
        text: "Aún recordamos como en la película de 1987 el policía que protagonizaba Robocop tenía un visor que le permitía identificar por medio de las caras la identidad de las personas. Pues, poco más de 20 años y de forma mucho menos intimidante, varios países del mundo ya cuentan con sistemas integrados de cámaras con reconocimiento facial que apoyan a los diferentes departamentos policiales para la detección y lucha del crimen. Aunque, lo que apenas está evolucionando es hacerles frente a las implicaciones éticas referentes a la privacidad, el mal uso de las imágenes y los potenciales sesgos que pueden ser replicados por la IA.",
      },
      {
        type: "paragraph",
        text: "Son diversas las formas como el reconocimiento facial apoya actualmente a la policía en la lucha contra el crimen. En España se desarrolló un sistema titulado ABIS (sistema automático de identificación biométrica por sus siglas en inglés) que utiliza un algoritmo llamado “Cogent Face Recognition Platform (FRP)” desarrollado por la empresa francesa THALES que permite identificar a un individuo con base en cualquier tipo de imagen que presente su rostro (El País, 2022). Del mismo modo, un informe presentado por Amnistía Internacional del 2017 al 2021 el departamento de policía de Nueva York utilizó reconocimiento facial para rastrear sospechosos en 22.000 casos, utilizando un sistema integrado de más de 15 000 cámaras (2021). Incluso, el uso de esta tecnología se reporta en Colombia. En Cúcuta, en el 2022, se desarrolló un plan piloto de reconocimiento facial que cruzaba la información de las bases de datos de detenidos de la Policía Nacional (Semana, 2022). En 2017 Envigado invirtió más de 2700 millones de pesos en cámaras con un software de reconocimiento facial y desde las 2015 ciudades como Bogotá, Cali, Cartagena y Barranquilla también cuentan con esta tecnología.",
      },
      {
        type: "paragraph",
        text: "No obstante, estos desarrollos suscitan verdaderas preguntas sobre la privacidad, la ética algorítmica y los derechos de los ciudadanos. Probablemente, el esfuerzo más canónico corresponde a la iniciativa #BanTheScan elaborada por Amnistía Internacional; organización que “después de la explosión de ciertas actuaciones racistas” en donde se utilizó cámaras con reconocimiento facial para identificar y procesar individuos en calidad de protesta durante el movimiento Black Lives Matter, estableció una campaña con más de 5.000 voluntarios y 18 000 horas de trabajo para documentar la ubicación de las cámaras con IA en Nueva York y ofrecer/demandar transparencia en el uso de esta tecnología. Pero, es importante preguntarse: ¿Por qué es importante la transparencia en estos sistemas? ¿Qué problemas puede traer una tecnología que tiene intenciones de reconocer a delincuentes? ¿Quién nada debe, nada teme?",
      },
      {
        type: "heading",
        text: "Problemas de ética en crimen",
      },
      {
        type: "paragraph",
        text: "La ética algorítmica ha ido incrementando su importancia con el creciente uso de la inteligencia artificial para la toma de decisiones. Los modelos de aprendizaje que han mostrado un mejor rendimiento técnico en los últimos años son los conocidos como «caja negra», dentro de los cuales podemos encontrar las redes neuronales profundas y los bosques aleatorios. El nombre asignado a esta categoría de modelos no es coincidencial; estos parecen producir resultados impresionantes, pero pocas personas entienden cómo lo hacen. Explicar por qué una red neuronal predijo que una imagen correspondía a un perro y no a un gato es una tarea titánica, pero posiblemente esa sea una respuesta correcta. A la hora de clasificar imágenes, la interpretabilidad del modelo no parece ser un gran problema, pero cuando se utilizan modelos para determinar si otorgar o no un crédito, para predecir si un delincuente va a reincidir, o si una persona es apta o no para un trabajo, es crucial entender el “cómo” de esa toma de decisión.",
      },
      {
        type: "paragraph",
        text: "La falta de interpretabilidad puede ocultar problemas subyacentes en los modelos, como sesgos. Si al determinar si una persona es apta o no para una vacante laboral, se le otorga un puntaje bajo a una hoja de vida simplemente porque corresponde a una mujer, se está incurriendo en sesgos. Estos sesgos provienen de los datos y, por lo tanto, de prejuicios históricos que han caracterizado a la humanidad en el pasado. Si no deseamos seguir perpetuando estos mismos prejuicios, debemos evitar que los modelos que utilicemos los reproduzcan.",
      },
      {
        type: "paragraph",
        text: "Los problemas de sesgos en los modelos de crimen se han venido estudiando desde hace un tiempo. En particular, se han detectado múltiples sesgos en modelos de reincidencia. En 2019 se llevó a cabo un estudio comparativo de distintas formas de asignar valores de probabilidad de reincidencia de jóvenes criminales en Cataluña. Comparativamente, los modelos de aprendizaje entrenados muestran un rendimiento técnico superior a la metodología utilizada anteriormente, que consistía en un formulario que los jóvenes debían completar. Sin embargo, estos modelos aún presentan discriminación por género y origen étnico. El uso exclusivo de estos modelos basados en un mejor rendimiento técnico podría dar lugar a sesgos y perjudicar a ciertos grupos poblacionales debido a características que no están relacionadas con sus comportamientos criminales. Especialmente conocida, la herramienta COMPAS ha sido utilizada en todo Estados Unidos para ayudar a los jueces a tomar decisiones sobre la fianza al predecir el riesgo de reincidencia criminal de los acusados. Diversos estudios han demostrado que esta herramienta muestra sesgos y perjudica a ciertos grupos poblacionales, principalmente a personas menores de 25 años y afroamericanas.",
      },
      {
        type: "paragraph",
        text: "Por otro lado, se sabe que los algoritmos de reconocimiento facial han mostrado, en su mayoría, disparidades de rendimiento entre personas de diferentes géneros y razas. Desde 2018, se ha estado estudiando el impacto que esto puede tener. Los algoritmos de inteligencia artificial aprenden de los datos con los que se entrenan. Si el 80% de las imágenes de entrenamiento corresponden a personas blancas, es muy probable que el algoritmo tenga un mejor rendimiento en este grupo poblacional. Los problemas de reconocimiento facial van más allá de la barrera tecnológica que podría proporcionar, por ejemplo, el reconocimiento facial en un dispositivo. Cuando estos algoritmos se combinan con políticas de seguridad, el daño puede ser enorme. En 2020, la Universidad de Harvard analizó el posible impacto en la sociedad al tener diferencias de rendimiento en los algoritmos para ciertos grupos poblacionales (Figura 1).",
      },
      {
        type: "paragraph",
        text: "“La vigilancia continua induce miedo y daño psicológico, dejando a los sujetos vulnerables a abusos dirigidos, así como daño físico, al expandir sistemas de supervisión gubernamental utilizados para negar el acceso a la atención médica y el bienestar social. En un entorno de justicia penal, las tecnologías de reconocimiento facial que son inherentemente sesgadas en su precisión pueden identificar erróneamente a sospechosos, encarcelando a afroamericanos inocentes.” (Harvard University, 2020).",
      },
      {
        type: "image",
        src: "url_de_la_imagen",
        alt: "Gráfica de precisión de tecnologías de reconocimiento facial",
      },
    ],
    slug: "titulo-del-blog-2",
  },
];

export default blogsData;
