import { Box } from "@mui/system";
import StyledFooter from "../../components/common/navigation/StyledFooter";
import {
  BorderCard,
  FlexColumn,
  HomeCard,
  MaxWidthContainer,
  PageContainer,
} from "../../components/shared/container/Index";
import StyledImage from "../../components/common/image/StyledImage";
import { Grid, Typography } from "@mui/material";
import StyledButton from "../../components/common/button/StyledButton";
import StyledTextDottedDivider from "../../components/common/divider/StyledTextDottedDivider";
import {
  SliderImage,
  SliderImage2,
  SliderImage3,
  MacBookPerformance,
  MacBookDetection,
  MacBookMitigation,
  SolutionVideo,
} from "../../images/Index";
import StyledDivider from "../../components/common/divider/StyledDivider";
import VideoBanner from "../../components/shared/section/VideoBanner";
import FullWidthBanner from "../../components/shared/section/FullWidthBanner";
import LogosBanner from "../../components/shared/section/LogosBanner";
import {
  IconFinancial,
  IconFinancialPurple,
} from "../../components/icons/Index";
import StyledSlider from "../../components/common/slider/StyledSlider";
import StyledTable from "../../components/common/table/StyledTable";
import { useNavigate } from "react-router-dom";

function createData(Caracteristicas, Gratuito, Pro, Premium) {
  return { Caracteristicas, Gratuito, Pro, Premium };
}

const rows = [
  createData("Modulos", "Todos", "Todos", "Todos"),
  createData("Mitigación de sesgos", "✔", "✔", "✔"),
  createData(
    "Auditorias de cumplimiento normativo",
    "-",
    "3",
    "Según necesidad"
  ),
  createData("Integraciones avanzadas", "-", "-", "✔"),
  createData("Consultoría en IA responsable", "-", "-", "✔"),
  createData(
    "Soporte",
    "Respuesta en 24 horas",
    "Respuesta en 12 horas",
    "Ejecutivo propio"
  ),
  createData("Modelos", "1", "15", "Según necesidad"),
  createData("Almacenamiento", "1 GB", "10 GB", "Según necesidad"),
];

const SolutionSaaS = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <PageContainer>
      <VideoBanner />
      <StyledTextDottedDivider>
        Conoce a detalle nuestra solución
      </StyledTextDottedDivider>
      <MaxWidthContainer>
        <FlexColumn gap="40px" alignItems="center" sx={{ margin: "40px 0px" }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "872px",
            }}
          >
            <StyledImage src={SolutionVideo} alt="Solution Video" />
          </Box>
          <StyledButton variant="outlined" secondary="true">
            <Typography
              variant="h5"
              sx={{
                lineHeight: "100%",
                marginBottom: "4px",
                fontWeight: "600",
              }}
            >
              Pruébalo
            </Typography>
          </StyledButton>
        </FlexColumn>
      </MaxWidthContainer>
      <MaxWidthContainer sx={{ marginTop: "72px", marginBottom: "80px" }}>
        <Grid container spacing={3} columns={{ xs: 4, sm: 12, xl: 12 }}>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancial sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Detección automática de sesgos 
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px" secondary>
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancialPurple sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Cumplimiento con estándares de IA responsable.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancial sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Optimización de decisiones basadas en datos justos.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px" secondary>
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancialPurple sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Escalabilidad sin complicaciones en la nube.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px" secondary>
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancialPurple sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Integración rápida con tus sistemas actuales.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancial sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Aumenta la confianza de tus clientes y usuarios.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px" secondary>
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancialPurple sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Mejora la equidad en procesos de recursos humanos.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
          <Grid item xs={4} sm={3} xl={3} sx={{ alignContent: "center" }}>
            <HomeCard padding="24px">
              <FlexColumn
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <IconFinancial sx={{ width: "80px", height: "80px" }} />
                <Typography variant="h5" textAlign="center">
                  Análisis detallado y reportes de rendimiento.
                </Typography>
              </FlexColumn>
            </HomeCard>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <FullWidthBanner />
      <StyledTextDottedDivider>Modulos</StyledTextDottedDivider>
      <MaxWidthContainer>
        <FlexColumn gap="80px" sx={{ marginBottom: "50px" }}>
          <Grid
            container
            spacing={4}
            columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
          >
            <Grid item xs={4} sm={7} xl={7}>
              <BorderCard>
                <FlexColumn gap="20px" padding="40px" alignItems="flex-start">
                  <Box sx={{ maxWidth: "206px", marginTop: "-92px" }}>
                    <StyledImage src={SliderImage} alt="SliderImage" />
                  </Box>
                  <Typography variant="h3">1. Rendimiento técnico</Typography>
                  <Typography variant="body1">
                    La primera constituye una fase de evaluación de rendimiento
                    del modelo cuyo objetivo es definir si este es adecuado para
                    realizar ajustes de justicia algorítmica y establecer
                    medidas de precisión y calibración según datos reales.
                  </Typography>
                  <Typography variant="body1">
                     A partir de las predicciones y resultados de un modelo, se
                    establecen métricas de rendimiento técnico, medidas de
                    precisión y calibración sobre el conjunto de datos
                    recibidos.
                  </Typography>
                  <Typography variant="body1">
                    Las principales (características/ventajas) del modulo son:
                    <ul style={{ color: "#02403C", margin: "0px" }}>
                      <li>
                        Determinar métricas más relevantes para modelos de
                        predicción binaria.
                      </li>
                      <li>
                        Generación de las curvas ROC, Precision-Recall y de
                        calibración, junto con sus métricas asociadas AUC,
                        AUC-PR y Brier score.
                      </li>
                      <li>
                        Analizr el cambio de las métricas binarias de Exactitud
                        (Accuracy), Sensibilidad (Recall), Precisión y F1-score
                        al modificar el umbral de la predicción.
                      </li>
                    </ul>
                  </Typography>
                  <StyledButton variant="outlined" secondary="true">
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Conoce más
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
            <Grid item xs={4} sm={5} xl={5}>
              <FlexColumn justifyContent="center" height="100%">
                <StyledImage
                  src={MacBookPerformance}
                  alt="MacBook Performance"
                />
              </FlexColumn>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
          >
            <Grid item xs={4} sm={5} xl={5}>
              <FlexColumn justifyContent="center" height="100%">
                <StyledImage src={MacBookDetection} alt="MacBook Detection" />
              </FlexColumn>
            </Grid>
            <Grid item xs={4} sm={7} xl={7}>
              <BorderCard>
                <FlexColumn gap="20px" padding="40px" alignItems="flex-start">
                  <Box sx={{ maxWidth: "206px", marginTop: "-92px" }}>
                    <StyledImage src={SliderImage2} alt="SliderImage2" />
                  </Box>
                  <Typography variant="h3">2. Detección de sesgos</Typography>
                  <Typography variant="body1">
                    La segunda es una fase de medición del nivel de justicia (o
                    injusticia) que presenta el modelo. Esto se analiza sobre
                    una serie de variables protegidas (como sexo o raza) que se
                    definen de acuerdo con el contexto del usuario.
                  </Typography>
                  <Typography variant="body1">
                    Cabe mencionar que, la segunda fase de este proceso de
                    auditoría algorítmica está basada, entre otras herramientas
                    o paquetes, en un kit de herramientas de auditoría
                    construida por el Center for Data Science and Public Policy
                    de la Universidad de Chicago denominado Aequitas en el que
                    se busca generar un consenso sobre definiciones de justicia
                    en la práctica a través de métricas de fácil implementación.
                  </Typography>
                  <Typography variant="body1">
                    Las principales (características/ventajas) del modulo son:
                    <ul style={{ color: "#02403C", margin: "0px" }}>
                      <li>
                        Utilizar distintas métricas para encontrar sesgos
                        (Equalized Odds, TPR-Parity, FPR-Parity, Demographic
                        Parity y justicia por calibración).
                      </li>
                      <li>
                        Visualización de hallazgos en gráficas comparativas
                        entre grupos poblacionales.
                      </li>
                      <li>Consolidación en reportes descargables.</li>
                    </ul>
                  </Typography>
                  <StyledButton variant="outlined" secondary="true">
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Conoce más
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
          >
            <Grid item xs={4} sm={7} xl={7}>
              <BorderCard>
                <FlexColumn gap="20px" padding="40px" alignItems="flex-start">
                  <Box sx={{ maxWidth: "206px", marginTop: "-92px" }}>
                    <StyledImage src={SliderImage3} alt="SliderImage3" />
                  </Box>
                  <Typography variant="h3">3. Mitigación de sesgos</Typography>
                  <Typography variant="body1">
                    La tercera es una fase de corrección o mitigación en la que,
                    sujeto a los resultados de la segunda fase, se propone una
                    alternativa a las predicciones originales del modelo la cual
                    permita reducir los niveles de injusticia sin afectar,
                    significativamente, el rendimiento inicial del modelo.
                  </Typography>
                  <Typography variant="body1">
                    A partir de la información suministrada en las fases
                    anteriores, se ejecutan métodos de corrección de tipo
                    post-processing.
                  </Typography>
                  <Typography variant="body1">
                    Las principales (características/ventajas) del modulo son:
                    <ul style={{ color: "#02403C", margin: "0px" }}>
                      <li>
                        Visualización del impacto de la corrección sobre el
                        modelo inicial.
                      </li>
                      <li>
                        Consolidación de la comparación de modelos en reportes
                        descargables.
                      </li>
                      <li>
                        Obtener las predicciones para un nuevo conjunto de
                        datos.
                      </li>
                    </ul>
                  </Typography>
                  <StyledButton variant="outlined" secondary="true">
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Conoce más
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
            <Grid item xs={4} sm={5} xl={5}>
              <FlexColumn justifyContent="center" height="100%">
                <StyledImage src={MacBookMitigation} alt="MacBook Mitigation" />
              </FlexColumn>
            </Grid>
          </Grid>
        </FlexColumn>
      </MaxWidthContainer>
      <StyledTextDottedDivider>Precios</StyledTextDottedDivider>
      <MaxWidthContainer>
        <FlexColumn alignItems="center">
          <Grid
            container
            spacing={2}
            columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
          >
            <Grid item xs={4} xl={4}>
              <BorderCard>
                <FlexColumn
                  padding="28px"
                  gap="20px"
                  alignItems="flex-start"
                  sx={{ height: "100%" }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    Plan Gratuito
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "600" }}>
                    $0 usd / mes
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "300" }}>
                    Ideal para PYMEs que recién comienzan a implementar IA
                    responsable y desean una solución accesible.
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "300" }}>
                    <ul style={{ color: "#02403C", margin: "0px" }}>
                      <li>
                        Incluye todos los módulos, con acceso a mitigación de
                        sesgos y auditorías avanzadas de cumplimiento normativo.
                      </li>
                      <li>1 modelo al mes o 1 GB de información.</li>
                    </ul>
                  </Typography>

                  <StyledButton
                    variant="contained"
                    onClick={() => handleNavigation("/register")}
                    sx={{ marginTop: "auto" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Registrarme
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
            <Grid item xs={4} xl={4}>
              <BorderCard>
                <FlexColumn
                  padding="28px"
                  gap="20px"
                  alignItems="flex-start"
                  sx={{ height: "100%" }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    Plan Pro
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "600" }}>
                    $199 usd / mes
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "300" }}>
                    Ideal para organizaciones medianas y grandes con requisitos
                    avanzados de IA y cumplimiento regulatorio.
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "300" }}>
                    <ul style={{ color: "#02403C", margin: "0px" }}>
                      <li>
                        Incluye todos los módulos, con acceso a mitigación de
                        sesgos y auditorías avanzadas de cumplimiento normativo.
                      </li>
                      <li>15 modelo al mes o 10 GB de información.</li>
                    </ul>
                  </Typography>

                  <StyledButton
                    variant="contained"
                    onClick={() => handleNavigation("/payment")}
                    sx={{ marginTop: "auto" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Suscribirme
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
            <Grid item xs={4} xl={4}>
              <BorderCard>
                <FlexColumn
                  padding="28px"
                  gap="20px"
                  alignItems="flex-start"
                  sx={{ height: "100%" }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    Plan Premium
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "600" }}>
                    Contactar a ventas
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "300" }}>
                    Ideal para entidades gubernamentales, grandes empresas y
                    sectores regulados (finanzas, salud, tecnología) con alta
                    demanda de IA y normativas complejas.
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "300" }}>
                    <ul style={{ color: "#02403C", margin: "0px" }}>
                      <li>
                        Todos los módulos incluidos, soporte personalizado,
                        integración avanzada y consultoría en IA responsable.
                      </li>
                      <li>Ajustable a las necesidades.</li>
                    </ul>
                  </Typography>

                  <StyledButton
                    variant="contained"
                    sx={{ marginTop: "auto" }}
                    onClick={() => {
                      const element = document.getElementById("Contact");
                      if (element) {
                        const elementPosition =
                          element.getBoundingClientRect().top + window.scrollY;
                        const offset = 40;
                        window.scrollTo({
                          top: elementPosition - offset,
                          behavior: "smooth",
                        });
                      } else {
                        document
                          .getElementById("Footer")
                          .scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "100%",
                        marginBottom: "4px",
                        fontWeight: "600",
                      }}
                    >
                      Contactarme
                    </Typography>
                  </StyledButton>
                </FlexColumn>
              </BorderCard>
            </Grid>
          </Grid>
          <StyledTable borderaxis="both" sx={{ margin: "80px 0px" }}>
            <thead>
              <tr key="Example">
                <th key="Caracteristicas">
                  <FlexColumn
                    padding="16px"
                    gap="8px"
                    justifycontent="center"
                    alignItems="flex-start"
                  >
                    <Typography variant="h5">Características</Typography>
                  </FlexColumn>
                </th>
                <th key="Gratuito">
                  <FlexColumn
                    padding="16px"
                    gap="8px"
                    justifycontent="center"
                    alignItems="center"
                  >
                    <Typography variant="h5">Gratuito</Typography>
                  </FlexColumn>
                </th>
                <th key="Pro">
                  <FlexColumn
                    padding="16px"
                    gap="8px"
                    justifycontent="center"
                    alignItems="center"
                  >
                    <Typography variant="h5">Pro</Typography>
                  </FlexColumn>
                </th>
                <th key="Premium">
                  <FlexColumn
                    padding="16px"
                    gap="8px"
                    justifycontent="center"
                    alignItems="center"
                  >
                    <Typography variant="h5">Premium</Typography>
                  </FlexColumn>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Typography variant="h6" textAlign="left" padding="16px">
                      {row.Caracteristicas}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" padding="16px">
                      {row.Gratuito}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" padding="16px">
                      {row.Pro}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" padding="16px">
                      {row.Premium}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </FlexColumn>
      </MaxWidthContainer>
      <StyledTextDottedDivider>Con el respaldo de</StyledTextDottedDivider>
      <LogosBanner />
      <StyledTextDottedDivider>Casos de uso</StyledTextDottedDivider>
      <MaxWidthContainer>
        <StyledSlider slidesToShow={1.8} showArrows={false}>
          <BorderCard padding="40px" sx={{ margin: "0px 40px" }}>
            <FlexColumn
              gap="20px"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography variant="h3">Hospital</Typography>
              <Typography variant="h5">
                Diagnóstico y tratamiento personalizado
              </Typography>
              <Typography variant="body1">
                Un hospital puede utilizar Criteria para analizar algoritmos de
                diagnóstico y planes de tratamiento, identificando posibles
                prejuicios que puedan afectar la atención a pacientes de
                diferentes géneros, razas o antecedentes socioeconómicos.
              </Typography>
              <Typography variant="body1">
                Criteria asegura que las recomendaciones médicas se basen
                únicamente en datos clínicos objetivos y no en estereotipos o
                sesgos implícitos.
              </Typography>
              <Typography variant="body1">
                Al hacerlo, el hospital reduce el riesgo de diagnósticos
                erróneos o tratamientos inadecuados, mejorando la equidad en la
                atención médica y cumpliendo con las normas éticas y de igualdad
                en salud.
              </Typography>
              <StyledButton variant="contained">
                <Typography
                  variant="h5"
                  sx={{
                    lineHeight: "100%",
                    marginBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Ver caso de uso
                </Typography>
              </StyledButton>
            </FlexColumn>
          </BorderCard>
          <BorderCard padding="40px" sx={{ margin: "0px 40px" }}>
            <FlexColumn
              gap="20px"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography variant="h3">Industria Financiera</Typography>
              <Typography variant="h5">
                Aprobación de préstamos y créditos
              </Typography>
              <Typography variant="body1">
                En el sector financiero, Criteria mejora algoritmos de
                evaluación crediticia para detectar sesgos que puedan afectar la
                aprobación de préstamos a minorías o grupos demográficos
                específicos.
              </Typography>
              <Typography variant="body1">
                Al mejorar la equidad en la toma de decisiones financieras, se
                asegura que las evaluaciones se basen en factores financieros
                reales y no en prejuicios implícitos, manteniendo una
                rentabilidad positiva para las empresas.
              </Typography>
              <Typography variant="body1">
                Esto disminuye el riesgo de litigios por discriminación y mejora
                la transparencia en los procesos crediticios, fortaleciendo la
                confianza del cliente en la institución financiera.
              </Typography>
              <StyledButton variant="contained">
                <Typography
                  variant="h5"
                  sx={{
                    lineHeight: "100%",
                    marginBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Ver caso de uso
                </Typography>
              </StyledButton>
            </FlexColumn>
          </BorderCard>
        </StyledSlider>
      </MaxWidthContainer>
      <StyledTextDottedDivider>Preguntas frecuentes</StyledTextDottedDivider>
      <MaxWidthContainer>
        <Grid
          container
          spacing={3}
          columns={{ xs: 4, sm: 12, xl: 12, alignContent: "flex-start" }}
        >
          <Grid item xs={4} sm={6} xl={6} sx={{ marginBottom: "28px" }}>
            <Box
              sx={{
                background: "#EBFDEA",
                borderRadius: "20px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <FlexColumn padding="28px 48px" gap="8px">
                <Typography variant="h5">
                  ¿Cómo detecta su software los sesgos en los modelos de IA?
                </Typography>
                <StyledDivider />
                <Typography variant="body1">
                  Utilizamos algoritmos de machine learning que analizan los
                  modelos en tiempo real para detectar patrones sesgados en los
                  datos.
                </Typography>
              </FlexColumn>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} xl={6} sx={{ marginBottom: "28px" }}>
            <Box
              sx={{
                background: "#EBFDEA",
                borderRadius: "20px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <FlexColumn padding="28px 48px" gap="8px">
                <Typography variant="h5">
                  ¿Qué tipos de sesgos puede identificar la herramienta?
                </Typography>
                <StyledDivider />
                <Typography variant="body1">
                  Nuestra solución puede identificar sesgos raciales, de género,
                  de edad y muchos otros tipos que pueden surgir en los modelos
                  de IA.
                </Typography>
              </FlexColumn>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} xl={6} sx={{ marginBottom: "28px" }}>
            <Box
              sx={{
                background: "#EBFDEA",
                borderRadius: "20px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <FlexColumn padding="28px 48px" gap="8px">
                <Typography variant="h5">
                  ¿La solución es compatible con nuestras herramientas de
                  análisis actuales?
                </Typography>
                <StyledDivider />
                <Typography variant="body1">
                  Sí, nuestra plataforma se integra fácilmente con las
                  principales herramientas como Python, R, AWS, Azure y Google
                  Cloud.
                </Typography>
              </FlexColumn>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} xl={6} sx={{ marginBottom: "28px" }}>
            <Box
              sx={{
                background: "#EBFDEA",
                borderRadius: "20px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <FlexColumn padding="28px 48px" gap="8px">
                <Typography variant="h5">
                  ¿Qué pasa si mi equipo necesita asistencia técnica?
                </Typography>
                <StyledDivider />
                <Typography variant="body1">
                  Ofrecemos soporte técnico 24/7 en nuestro plan empresarial y
                  soporte prioritario en el plan Pro.
                </Typography>
              </FlexColumn>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} xl={6} sx={{ marginBottom: "28px" }}>
            <Box
              sx={{
                background: "#EBFDEA",
                borderRadius: "20px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <FlexColumn padding="28px 48px" gap="8px">
                <Typography variant="h5">
                  ¿Cuál es el proceso de mitigación de sesgos?
                </Typography>
                <StyledDivider />
                <Typography variant="body1">
                  Utilizamos varias técnicas, incluyendo el reequilibrio de
                  datos y la modificación de las variables en los modelos para
                  reducir los sesgos sin perder precisión.
                </Typography>
              </FlexColumn>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6} xl={6} sx={{ marginBottom: "28px" }}>
            <Box
              sx={{
                background: "#EBFDEA",
                borderRadius: "20px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <FlexColumn padding="28px 48px" gap="8px">
                <Typography variant="h5">
                  ¿Puedo ver un informe de auditoría después de corregir el
                  sesgo?
                </Typography>
                <StyledDivider />
                <Typography variant="body1">
                  Sí, nuestro módulo de auditoría genera informes detallados de
                  cumplimiento y rendimiento para que puedas monitorear los
                  resultados.
                </Typography>
              </FlexColumn>
            </Box>
          </Grid>
        </Grid>
      </MaxWidthContainer>
      <StyledFooter />
    </PageContainer>
  );
};

export default SolutionSaaS;
