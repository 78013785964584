import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  width: "16px",
  height: "16px",
  marginRight: "8px",
  marginBottom: "12px",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.secondary.main,
  },
}));

const StyledTooltip = ({ tooltipText, onClick }) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <span>
        <StyledIconButton onClick={onClick}>
          <HelpOutlineIcon sx={{ height: "16px", width: "16px" }} />
        </StyledIconButton>
      </span>
    </Tooltip>
  );
};

export default StyledTooltip;
