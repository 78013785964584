import React, { useContext, useState, useEffect } from "react";
import { useApiClient } from "../../../api/apiService";
import { toast } from "react-toastify";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Typography, styled } from "@mui/material";
import StyledButton from "../../common/button/StyledButton";
import { EchartsContext } from "../../../context/EchartsContext";
import { DataContext } from "../../../context/DataContext";
import { LogoPDF } from "../../../images/Index";
import styles from "./PdfStyles";
import { useAuth } from "../../../context/AuthProvider";

const CustomPDFDownloadLink = styled(PDFDownloadLink)(
  ({ theme, ...props }) => ({
    color: theme.palette.primary[50],
    textDecoration: "none",
    fontWeight: "500",
  })
);

const MyDocument = ({ chartImages, texts, metrics, userName, actualData }) => {
  const distribucionArray =
    texts && texts.distribucion && Array.isArray(texts.distribucion[1])
      ? texts.distribucion[1]
      : null;

  const formatBulletPoint = (text) => {
    if (text.includes(":")) {
      const [boldPart, normalPart] = text.split(":");
      return (
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={styles.bulletPoint}>•{boldPart}:</Text>
          <Text style={styles.bulletText}>{normalPart}</Text>
        </View>
      );
    } else {
      return <Text style={styles.bulletText}>{text}</Text>;
    }
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.boxRow} wrap={false}>
          <View style={styles.boxColumn}>
            <Text style={styles.title}>Reporte de Rendimiento Técnico</Text>
            <View style={styles.boxRow} wrap={false}>
              <Text style={styles.subtitle}>Fecha:</Text>
              <Text style={styles.greentext}>
                {new Date().toISOString().split("T")[0]}
              </Text>
              <Text style={styles.greentext}>-</Text>
              <Text style={styles.subtitle}>Usuario:</Text>
              <Text style={styles.greentext}>{userName}</Text>
            </View>
          </View>
          <Image style={styles.image} src={LogoPDF} />
        </View>
        <Text style={styles.text}>
          El presente documento resume los hallazgos sobre el rendimiento
          técnico del modelo {actualData.name} cargado a la plataforma en la
          fecha {new Date().toISOString().split("T")[0]}. En la primera sección
          se analizan las distribuciones de los datos y se dan algunas
          recomendaciones sobre la base ingresada. En la segunda sección se
          presenta un resumen del rendimiento técnico y conclusiones finales.
        </Text>
        <Text style={styles.title}>Distribuciones</Text>
        <Text style={styles.text}>{texts && texts.distribucion[0]}</Text>

        <View style={styles.boxRow} wrap={false}>
          <View style={styles.graphBox}>
            <Text style={styles.greentext}>
              Figura 1: Distribución de predicciones
            </Text>
            {chartImages && chartImages["pieChart"] && (
              <Image
                style={{ ...styles.graph, height: "140px" }}
                src={chartImages["pieChart"]}
              />
            )}
          </View>
          <View style={styles.graphBox}>
            <Text style={styles.greentext}>
              Figura 2: Distribución de etiquetas
            </Text>
            {chartImages && chartImages["barChart"] && (
              <Image
                style={{ ...styles.graph, height: "200px" }}
                src={chartImages["barChart"]}
              />
            )}
          </View>
        </View>
        {distribucionArray && (
          <>
            <Text style={styles.text}>{distribucionArray[0]}</Text>
            {distribucionArray.slice(1).map((item, index) => (
              <>{formatBulletPoint(item)}</>
            ))}
          </>
        )}

        {chartImages["barVariableCharts"] &&
          chunkArray(chartImages["barVariableCharts"], 4).map(
            (chunk, chunkIndex) => (
              <View
                style={{
                  ...styles.graphBoxDinamic,
                  justifyContent: "flex-start",
                }}
                wrap={false}
              >
                {chunkIndex === 0 && (
                  <Text style={styles.greentext}>
                    Figura 3: Distribución de categorías de variables protegidas
                  </Text>
                )}
                <View style={styles.boxRow} key={chunkIndex}>
                  {chunk.map((imgData, index) => (
                    <View
                      style={{ ...styles.graphBoxHalfWidth, width: "25%" }}
                      key={index}
                    >
                      {imgData && <Image style={styles.graph} src={imgData} />}
                    </View>
                  ))}
                </View>
              </View>
            )
          )}
        <Text style={styles.title}>Rendimiento técnico</Text>
        <Text style={styles.text}>{texts && texts.rendimiento_tecnico[0]}</Text>
        <Text style={styles.text}>{texts && texts.rendimiento_tecnico[1]}</Text>
        <Text style={styles.text}>{texts && texts.rendimiento_tecnico[2]}</Text>
        <View style={styles.boxRow} wrap={false}>
          {chartImages["variableCharts"] &&
            chartImages["variableCharts"].map((imgData, index) => (
              <View style={styles.graphBox} key={index}>
                {imgData && (
                  <Image
                    style={{ ...styles.graph, height: "180px" }}
                    src={imgData}
                  />
                )}
              </View>
            ))}
        </View>
        <Text style={styles.title}>Métricas binarias</Text>
        <Text style={styles.text}>{texts && texts.metricas_binarias}</Text>
        {metrics && (
          <View style={styles.boxRow} wrap={false}>
            <View
              style={{
                ...styles.metricBox,
                backgroundColor: "#4DB9A8",
                border: "1px solid #809F9E",
                borderRadius: "5px",
                width: "25%",
              }}
            >
              <Text style={styles.metricText}>Accuracy</Text>
              <Text style={styles.metricText}>{metrics.Accuracy}</Text>
            </View>
            <View
              style={{
                ...styles.metricBox,
                backgroundColor: "#4DB9A8",
                border: "1px solid #A6819F",
                borderRadius: "5px",
                width: "25%",
              }}
            >
              <Text style={styles.metricText}>Recall</Text>
              <Text style={styles.metricText}>{metrics.Recall}</Text>
            </View>
            <View
              style={{
                ...styles.metricBox,
                backgroundColor: "#FF656D",
                border: "1px solid #FFCDA1",
                borderRadius: "5px",
                width: "25%",
              }}
            >
              <Text style={styles.metricText}>Precisión</Text>
              <Text style={styles.metricText}>{metrics.Precision}</Text>
            </View>
            <View
              style={{
                ...styles.metricBox,
                backgroundColor: "#FCC465",
                border: "1px solid #9FC7D0",
                borderRadius: "5px",
                width: "25%",
              }}
            >
              <Text style={styles.metricText}>F1</Text>
              <Text style={styles.metricText}>{metrics.F1Score}</Text>
            </View>
          </View>
        )}

        <View style={{ ...styles.graphBox, height: "280px" }} wrap={false}>
          <Text style={styles.greentext}>
            Figura 6: Valor de métricas binarias para distintos umbrales
          </Text>
          {chartImages && chartImages["completeDataChart"] && (
            <Image
              style={styles.graph}
              src={chartImages["completeDataChart"]}
            />
          )}
        </View>

        <Text style={styles.title}>Recomendaciones y conclusiones</Text>
        <Text style={styles.text}>{texts && texts.conclusion}</Text>
      </Page>
    </Document>
  );
};

const PerformanceReport = ({ metrics, loading }) => {
  const { post } = useApiClient();
  const { eCharts } = useContext(EchartsContext);
  const { actualData } = useContext(DataContext);
  const { userName } = useAuth();
  const [texts, setTexts] = useState(null);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  const getTexts = async (actualData) => {
    const body = {
      dataframe: actualData.original_dataframe,
      optimal_threshold: actualData.optimal_threshold,
    };
    try {
      const response = await post(
        "generate_technical_performance_report/",
        body
      );
      setTexts(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.msg}`);
      } else {
        toast.error(
          "Error: Ocurrió un error al cargar la información de las graficas."
        );
      }
    }
  };

  useEffect(() => {
    if (actualData) {
      getTexts(actualData);
    }
  }, [actualData, metrics]);

  const handlePdfStatus = (loading) => {
    setPdfGenerating(loading);
  };

  return (
    <StyledButton variant="contained" disabled={loading || pdfGenerating}>
      <Typography variant="body1">
        <CustomPDFDownloadLink
          document={
            <MyDocument
              chartImages={eCharts}
              texts={texts}
              metrics={metrics}
              userName={userName}
              actualData={actualData}
            />
          }
          fileName={"Rendimiento_tecnico_" + actualData.name + ".pdf"}
        >
          {({ blob, url, loading: pdfLoading, error }) => {
            handlePdfStatus(pdfLoading);
            return pdfLoading || loading
              ? "Generando documento..."
              : "Descargar reporte rendimiento técnico";
          }}
        </CustomPDFDownloadLink>
      </Typography>
    </StyledButton>
  );
};

export default PerformanceReport;
