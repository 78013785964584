import { createTheme } from "@mui/material/styles";

// Definir los colores del tema
const theme = createTheme({
  palette: {
    primary: {
      main: "#02403C",
      50: "#EBFDEA",
      contrastText: "#4D033F",
      100: "#FFF5FC",
    },
    secondary: {
      main: "#FF9B43",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#000000",
    },
  },
  typography: {
    fontFamily: "Atyp Display, Arial, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "62px",
      lineHeight: "62px",
      color: "#02403C",
    },
    h2: {
      fontWeight: 400,
      fontSize: "32px",
      lineHeight: "150%",
      letterSpacing: "14px",
      color: "#02403C",
    },
    h3: {
      fontWeight: 600,
      fontSize: "40px",
      lineHeight: "40px",
      color: "#02403C",
    },
    h4: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "150%",
      color: "#02403C",
    },
    h5: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "150%",
      color: "#02403C",
    },
    h6: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "150%",
      color: "#02403C",
    },
    body1: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "150%",
      color: "black", // Color del texto de los párrafos
    },
    body2: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "150%",
      color: "black", // Color del texto de los párrafos
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
            margin: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#02403C",
            borderRadius: "63px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#EBFDEA",
          },
          "& .MuiPickersDay-root": {
            color: "#02403C !important",
          },
          "& .MuiPickersYear-yearButton": {
            color: "#02403C !important",
          },
          "& .MuiPickersDay-root.Mui-selected": {
            backgroundColor: "#02403C !important",
            color: "#FFF !important",
          },
          "& .MuiPickersDay-root:hover": {
            backgroundColor: "#E0F2F1 !important",
          },
          "& .MuiPickersYear-yearButton.Mui-selected": {
            backgroundColor: "#02403C !important",
            color: "#FFF !important",
          },
          "& .MuiPickersYear-yearButton:hover": {
            color: "#02403C !important",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "& .link": {
            color: "#02403C",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
    },
  },
});

export default theme;
