import React, { createContext, useState } from "react";

export const EchartsContext = createContext();

export const EchartsProvider = ({ children }) => {
  const [eCharts, setECharts] = useState({});

  const addChart = (key, echart) => {
    setECharts((prevData) => ({
      ...prevData,
      [key]: echart,
    }));
  };

  const deleteChart = (key) => {
    setECharts((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[key];
      return updatedData;
    });
  };

  return (
    <EchartsContext.Provider
      value={{
        eCharts,
        addChart,
        deleteChart,
      }}
    >
      {children}
    </EchartsContext.Provider>
  );
};
