import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({
  height: "12px",
  width: "12px",
}));

const IconInfo = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
      >
        <path
          d="M10.4482 5.05444C10.4482 7.81648 8.20947 10.0544 5.44824 10.0544C2.68701 10.0544 0.448242 7.81648 0.448242 5.05444C0.448242 2.29402 2.68701 0.0544434 5.44824 0.0544434C8.20947 0.0544434 10.4482 2.29402 10.4482 5.05444ZM5.58242 1.70767C4.48369 1.70767 3.78292 2.17051 3.23264 2.99311C3.16135 3.09969 3.1852 3.24342 3.28738 3.32089L3.98695 3.85134C4.09189 3.93092 4.24141 3.91198 4.32294 3.80856C4.6831 3.35174 4.93006 3.08684 5.47824 3.08684C5.89012 3.08684 6.39957 3.35192 6.39957 3.75132C6.39957 4.05325 6.15032 4.20831 5.74365 4.43632C5.26937 4.70219 4.64179 5.03309 4.64179 5.86089V5.94154C4.64179 6.07515 4.75012 6.18348 4.88373 6.18348H6.01276C6.14637 6.18348 6.25469 6.07515 6.25469 5.94154V5.91466C6.25469 5.34083 7.93183 5.31694 7.93183 3.76412C7.93183 2.59473 6.71883 1.70767 5.58242 1.70767ZM5.44824 6.70767C4.93685 6.70767 4.52082 7.1237 4.52082 7.63509C4.52082 8.14646 4.93685 8.56251 5.44824 8.56251C5.95963 8.56251 6.37566 8.14646 6.37566 7.63509C6.37566 7.1237 5.95963 6.70767 5.44824 6.70767Z"
          fill="#4D033F"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconInfo;
