import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, Typography } from "@mui/material";
import IconGlossaryItem from "../../icons/IconGlossaryItem";
import BorderCard from "../../shared/container/BorderCard";

const CustomAccordion = styled(Accordion)(({ theme, ...props }) => ({
  boxShadow: "none",
}));

const StyledAccordion = (props) => {
  const { title, details, image, details2, image2, ...otherProps } = props;
  return (
    <BorderCard padding="8px">
      <CustomAccordion {...otherProps}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: "#02403C" }} fontSize="large" />
          }
          aria-controls="panel-content"
          id="panel-header"
        >
          <IconGlossaryItem
            sx={{ width: "40px", height: "40px", marginRight: "16px" }}
          />
          <Typography variant="h4">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">{details}</Typography>
          {image && <img src={image} alt={title} />}
          {details2 && <Typography variant="body1">{details2}</Typography>}
          {image2 && <img src={image2} alt={title} />}
        </AccordionDetails>
      </CustomAccordion>
    </BorderCard>
  );
};

export default StyledAccordion;
