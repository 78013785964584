import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({
  height: "10px",
  width: "16px",
}));

const IconView = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
      >
        <path
          d="M15.2902 5.38396C13.878 2.62849 11.0822 0.76416 7.88086 0.76416C4.67955 0.76416 1.88293 2.62979 0.47147 5.38422C0.411898 5.50206 0.380859 5.63226 0.380859 5.7643C0.380859 5.89634 0.411898 6.02654 0.47147 6.14438C1.88371 8.89985 4.67955 10.7642 7.88086 10.7642C11.0822 10.7642 13.8788 8.89855 15.2902 6.14412C15.3498 6.02628 15.3809 5.89608 15.3809 5.76404C15.3809 5.632 15.3498 5.5018 15.2902 5.38396ZM7.88086 9.51418C7.13918 9.51418 6.41415 9.29424 5.79747 8.88219C5.18078 8.47013 4.70013 7.88446 4.4163 7.19924C4.13248 6.51401 4.05821 5.76001 4.20291 5.03258C4.3476 4.30515 4.70476 3.63696 5.2292 3.11251C5.75365 2.58807 6.42184 2.23091 7.14927 2.08622C7.8767 1.94152 8.6307 2.01579 9.31592 2.29961C10.0011 2.58344 10.5868 3.06409 10.9989 3.68078C11.4109 4.29746 11.6309 5.02249 11.6309 5.76417C11.6311 6.2567 11.5343 6.74444 11.3459 7.19952C11.1575 7.6546 10.8813 8.06809 10.533 8.41636C10.1848 8.76463 9.77129 9.04084 9.31621 9.22921C8.86113 9.41758 8.37338 9.51442 7.88086 9.51418ZM7.88086 3.26416C7.65772 3.26728 7.43601 3.30048 7.22174 3.36286C7.39836 3.60288 7.48312 3.89825 7.46064 4.1954C7.43816 4.49255 7.30993 4.77181 7.09922 4.98253C6.8885 5.19325 6.60924 5.32147 6.31209 5.34395C6.01494 5.36643 5.71957 5.28167 5.47955 5.10505C5.34288 5.60859 5.36755 6.14232 5.5501 6.6311C5.73264 7.11988 6.06387 7.53911 6.49716 7.82979C6.93045 8.12046 7.44399 8.26794 7.96549 8.25147C8.48699 8.23499 8.99019 8.05539 9.40427 7.73795C9.81835 7.42051 10.1225 6.98121 10.2738 6.48188C10.4251 5.98255 10.4161 5.44833 10.2479 4.95442C10.0797 4.46051 9.76085 4.03178 9.33624 3.72857C8.91162 3.42536 8.40262 3.26294 7.88086 3.26416Z"
          fill="#02403C"
          fillOpacity="0.5"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconView;
