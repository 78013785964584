import React from "react";
import { Paper } from "@mui/material";
import { styled } from "@mui/system";

const CustomPaper = styled(Paper)(({ theme, ...props }) => ({
  height: "100%",
  width: props.width,
  display: props.display,
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 5px 10px #02403C50",
  borderRadius: "10px",
  padding: props.padding,
}));

const ShadowCard = (props) => {
  return <CustomPaper {...props}>{props.children}</CustomPaper>;
};

export default ShadowCard;
