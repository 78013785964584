import { Box } from "@mui/system";
import { FlexRow, MaxWidthContainer } from "../container/Index";
import StyledImage from "../../common/image/StyledImage";
import { quantil, BIDLAB, LogofAIr } from "../../../images/Index";

const LogosBanner = () => {
  return (
    <MaxWidthContainer>
      <FlexRow
        padding="32px"
        gap="72px"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: "linear-gradient(345deg, #4D033F 13.85%, #02403C 64.31%)",
          borderRadius: "20px",
        }}
      >
        <Box sx={{ width: "15%" }}>
          <StyledImage src={quantil} alt="Logo quantil" />
        </Box>
        <Box sx={{ width: "15%" }}>
          <StyledImage src={BIDLAB} alt="Logo BID LAB" />
        </Box>
        <Box sx={{ width: "15%" }}>
          <StyledImage src={LogofAIr} alt="Logo fAIr" />
        </Box>
      </FlexRow>
    </MaxWidthContainer>
  );
};

export default LogosBanner;
