import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StyledArrow from "./StyledArrow";
import "./styles.css"; // Asegúrate de importar el archivo CSS donde está definida la clase .styled-slider

const StyledSlider = ({ slidesToShow, showArrows, children }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const slideRefs = useRef([]);

  useEffect(() => {
    const heights = slideRefs.current.map((ref) => ref?.clientHeight || 0);
    setMaxHeight(Math.max(...heights));
  }, [children]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow || 1.3,
    centerMode: false,
    centerPadding: "24px",
    nextArrow: showArrows ? <StyledArrow /> : null,
    prevArrow: showArrows ? <StyledArrow prev="true" /> : null,
  };

  return (
    <div className="styled-slider">
      <Slider {...settings}>
        {React.Children.map(children, (child, index) => (
          <div
            className="slide"
            ref={(el) => (slideRefs.current[index] = el)}
            style={{ height: maxHeight }}
          >
            {child}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default StyledSlider;
