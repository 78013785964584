import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme, src, ...props }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  borderRadius: "15px",
  width: "100%",
  height: "186px !important",
}));

const StyledSliderImage = (props) => {
  return <CustomBox {...props} />;
};

export default StyledSliderImage;
