import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1440px",
  margin: "0 auto",
  padding: "0px 20px",
}));

const MaxWidthContainer = (props) => {
  return <CustomContainer {...props}>{props.children}</CustomContainer>;
};

export default MaxWidthContainer;
