import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import StyledNavbar from "./components/common/navigation/StyledNavbar";
import {
  Dashboard,
  LoadData,
  DataView,
  Detection,
  Performance,
  Mitigation,
  Glossary,
  Prediction,
} from "./pages/dashboard";
import {
  AboutUs,
  Homepage,
  Resources,
  SolutionSaaS,
  BlogEntry,
} from "./pages/homepage";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { AuthProvider } from "./context/AuthProvider";
import { DataProvider } from "./context/DataContext";
import { EchartsProvider } from "./context/EchartsContext";
import theme from "./theme/BaseTheme";
import StyledAppBar from "./components/common/navigation/StyledAppBar";
import { Login, Register } from "./pages/sesion";
import { AxiosProvider } from "./context/AxiosProvider";
import PrivateRoute from "./components/shared/router/PrivateRoute";
import { ToastContainer } from "react-toastify";
import { BlogProvider } from "./context/BlogContext";
import PrivacyPolicy from "./pages/homepage/PrivacyPolicy";
import TermsAndConditions from "./pages/homepage/TermsAndConditions";
import Payment from "./pages/subscription/Payment";
import StyledAppBarDashboard from "./components/common/navigation/StyledAppBarDashboard";

function App() {
  return (
    <Router>
      <ToastContainer />
      <React.StrictMode>
        <AuthProvider>
          <AxiosProvider>
            <DataProvider>
              <EchartsProvider>
                <BlogProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                      <Route
                        path="/login"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <Login />
                          </Box>
                        }
                      />
                      <Route
                        path="/register"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <Register />
                          </Box>
                        }
                      />
                      <Route
                        path="/"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <Homepage />
                          </Box>
                        }
                      />
                      <Route
                        path="/about-us"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <AboutUs />
                          </Box>
                        }
                      />
                      <Route
                        path="/solution-saas"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <SolutionSaaS />
                          </Box>
                        }
                      />
                      <Route
                        path="/resources"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <Resources />
                          </Box>
                        }
                      />
                      <Route
                        path="/privacy-policy"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <PrivacyPolicy />
                          </Box>
                        }
                      />
                      <Route
                        path="/terms-and-conditions"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <TermsAndConditions />
                          </Box>
                        }
                      />
                      <Route
                        path="/blogs/:slug"
                        element={
                          <Box sx={{ display: "flex" }}>
                            <StyledAppBar />
                            <BlogEntry />
                          </Box>
                        }
                      />
                      <Route
                        path="/payment"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledAppBarDashboard />
                              <Payment />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <Dashboard />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/load-data"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <LoadData />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/:id/data-view"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <DataView />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/:id/performance"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <Performance />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/:id/detection"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <Detection />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/:id/mitigation"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <Mitigation />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/:id/prediction"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <Prediction />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/dashboard/glossary"
                        element={
                          <PrivateRoute>
                            <Box sx={{ display: "flex" }}>
                              <StyledNavbar />
                              <Glossary />
                            </Box>
                          </PrivateRoute>
                        }
                      />
                    </Routes>
                  </ThemeProvider>
                </BlogProvider>
              </EchartsProvider>
            </DataProvider>
          </AxiosProvider>
        </AuthProvider>
      </React.StrictMode>
    </Router>
  );
}

export default App;
