import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconStack = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="31"
        viewBox="0 0 26 31"
        fill="none"
      >
        <path
          d="M25.8529 5.0157V7.62514C25.8529 9.92473 20.1257 11.8003 13.0666 11.8003C6.00745 11.8003 0.280273 9.92473 0.280273 7.62514V5.0157C0.280273 2.71611 6.00745 0.840576 13.0666 0.840576C20.1257 0.840576 25.8529 2.71611 25.8529 5.0157ZM25.8529 10.887V16.7582C25.8529 19.0578 20.1257 20.9333 13.0666 20.9333C6.00745 20.9333 0.280273 19.0578 0.280273 16.7582V10.887C3.02733 12.7788 8.05526 13.6595 13.0666 13.6595C18.0779 13.6595 23.1058 12.7788 25.8529 10.887ZM25.8529 20.02V25.8913C25.8529 28.1909 20.1257 30.0664 13.0666 30.0664C6.00745 30.0664 0.280273 28.1909 0.280273 25.8913V20.02C3.02733 21.9119 8.05526 22.7926 13.0666 22.7926C18.0779 22.7926 23.1058 21.9119 25.8529 20.02Z"
          fill="currentColor"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconStack;
