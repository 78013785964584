import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconBalance = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
      >
        <path
          d="M11.9706 15.5582H11.9697C11.9697 14.8193 12.0309 15.1595 8.08585 7.26944C7.27986 5.65791 4.97193 5.65471 4.16457 7.26944C0.186203 15.2271 0.281187 14.8426 0.281187 15.5582H0.280273C0.280273 17.5757 2.89736 19.2114 6.12544 19.2114C9.35352 19.2114 11.9706 17.5757 11.9706 15.5582ZM6.12544 8.2517L9.41335 14.8275H2.83753L6.12544 8.2517ZM29.5052 15.5582C29.5052 14.8193 29.5664 15.1595 25.6213 7.26944C24.8154 5.65791 22.5074 5.65471 21.7001 7.26944C17.7217 15.2271 17.8167 14.8426 17.8167 15.5582H17.8158C17.8158 17.5757 20.4329 19.2114 23.6609 19.2114C26.889 19.2114 29.5061 17.5757 29.5061 15.5582H29.5052ZM20.373 14.8275L23.6609 8.2517L26.9488 14.8275H20.373ZM24.3916 20.6727H16.3545V7.21282C17.4281 6.74292 18.2341 5.77527 18.4729 4.59847H24.3916C24.7953 4.59847 25.1222 4.27151 25.1222 3.86783V2.40654C25.1222 2.00286 24.7953 1.67589 24.3916 1.67589H17.7993C17.1326 0.793636 16.0846 0.2146 14.8932 0.2146C13.7018 0.2146 12.6538 0.793636 11.987 1.67589H5.39479C4.99111 1.67589 4.66415 2.00286 4.66415 2.40654V3.86783C4.66415 4.27151 4.99111 4.59847 5.39479 4.59847H11.3135C11.5523 5.77481 12.3578 6.74292 13.4319 7.21282V20.6727H5.39479C4.99111 20.6727 4.66415 20.9996 4.66415 21.4033V22.8646C4.66415 23.2683 4.99111 23.5953 5.39479 23.5953H24.3916C24.7953 23.5953 25.1222 23.2683 25.1222 22.8646V21.4033C25.1222 20.9996 24.7953 20.6727 24.3916 20.6727Z"
          fill="currentColor"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconBalance;
