import { createContext, useContext, useState, useEffect } from "react";
import blogsData from "../assets/Blogs/Blogs";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogsData);
  }, []);

  return (
    <BlogContext.Provider value={{ blogs }}>{children}</BlogContext.Provider>
  );
};

export const useBlogs = () => useContext(BlogContext);
