import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconSchema = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
      >
        <path
          d="M17.8158 15.2081H11.9706C11.1637 15.2081 10.5093 15.8625 10.5093 16.6694V22.5146C10.5093 23.3215 11.1637 23.9759 11.9706 23.9759H17.8158C18.6227 23.9759 19.2771 23.3215 19.2771 22.5146V16.6694C19.2771 15.8625 18.6227 15.2081 17.8158 15.2081ZM9.04802 2.05651C9.04802 1.2496 8.39364 0.595215 7.58673 0.595215H1.74156C0.934658 0.595215 0.280273 1.2496 0.280273 2.05651V7.90167C0.280273 8.70858 0.934658 9.36296 1.74156 9.36296H6.11265L9.45353 15.21C9.96042 14.3396 10.8929 13.7468 11.9706 13.7468H11.9834L9.04802 8.60994V6.44038H19.2771V3.5178H9.04802V2.05651ZM28.0448 0.595215H22.1996C21.3927 0.595215 20.7384 1.2496 20.7384 2.05651V7.90167C20.7384 8.70858 21.3927 9.36296 22.1996 9.36296H28.0448C28.8517 9.36296 29.5061 8.70858 29.5061 7.90167V2.05651C29.5061 1.2496 28.8517 0.595215 28.0448 0.595215Z"
          fill="currentColor"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconSchema;
