// contexts/AxiosProvider.js
import React, { createContext, useContext, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./AuthProvider";

const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
  const { accessToken, refreshAccessToken } = useAuth();

  // Crear una instancia de axios
  let api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  // Configurar el interceptor de request para añadir el token de acceso
  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use(
      async (config) => {
        if (localStorage.getItem("access_token")) {
          config.headers.Authorization = `Bearer ${localStorage.getItem(
            "access_token"
          )}`;
        } else {
          // Refrescar el token si no hay un accessToken
          await refreshAccessToken();
          config.headers.Authorization = `Bearer ${localStorage.getItem(
            "access_token"
          )}`;
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    // Efecto de limpieza para remover el interceptor cuando el componente se desmonte
    return () => {
      api.interceptors.request.eject(requestInterceptor);
    };
  }, [accessToken, refreshAccessToken, api]);

  return <AxiosContext.Provider value={api}>{children}</AxiosContext.Provider>;
};

export const useAxios = () => useContext(AxiosContext);
