import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme, ...props }) => ({
  transition: "transform 0.3s",
  transform: props.toogle === "true" ? "rotate(180deg)" : "",
}));

const IconDoubleArrow = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="21"
        viewBox="0 0 26 21"
        fill="none"
      >
        <path
          d="M12.796 11.8687L4.2661 20.3985C3.67654 20.9881 2.7232 20.9881 2.13991 20.3985L0.722446 18.981C0.132883 18.3915 0.132883 17.4381 0.722446 16.8549L6.76861 10.8087L0.722446 4.76253C0.132883 4.17297 0.132883 3.21963 0.722446 2.63634L2.13364 1.20633C2.7232 0.616769 3.67654 0.616769 4.25983 1.20633L12.7897 9.73619C13.3855 10.3258 13.3855 11.2791 12.796 11.8687ZM24.8381 9.73619L16.3082 1.20633C15.7187 0.616769 14.7653 0.616769 14.1821 1.20633L12.7646 2.62379C12.175 3.21336 12.175 4.16669 12.7646 4.74999L18.8108 10.7961L12.7646 16.8423C12.175 17.4319 12.175 18.3852 12.7646 18.9685L14.1821 20.386C14.7716 20.9755 15.725 20.9755 16.3082 20.386L24.8381 11.8561C25.4277 11.2791 25.4277 10.3258 24.8381 9.73619Z"
          fill="currentColor"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconDoubleArrow;
