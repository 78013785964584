import React from "react";
import { Dialog } from "@mui/material";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "20px",
    maxWidth: "80%",
  },
  "& .MuiTypography-root": {
    color: theme.palette.primary.main,
  },
}));

const StyledModal = ({ open, onClose, children }) => {
  return (
    <StyledDialog open={open} onClose={() => onClose()}>
      {children}
    </StyledDialog>
  );
};

export default StyledModal;
