import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme, secondary, ...props }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "60px",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "48px",
  marginBottom: "80px",
}));

const CustomDivider = styled(Box)(({ theme, secondary, purple, ...props }) => ({
  borderTop: secondary
    ? "2px dashed " + theme.palette.primary[50]
    : purple
    ? "2px dashed #4D033F"
    : "2px dashed " + theme.palette.primary.main,
  height: "2px",
  width: "100%",
}));

const StyledTextDottedDivider = (props) => {
  const { secondary, children, purple } = props;

  return (
    <CustomBox {...props}>
      <CustomDivider secondary={secondary} purple={purple} />
      <Typography
        variant="h5"
        sx={{
          textWrap: "nowrap",
          color: secondary ? "#EBFDEA" : purple && "#4D033F",
        }}
      >
        {children}
      </Typography>
      <CustomDivider secondary={secondary} purple={purple} />
    </CustomBox>
  );
};

export default StyledTextDottedDivider;
