import { FlexColumn, MaxWidthContainer } from "../container/Index";
import { MacBook } from "../../../images/Index";
import { Grid, Typography } from "@mui/material";
import StyledButton from "../../common/button/StyledButton";
import StyledImage from "../../common/image/StyledImage";

const VideoBanner = () => {
  return (
    <MaxWidthContainer>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "72px", marginBottom: "112px" }}
        columns={{ xs: 4, sm: 12, xl: 12 }}
      >
        <Grid item xs={4} sm={5} xl={5} sx={{ alignContent: "center" }}>
          <StyledImage src={MacBook} alt="MacBook" />
        </Grid>
        <Grid item xs={4} sm={7} xl={7}>
          <FlexColumn
            gap="28px"
            alignItems="flex-start"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h2">SOLUCIÓN</Typography>
            <Typography variant="h1" sx={{ fontSize: "50px" }}>
              Software en la Nube para Mitigación de Sesgos en IA
            </Typography>
            <Typography variant="body1">
              Criteria es una solución en la nube que permite a empresas y
              gobierno identificar y corregir sesgos en sus modelos de
              inteligencia artificial. Al aprovechar la infraestructura en la
              nube, ofrecemos una integración fluida y escalable asegurando que
              tus modelos de IA sean justos, responsables y cumplan con las
              estándares más exigentes.
            </Typography>

            <StyledButton variant="outlined" secondary="true">
              <Typography
                variant="h5"
                sx={{
                  lineHeight: "100%",
                  marginBottom: "4px",
                  fontWeight: "600",
                }}
              >
                Saber más
              </Typography>
            </StyledButton>
          </FlexColumn>
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default VideoBanner;
