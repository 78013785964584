import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [userName, setUserName] = useState(localStorage.getItem("username"));

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh_token")
  );

  const refreshAccessToken = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "token/refresh/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh: localStorage.getItem("refresh_token"),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access);
        setRefreshToken(data.refresh);
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
      } else {
        // Maneja el caso de que el refresh token sea inválido o haya expirado
        handleLogout();
      }
    } catch (error) {
      console.error("Error al refrescar token:", error);
    }
  };

  const handleLogout = () => {
    setUserId(null);
    setUserName(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("user_id");
    localStorage.removeItem("username");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };

  // Renueva el token de acceso cada 4 minutos (antes de los 5 minutos de expiración)
  //useEffect(() => {
  //  const interval = setInterval(refreshAccessToken, 4 * 60 * 1000);
  //  return () => clearInterval(interval);
  //}, [refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        userId,
        setUserId,
        userName,
        setUserName,
        accessToken,
        setAccessToken,
        refreshToken,
        refreshAccessToken,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
