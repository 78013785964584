import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../common/button/StyledButton";
import StyledDivider from "../../common/divider/StyledDivider";
import {
  Box,
  List,
  ListItem,
  Typography,
  Slide,
  IconButton,
  TextField,
} from "@mui/material";
import { minWidth, styled } from "@mui/system";
import { DataContext } from "../../../context/DataContext";
import { IconView, IconTrash } from "../../icons/Index";
import SearchIcon from "@mui/icons-material/Search";
import FlexRow from "../container/FlexRow";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import FlexColumn from "../container/FlexColumn";

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary[50],
  color: theme.palette.primary.main,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  borderRight: "1px solid " + theme.palette.primary.main,
  minWidth: "250px",
  maxWidth: "250px",
  height: "100%",
}));

const CustomSlide = styled(Slide)(({ theme }) => ({
  minWidth: "250px",
  maxWidth: "250px",
  height: "100%",
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  background: "#FFF",
  borderRadius: "10px",

  "& .MuiOutlinedInput-input": {
    color: "#02403C",
  },
}));

const CustomDatePicker = styled(DatePicker)({
  minWidth: "0px !important",
  background: "#FFF !important",
  "& .MuiInputBase-root": {
    color: "#02403C !important",
    padding: "8px 12px",
  },
  "& .MuiInputBase-input": {
    color: "#02403C !important",
    padding: "8px 12px",
  },
  "& .MuiSvgIcon-root": {
    color: "#02403C !important",
  },
  "& .MuiButtonBase-root": {
    color: "#02403C !important",
  },
  "& .MuiPickersDay-root": {
    color: "#02403C !important",
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#02403C !important",
    color: "#FFF !important",
  },
  "& .MuiPickersDay-root:hover": {
    backgroundColor: "#E0F2F1 !important",
  },
});

const CustomListItem = styled(ListItem)(({ theme }) => ({
  height: "24px",
  width: "100%",
  padding: "0",
  marginBottom: "20px",
}));

const LoadData = (props) => {
  const navigate = useNavigate();
  const { dataView, selectData, deleteData } = useContext(DataContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  return (
    <CustomSlide direction="right" in={dataView} mountOnEnter unmountOnExit>
      <CustomBox {...props}>
        <StyledButton
          variant="contained"
          onClick={() => handleNavigation("/dashboard/load-data")}
        >
          Cargar datos
        </StyledButton>
        <StyledDivider />

        <FlexColumn gap="8px">
          <CustomTextField
            label="Buscar"
            variant="outlined"
            size="small"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon sx={{ color: "#02403C" }} />,
            }}
          />
          <Box sx={{ display: "flex", gap: "16px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <CustomDatePicker
                  label="Inicio"
                  size="small"
                  value={startDate ? dayjs(startDate) : null}
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <CustomTextField {...params} fullWidth />
                  )}
                />
              </DemoContainer>
              <DemoContainer components={["DatePicker"]}>
                <CustomDatePicker
                  label="Fin"
                  size="small"
                  value={endDate ? dayjs(endDate) : null}
                  onChange={handleEndDateChange}
                  renderInput={(params) => (
                    <CustomTextField {...params} fullWidth />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </FlexColumn>

        <List></List>
      </CustomBox>
    </CustomSlide>
  );
};

export default LoadData;
