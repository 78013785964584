import { Box } from "@mui/system";
import { BorderCard, FlexColumn, MaxWidthContainer } from "../container/Index";
import { BannerBackground } from "../../../images/Index";
import { Typography } from "@mui/material";

const FullWidthBanner = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BannerBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        paddingTop: "132px",
        marginBottom: "140px",
      }}
    >
      <MaxWidthContainer
        sx={{
          position: "relative",
          bottom: "-68px",
        }}
      >
        <Typography
          variant="h3"
          textAlign="center"
          color="#EBFDEA"
          sx={{ marginBottom: "80px" }}
        >
          Analizamos, detectamos y mitigamos sesgos en modelos de inteligencia
          artificial de una forma accesible y transparente para decisiones
          éticas y justas.
        </Typography>
        <BorderCard
          shadow
          sx={{
            background: "#FFFFFF",
            opacity: "90%",
          }}
        >
          <FlexColumn gap="28px" padding="40px 60px" justifyContent="center">
            <Typography variant="h5" textAlign="center">
              Somos ese aliado de empresas y entidades, que se interesa en
              disminuir el impacto negativo que han vivido los distintos grupos
              poblacionales históricamente discriminados, y en promover procesos
              e interacciones más justas para todos.
            </Typography>
          </FlexColumn>
        </BorderCard>
      </MaxWidthContainer>
    </Box>
  );
};

export default FullWidthBanner;
