import React, { useContext } from "react";
import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  Divider,
  ListItemButton,
} from "@mui/material";
import {
  IconBalance,
  IconGlossary,
  IconSchema,
  IconSearch,
  IconStack,
  IconTrending,
} from "../../icons/Index";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import HomeIcon from "@mui/icons-material/Home";

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: "72px",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    background: theme.palette.primary.main,
    width: "72px",
    overflow: "hidden",
    boxSizing: "border-box",
  },
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.primary[50],
  height: "1px",
  margin: "12px 20px 12px 20px",
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  height: "48px",
  padding: "0",
}));

const CustomListItemButton = styled(ListItemButton)(({ theme, ...props }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "12px 0px 12px 0px",
  color:
    props.path === props.actualpath ||
    props.secondpath === props.actualpath ||
    props.toogle === "true"
      ? theme.palette.secondary.main
      : theme.palette.primary[50],
  "&:hover": {
    color: theme.palette.secondary.main, // Puedes ajustar el color del hover si es necesario
  },
}));

const StyledDrawer = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const actualpath = location.pathname;
  const { actualData } = useContext(DataContext);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <CustomDrawer {...props}>
      <Toolbar />
      <Box sx={{ height: "100%" }}>
        <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CustomListItem key={"Dashboard"}>
            <CustomListItemButton
              actualpath={actualpath}
              path="/dashboard"
              onClick={() => handleNavigation("/dashboard")}
            >
              <HomeIcon sx={{ fontSize: 32 }} />
            </CustomListItemButton>
          </CustomListItem>
          <CustomDivider />
          <CustomListItem key={"Stack"}>
            <CustomListItemButton
              actualpath={actualpath}
              path={`/dashboard/${actualData?.id_model}/data-view`}
              onClick={() =>
                handleNavigation(`/dashboard/${actualData?.id_model}/data-view`)
              }
              disabled={!actualData}
            >
              <IconStack />
            </CustomListItemButton>
          </CustomListItem>
          <CustomListItem key={"Trending"}>
            <CustomListItemButton
              actualpath={actualpath}
              path={`/dashboard/${actualData?.id_model}/performance`}
              onClick={() =>
                handleNavigation(
                  `/dashboard/${actualData?.id_model}/performance`
                )
              }
              disabled={!actualData}
            >
              <IconTrending />
            </CustomListItemButton>
          </CustomListItem>
          <CustomListItem key={"Detection"}>
            <CustomListItemButton
              actualpath={actualpath}
              path={`/dashboard/${actualData?.id_model}/detection`}
              onClick={() =>
                handleNavigation(`/dashboard/${actualData?.id_model}/detection`)
              }
              disabled={!actualData}
            >
              <IconSearch />
            </CustomListItemButton>
          </CustomListItem>
          <CustomListItem key={"Mitigation"}>
            <CustomListItemButton
              actualpath={actualpath}
              path={`/dashboard/${actualData?.id_model}/mitigation`}
              onClick={() =>
                handleNavigation(
                  `/dashboard/${actualData?.id_model}/mitigation`
                )
              }
              disabled={!actualData}
            >
              <IconBalance />
            </CustomListItemButton>
          </CustomListItem>
          <CustomListItem key={"Prediction"}>
            <CustomListItemButton
              actualpath={actualpath}
              path={`/dashboard/${actualData?.id_model}/prediction`}
              onClick={() =>
                handleNavigation(
                  `/dashboard/${actualData?.id_model}/prediction`
                )
              }
              disabled={!actualData}
            >
              <IconSchema />
            </CustomListItemButton>
          </CustomListItem>
          <CustomListItem key={"Book"} sx={{ marginTop: "auto" }}>
            <CustomListItemButton
              actualpath={actualpath}
              path="/dashboard/glossary"
              onClick={() => handleNavigation("/dashboard/glossary")}
            >
              <IconGlossary sx={{ width: "30px", height: "36px" }} />
            </CustomListItemButton>
          </CustomListItem>
        </List>
      </Box>
    </CustomDrawer>
  );
};

export default StyledDrawer;
