import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/system";

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({}));

const IconSearch = (props) => {
  return (
    <CustomSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
      >
        <path
          d="M29.1065 26.2559L23.4155 20.5649C23.1586 20.308 22.8104 20.1653 22.4451 20.1653H21.5147C23.0901 18.1503 24.0263 15.6159 24.0263 12.8588C24.0263 6.30015 18.712 0.98584 12.1533 0.98584C5.59458 0.98584 0.280273 6.30015 0.280273 12.8588C0.280273 19.4175 5.59458 24.7318 12.1533 24.7318C14.9103 24.7318 17.4447 23.7957 19.4597 22.2202V23.1507C19.4597 23.516 19.6024 23.8642 19.8593 24.1211L25.5503 29.8121C26.0869 30.3487 26.9546 30.3487 27.4854 29.8121L29.1008 28.1967C29.6374 27.6601 29.6374 26.7925 29.1065 26.2559ZM12.1533 20.1653C8.11759 20.1653 4.84681 16.9002 4.84681 12.8588C4.84681 8.82316 8.11188 5.55238 12.1533 5.55238C16.1889 5.55238 19.4597 8.81745 19.4597 12.8588C19.4597 16.8945 16.1947 20.1653 12.1533 20.1653Z"
          fill="currentColor"
        />
      </svg>
    </CustomSvgIcon>
  );
};

export default IconSearch;
